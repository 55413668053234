export const countries_code = [
    {
        countryCode: "+213" ,
        country:  'Algeria (+213)',
        value:'Algeria',
        IsoCode: "DZ"
    },
    {
        countryCode: "+376" ,
        country: 'Andorra (+376)',
        value:'Andorra',
        IsoCode: "AD"
    },
    {
        countryCode: "+244" ,
        country: 'Angola (+244)',
        value:'Angola',
        IsoCode: "AO"
    },
    {    
        countryCode: "+1264" ,
        country: 'Anguilla (+1264)',
        value: 'Anguilla',
        IsoCode: "AI"
    },
    {
        countryCode: "+1268" ,
        country: 'Antigua (+1268)',
        value: 'Antigua',
        IsoCode: "AG"
    },
    {
        countryCode: "+54" ,
        country: 'Argentina (+54)',
        value: 'Argentina',
        IsoCode: "AR"
    },
    {
        countryCode: "+374" ,
        country: 'Armenia (+374)',
        value: 'Armenia',
        IsoCode: "AM"
    },
    {
        countryCode: "+297" ,
        country: 'Aruba (+297)',
        value: 'Aruba',
        IsoCode: "AW"
    },
    {
        countryCode: "+61" ,
        country: 'Australia (+61)',
        value:'Australia',
        IsoCode: "AU"
    },
    {
        countryCode: "+43" ,
        country: 'Austria (+43)',
        value: 'Austria',
        IsoCode: "AT"
    },
    {
        countryCode: "+994" ,
        country: 'Azerbaijan (+994)',
        value: 'Azerbaijan',
        IsoCode: "AZ"
    },
    {
        countryCode: "+1242" ,
        country: 'Bahamas (+1242)',
        value: 'Bahamas',
        IsoCode: "BS"
    },
    {
        countryCode: "+973" ,
        country: 'Bahrain (+973)',
        value: 'Bahrain',
        IsoCode: "BH"
    },
    {
        countryCode: "+880" ,
        country: 'Bangladesh (+880)',
        value: 'Bangladesh',
        IsoCode: "BD"
    },
    {
        countryCode: "+1246" ,
        country: 'Barbados (+1246)',
        value: 'Barbados',
        IsoCode: "BB"
    },
    {
        countryCode: "+375" ,
        country: 'Belarus (+375)',
        value:'Belarus',
        IsoCode: "BY"
    },
    {
        countryCode: "+32" ,
        country: 'Belgium (+32)',
        value:'Belgium',
        IsoCode: "BE"
    },
    {
        countryCode: "+501" ,
        country: 'Belize (+501)',
        value:'Belize',
        IsoCode: "BZ"
    },
    {
        countryCode: "+229" ,
        country: 'Benin (+229)',
        value:'Benin',
        IsoCode: "BJ"
    },
    {
        countryCode: "+1441" ,
        country: 'Bermuda (+1441)',
        value:'Bermuda',
        IsoCode: "BM"
    },
    {
        countryCode: "+975" ,
        country: 'Bhutan (+975)',
        value:'Bhutan',
        IsoCode: "BT"
    },
    {
        countryCode: "+591" ,
        country: 'Bolivia (+591)',
        value:'Bolivia',
        IsoCode: "BO"
    },
    {
        countryCode: "+387" ,
        country: 'Bosnia Herzegovina (+387)',
        value:'Bosnia Herzegovina',
        IsoCode: "BA"
    },
    {
        countryCode: "+267" ,
        country: 'Botswana (+267)',
        value:'Botswana',
        IsoCode: "BW"
    },
    {
        countryCode: "+55" ,
        country: 'Brazil (+55)',
        value:'Brazil',
        IsoCode: "BR"
    },
    {
        countryCode: "+673" ,
        country: 'Brunei (+673)',
        value:'Brunei',
        IsoCode: "BN"
    },
    {
        countryCode: "+359" ,
        country: 'Bulgaria (+359)',
        value:'Bulgaria',
        IsoCode: "BF"
    },
    {
        countryCode: "+226" ,
        country: 'Burkina Faso (+226)',
        value:'Burkina Faso',
        IsoCode: "BF"
    },
    {
        countryCode: "+257" ,
        country: 'Burundi (+257)',
        value:'Burundi',
        IsoCode: "BI"
    },
    {
        countryCode: "+855" ,
        country: 'Cambodia (+855)',
        value:'Cambodia',
        IsoCode: "KH"
    },
    {
        countryCode: "+237" ,
        country: 'Cameroon (+237)',
        value:'Cameroon',
        IsoCode: "CM"
    },
    {
        countryCode: "+1" ,
        country: 'Canada (+1)',
        value: 'Canada',
        IsoCode: "CA"
    },
    {
        countryCode: "+238" ,
        country: 'Cape Verde Islands (+238)',
        value: 'Cape Verde Islands',
        IsoCode: "CV"
    },  {
        countryCode: "+1345" ,
        country: 'Cayman Islands (+1345)',
        value:'Cayman Islands',
        IsoCode: "KY"
    },
    {
        countryCode: "+236" ,
        country: 'Central African Republic (+236)',
        value:'Central African Republic',
        IsoCode: "CF"
    },
    {
        countryCode: "+56" ,
        country: 'Chile (+56)',
        value:'Chile',
        IsoCode: "CL"
    },
    {
        countryCode: "+86" ,
        country: 'China (+86)',
        value:'China',
        IsoCode: "CN"
    },
    {
        countryCode: "+57" ,
        country: 'Colombia (+57)',
        value:'Colombia',
        IsoCode: "CO"
    },
    {
        countryCode: "+269" ,
        country: 'Comoros (+269)',
        value:'Comoros',
        IsoCode: "KM"
    },
    {
        countryCode: "+242" ,
        country: 'Congo (+242)',
        value:'Congo',
        IsoCode: "CG"
    },
    {
        countryCode: "+682" ,
        country: 'Cook Islands (+682)',
        value:'Cook Islands',
        IsoCode: "CK"
    },
    {
        countryCode: "+506" ,
        country: 'Costa Rica (+506)',
        value:'Costa Rica',
        IsoCode: "CR"
    },
    {
        countryCode: "+385" ,
        country: 'Croatia (+385)',
        value:'Croatia',
        IsoCode: "HR"
    },
    {
        countryCode: "+53" ,
        country: 'Cuba (+53)',
        value:'Cuba',
        IsoCode: "CU"
    },
    {
        countryCode: "+90392" ,
        country: 'Cyprus North (+90392)',
        value:'Cyprus North',
        IsoCode: "CY"
    },
    {
        countryCode: "+357" ,
        country: 'Cyprus South (+357)',
        value:'Cyprus South',
        IsoCode: "CY"
    },
    {
        countryCode: "+42" ,
        country: 'Czech Republic (+42)',
        value:'Czech Republic',
        IsoCode: "CZ"
    },
  {
        countryCode: "+45" ,
        country: 'Denmark (+45)',
        value:'Denmark',
        IsoCode: "DK"
    },
    {
        countryCode: "+253" ,
        country: 'Djibouti (+253)',
        value:'Djibouti',
        IsoCode: "DJ"
    },
    {
        countryCode: "+1809" ,
        country: 'Dominica (+1809)',
        value:'Dominica',
        IsoCode: "DM"
    },
    {
        countryCode: "+1809" ,
        country: 'Dominican Republic (+1809)',
        value:'Dominican Republic',
        IsoCode: "DO"
    },
    {
        countryCode: "+593" ,
        country: 'Ecuador (+593)',
        value:'Ecuador',
        IsoCode: "EC"
    },
    {
        countryCode: "+20" ,
        country: 'Egypt (+20)',
        value:'Egypt',
        IsoCode: "EG"
    },
    {
        countryCode: "+503" ,
        country: 'El Salvador (+503)',
        value:'El Salvador',
        IsoCode: "SV"
    },
    {
        countryCode: "+240" ,
        country: 'Equatorial Guinea (+240)',
        value:'Equatorial Guinea',
        IsoCode: "GQ"
    },
    {
        countryCode: "+291" ,
        country: 'Eritrea (+291)',
        value:'Eritrea',
        IsoCode: "ER"
    },
    {
        countryCode: "+372" ,
        country: 'Estonia (+372)',
        value:'Estonia',
        IsoCode: "EE"
    },
    {
        countryCode: "+251" ,
        country: 'Ethiopia (+251)',
        value:'Ethiopia',
        IsoCode: "ET"
    },
    {
        countryCode: "+500" ,
        country: 'Falkland Islands (+500)',
        value:'Falkland Islands',
        IsoCode: "FK"
    },
    {
        countryCode: "+298" ,
        country: 'Faroe Islands (+298)',
        value:'Faroe Islands',
        IsoCode: "FO "
    },
    {
        countryCode: "679" ,
        country: 'Fiji (679)',
        value:'Fiji',
        IsoCode: "FJ"
    },
    {
        countryCode: "+358" ,
        country: 'Finland (+358)',
        value:'Finland',
        IsoCode: "FI"
    },
    {
        countryCode: "+33" ,
        country: 'France (+33)',
        value:'France',
        IsoCode: "FR"
    },
    {
        countryCode: "+594" ,
        country: 'French Guiana (+594)',
        value:'French Guiana',
        IsoCode: "GF"
    },
    {
        countryCode: "+689" ,
        country: 'French Polynesia (+689)',
        value:'French Polynesia',
        IsoCode: "PF"
    },
    {
        countryCode: "+241" ,
        country: 'Gabon (+241)',
        value:'Gabon',
        IsoCode: "GA"
    },
    {
        countryCode: "+220" ,
        country: ' Gambia (+220)',
        value:'Gambia',
        IsoCode: "GM"
    },
    {
        countryCode: "+7880" ,
        country: 'Georgia (+7880)',
        value:'Georgia',
        IsoCode: "GE"
    },
    {
        countryCode: "+49" ,
        country: 'Germany (+49)',
        value:'Germany',
        IsoCode: "DE"
    },
    {
        countryCode: "+233" ,
        country: 'Ghana (+233)',
        value:'Ghana',
        IsoCode: "GH"
    },
    {
        countryCode: "+350" ,
        country: 'Gibraltar (+350)',
        value:'Gibraltar',
        IsoCode: "GI"
    },
    {
        countryCode: "+30" ,
        country: 'Greece (+30)',
        value:'Greece',
        IsoCode: "GR"
    },
  {
        countryCode: "+299" ,
        country: 'Greenland (+299)',
        value:'Greenland',
        IsoCode: "GL"
    },
    {
        countryCode: "+1473" ,
        country: 'Grenada (+1473)',
        value:'Grenada',
        IsoCode: "GD"
    },
    {
        countryCode: "+590" ,
        country: 'Guadeloupe (+590)',
        value:'Guadeloupe',
        IsoCode: "GP"
    },
    {
        countryCode: "+671" ,
        country: 'Guam (+671)',
        value:'Guam',
        IsoCode: "GU"
    },
    {
        countryCode: "+502" ,
        country: 'Guatemala (+502)',
        value:'Guatemala',
        IsoCode: "GT"
    },
    {
        countryCode: "+224" ,
        country: 'Guinea (+224)',
        value:'Guinea',
        IsoCode: "GN"
    },
    {
        countryCode: "+245" ,
        country: 'Guinea - Bissau (+245)',
        value:'Guinea - Bissau',
        IsoCode: "GW"
    },
    {
        countryCode: "+592" ,
        country: 'Guyana (+592)',
        value:'Guyana',
        IsoCode: "GY"
    }, {
        countryCode: "+509" ,
        country: 'Haiti (+509)',
        value:'Haiti',
        IsoCode: "HT"
    },
    {
        countryCode: "+504" ,
        country: 'Honduras (+504)',
        value:'Honduras',
        IsoCode: "HN"
    }, {
        countryCode: "+852" ,
        country: 'Hong Kong (+852)',
        value:'Hong Kong',
        IsoCode: "HK"
    },
    {
        countryCode: "+36" ,
        country: 'Hungary (+36)',
        value:'Hungary',
        IsoCode: "HU"
    }, {
        countryCode: "+354" ,
        country: 'Iceland (+354)',
        value:'Iceland',
        IsoCode: "IS"
    },
    {
        countryCode: "+91" ,
        country: 'India (+91) ',
        value:'India',
        IsoCode: "IN"
    }, {
        countryCode: "+62" ,
        country: 'Indonesia (+62)',
        value:'Indonesia',
        IsoCode: "ID"
    },
    {
        countryCode: "+98" ,
        country: 'Iran (+98)',
        value:'Iran',
        IsoCode: "IR"
    }, {
        countryCode: "+964" ,
        country: 'Iraq (+964)',
        value:'Iraq',
        IsoCode: "IQ"
    },
    {
        countryCode: "+353" ,
        country: 'Ireland (+353)',
        value:'Ireland',
        IsoCode: "IE"
    }, {
        countryCode: "+972" ,
        country: 'Israel (+972)',
        value:'Israel',
        IsoCode: "IL"
    },
    {
        countryCode: "+39" ,
        country: 'Italy (+39)',
        value:'Italy',
        IsoCode: "IT"
    }, {
        countryCode: "+1876" ,
        country: 'Jamaica (+1876)',
        value:'Jamaica',
        IsoCode: "JM"
    },
    {
        countryCode: "+81" ,
        country: 'Japan (+81)',
        value:'Japan',
        IsoCode: "JP"
    }, {
        countryCode: "+962" ,
        country: 'Jordan (+962)',
        value:'Jordan',
        IsoCode: "JO"
    },
    {
        countryCode: "+7" ,
        country: 'Kazakhstan (+7)',
        value:'Kazakhstan',
        IsoCode: "KZ"
    }, {
        countryCode: "+254" ,
        country: 'Kenya (+254)',
        value:'Kenya',
        IsoCode: "KE"
    },
    {
        countryCode: "+686" ,
        country: 'Kiribati (+686)',
        value:'Kiribati',
        IsoCode: "KI"
    }, {
        countryCode: "+850" ,
        country: 'Korea North (+850)',
        value:'Korea North',
        IsoCode: "KP"
    },
    {
        countryCode: "+82" ,
        country: 'Korea South (+82)',
        value:'Korea South',
        IsoCode: "KR"
    }, {
        countryCode: "+965" ,
        country: 'Kuwait (+965)',
        value:'Kuwait',
        IsoCode: "KW"
    },
    {
        countryCode: "+996" ,
        country: 'Kyrgyzstan (+996)',
        value:'Kyrgyzstan',
        IsoCode: "KG"
    }, {
        countryCode: "+856" ,
        country: 'Laos (+856)',
        value:'Laos',
        IsoCode: "LA"
    },
    {
        countryCode: "+371" ,
        country: 'Latvia (+371)',
        value:'Latvia',
        IsoCode: "LV"
    }, {
        countryCode: "+961" ,
        country: 'Lebanon (+961)',
        value:'Lebanon',
        IsoCode: "LB"
    },
    {
        countryCode: "+266" ,
        country: 'Lesotho (+266)',
        value:'Lesotho',
        IsoCode: "LS"
    }, {
        countryCode: "+231" ,
        country: 'Liberia (+231)',
        value:'Liberia',
        IsoCode: "LR"
    },
    {
        countryCode: "+218" ,
        country: 'Libya (+218)',
        value:'Libya',
        IsoCode: "LY"
    }, {
        countryCode: "+417" ,
        country: 'Liechtenstein (+417)',
        value:'Liechtenstein',
        IsoCode: "LI"
    },
    {
        countryCode: "+370" ,
        country: 'Lithuania (+370)',
        value:'Lithuania',
        IsoCode: "LT"
    }, {
        countryCode: "+352" ,
        country: 'Luxembourg (+352)',
        value:'Luxembourg',
        IsoCode: "LU"
    },
    {
        countryCode: "+853" ,
        country: 'Macao (+853)',
        value:'Macao',
        IsoCode: "MP"
    }, {
        countryCode: "+389" ,
        country: 'Macedonia (+389)',
        value:'Macedonia',
        IsoCode: "MK"
    },
    {
        countryCode: "+261" ,
        country: 'Madagascar (+261)',
        value:'Madagascar',
        IsoCode: "MG"
    }, {
        countryCode: "+265" ,
        country: 'Malawi (+265) ',
        value:'Malawi',
        IsoCode: "MW"
    },
    {
        countryCode: "+60" ,
        country: 'Malaysia (+60)',
        value:'Malaysia',
        IsoCode: "MY"
    }, {
        countryCode: "+960" ,
        country: 'Maldives (+960)',
        value:'Maldives',
        IsoCode: "MV"
    },
    {
        countryCode: "+223" ,
        country: 'Mali (+223)',
        value:'Mali',
        IsoCode: "ML"
    }, {
        countryCode: "+356" ,
        country: 'Malta (+356)',
        value:'Malta',
        IsoCode: "MT"
    },
    {
        countryCode: "+692" ,
        country: 'Marshall Islands (+692)',
        value:'Marshall Islands',
        IsoCode: "MH"
    }, {
        countryCode: "+596" ,
        country: 'Martinique (+596)',
        value:'Martinique',
        IsoCode: "MQ"
    },
    {
        countryCode: "+222" ,
        country: 'Mauritania (+222)',
        value:'Mauritania',
        IsoCode: "MR"
    }, 
    {
        countryCode: "+269" ,
        country: 'Mayotte (+269)',
        value:'Mayotte',
        IsoCode: "yt"
    },
    {
        countryCode: "+52" ,
        country: 'Mexico (+52)',
        value:'Mexico',
        IsoCode: "mx"
    },
    {
        countryCode: "+691" ,
        country: 'Micronesia (+691)',
        value:'Micronesia',
        IsoCode: "FM"
    },
    {
        countryCode: "+373" ,
        country: 'Moldova (+373)',
        value:'Moldova',
        IsoCode: "MD"
    },
    {
        countryCode: "+377)" ,
        country: 'Monaco (+377)',
        value:'Monaco',
        IsoCode: "MC"
    },
    {
        countryCode: "+976" ,
        country: 'Mongolia (+976)',
        value:'Mongolia',
        IsoCode: "MN"
    },
    {
        countryCode: "+1664" ,
        country: 'Montserrat (+1664)',
        value:'Montserrat',
        IsoCode: "MS"
    },
    {
        countryCode: "+212" ,
        country: 'Morocco (+212)',
        value:'Morocco',
        IsoCode: "MA"
    },
    {
        countryCode: "+258" ,
        country: 'Mozambique (+258)',
        value:'Mozambique',
        IsoCode: "MZ"
    },
    {
        countryCode: "+95" ,
        country: 'Myanmar (+95)',
        value:'Myanmar',
        IsoCode: "MN"
    },
    {
        countryCode: "+264" ,
        country: 'Namibia (+264)',
        value:'Namibia',
        IsoCode: "NA"
    },
    {
        countryCode: "+674" ,
        country: 'Nauru (+674)',
        value:'Nauru',
        IsoCode: "NR"
    },
    {
        countryCode: "+977" ,
        country: 'Nepal (+977)',
        value:'Nepal',
        IsoCode: "NP"
    },
    {
        countryCode: "+31" ,
        country: 'Netherlands (+31)',
        value:'Netherlands',
        IsoCode: "NL"
    },
    {
        countryCode: "+687" ,
        country: 'New Caledonia (+687)',
        value:'New Caledonia',
        IsoCode: "NC"
    },
    {
        countryCode: "+64" ,
        country: 'New Zealand (+64)',
        value:'New Zealand',
        IsoCode: "NZ"
    },
    {
        countryCode: " " ,
        country: 'Nicaragua (+505)',
        value:'Nicaragua',
        IsoCode: "NI"
    },
    {
        countryCode: " " ,
        country: 'Niger (+227)',
        value:'Niger',
        IsoCode: "NE"
    },
    {
        countryCode: " " ,
        country: 'Nigeria (+234)',
        value:'Nigeria',
        IsoCode: "NG"
    },
    {
        countryCode: " " ,
        country: 'Niue (+683',
        value:'Niue',
        IsoCode: "NU"
    },
    {
        countryCode: " " ,
        country: 'Norfolk Islands (+672)',
        value:'Norfolk Islands',
        IsoCode: "NF"
    },
    {
        countryCode: " " ,
        country: 'Northern Marianas (+670',
        value:'Northern Marianas',
        IsoCode: "NP"
    },
    {
        countryCode: " " ,
        country: 'Norway (+47)',
        value:'Norway',
        IsoCode: "NO"
    },
    {
        countryCode: " " ,
        country: 'Oman (+968)',
        value:'Oman',
        IsoCode: "OM"
    },
    {
        countryCode: " " ,
        country: 'Pakistan (+92)',
        value:'Pakistan',
        IsoCode: "PK"
    },
    {
        countryCode: " " ,
        country: 'Palau (+680)',
        value:'Palau',
        IsoCode: "PW"
    },
    {
        countryCode: " " ,
        country: 'Papua New Guinea (+675)',
        value:'Papua New Guinea',
        IsoCode: "PG"
    },
    {
        countryCode: " " ,
        country: 'Paraguay (+595)',
        value:'Paraguay',
        IsoCode: "PY"
    },
    {
        countryCode: " " ,
        country: 'Peru (+51)',
        value:'Peru',
        IsoCode: "PE"
    },
    {
        countryCode: " " ,
        country: 'Philippines (+63)',
        value:'Philippines',
        IsoCode: "PH"
    },
    {
        countryCode: " " ,
        country: 'Poland (+48)',
        value:'Poland',
        IsoCode: "PL"
    },
    {
        countryCode: " " ,
        country: 'Portugal (+351)',
        value:'Portugal',
        IsoCode: "PT"
    },
    {
        countryCode: " " ,
        country: 'Puerto Rico (+1787)',
        value:'Puerto Rico',
        IsoCode: "PR"
    },
    {
        countryCode: " " ,
        country: 'Qatar (+974)',
        value:'Qatar',
        IsoCode: "QA"
    },
    {
        countryCode: " " ,
        country: 'Reunion (+262)',
        value:'Reunion',
        IsoCode: "RE"
    },
    {
        countryCode: " " ,
        country: 'Romania (+40)',
        value:'Romania',
        IsoCode: "RO"
    },
    {
        countryCode: " " ,
        country: 'Russia (+7)',
        value:'Russia',
        IsoCode: "RU"
    },
    {
        countryCode: " " ,
        country: 'Rwanda (+250)',
        value:'Rwanda',
        IsoCode: "RW"
    },
    {
        countryCode: " " ,
        country: 'San Marino (+378',
        value:'San Marino',
        IsoCode: "SM"
    },
    {
        countryCode: " " ,
        country: 'Principe (+239)',
        value:'Principe',
        IsoCode: "ST"
    },
    {
        countryCode: " " ,
        country: 'Saudi Arabia (+966)',
        value:'Saudi Arabia',
        IsoCode: "SA"
    },
    {
        countryCode: " " ,
        country: 'Senegal (+221)',
        value:'Senegal',
        IsoCode: "SN"
    },
    {
        countryCode: " " ,
        country: 'Serbia (+381)',
        value:'Serbia',
        IsoCode: "CS"
    },
    {
        countryCode: " " ,
        country: 'Seychelles (+248)',
        value:'Seychelles',
        IsoCode: "SC"
    },
    {
        countryCode: " " ,
        country: 'Sierra Leone (+232)',
        value:'Sierra Leone',
        IsoCode: "SL"
    },
    {
        countryCode: " " ,
        country: 'Singapore (+65)',
        value:'Singapore',
        IsoCode: "SG"
    },
    {
        countryCode: " " ,
        country: 'Slovak Republic (+421)',
        value:'Slovak Republic',
        IsoCode: "SK"
    },
    {
        countryCode: " " ,
        country: 'Slovenia (+386',
        value:'Slovenia',
        IsoCode: "SI"
    },
    {
        countryCode: " " ,
        country: 'Solomon Islands (+677',
        value:'Solomon Islands',
        IsoCode: "SB"
    },
    {
        countryCode: " " ,
        country: 'Somalia (+252)',
        value:'Somalia',
        IsoCode: "SO"
    },
    {
        countryCode: " " ,
        country: 'South Africa (+27)',
        value:'South Africa',
        IsoCode: "ZA"
    },
    {
        countryCode: " " ,
        country: 'Spain (+34)',
        value:'Spain',
        IsoCode: "ES"
    },
    {
        countryCode: " " ,
        country: 'Sri Lanka (+94)',
        value:'Sri Lanka',
        IsoCode: "LK"
    },
    {
        countryCode: " " ,
        country: 'St. Helena (+290)',
        value:'St. Helena',
        IsoCode: "SH"
    },
    {
        countryCode: " " ,
        country: 'St. Kitts (+1869)',
        value:'St. Kitts',
        IsoCode: "KN"
    },
    {
        countryCode: " " ,
        country: 'St. Lucia (+1758)',
        value:'St. Lucia',
        IsoCode: "SC"
    },
    {
        countryCode: " " ,
        country: 'Sudan (+249)',
        value:'Sudan',
        IsoCode: "SD"
    },
    {
        countryCode: " " ,
        country: 'Suriname (+597)',
        value:'Suriname',
        IsoCode: "SR"
    },
    {
        countryCode: " " ,
        country: 'Swaziland (+268)',
        value:'Swaziland',
        IsoCode: "SZ"
    },
    {
        countryCode: " " ,
        country: 'Sweden (+46)',
        value:'Sweden',
        IsoCode: "SE"
    },
    {
        countryCode: " " ,
        country: 'Switzerland (+41)',
        value:'Switzerland',
        IsoCode: "CH"
    },
    {
        countryCode: " " ,
        country: 'Syria (+963',
        value:'Syria',
        IsoCode: "SI"
    },
    {
        countryCode: " " ,
        country: 'Taiwan (+886)',
        value:'Taiwan',
        IsoCode: "TW"
    },
    {
        countryCode: " " ,
        country: 'Tajikstan (+7)',
        value:'Tajikstan',
        IsoCode: "TJ"
    },
    {
        countryCode: " " ,
        country: 'Thailand (+66)',
        value:'Thailand',
        IsoCode: "TH"
    },
    {
        countryCode: " " ,
        country: 'Togo (+228',
        value:'Togo',
        IsoCode: "TG"
    },
    {
        countryCode: " " ,
        country: 'Tonga (+676)',
        value:'Tonga',
        IsoCode: "TO"
    },
    {
        countryCode: " " ,
        country: 'Trinidad & Tobago (+1868)',
        value:'Trinidad & Tobago',
        IsoCode: "TT"
    },
    {
        countryCode: " " ,
        country: 'Tunisia (+216)',
        value:'Tunisia',
        IsoCode: "TN"
    },
    {
        countryCode: " " ,
        country: 'Turkey (+90)',
        value:'Turkey',
        IsoCode: "TR"
    },
    {
        countryCode: " " ,
        country: 'Turkmenistan (+7)',
        value:'Turkmenistan',
        IsoCode: "TM"
    },
    {
        countryCode: " " ,
        country: 'Turks & Caicos Islands (+1649',
        value:'Turks & Caicos Islands',
        IsoCode: "TC"
    },
    {
        countryCode: " " ,
        country: 'Tuvalu (+688)',
        value:'Tuvalu',
        IsoCode: "TV"
    },
    {
        countryCode: " " ,
        country: 'Uganda (+256)',
        value:'Uganda',
        IsoCode: "UG"
    },
    {
        countryCode: " " ,
        country: 'UK (+44)',
        value: 'UK',
        IsoCode: "GB"
    },
    {
        countryCode: " " ,
        country: 'Ukraine (+380)',
        value:'Ukraine',
        IsoCode: "UA"
    },
    {
        countryCode: " " ,
        country: 'United Arab Emirates (+971)',
        value:'United Arab Emirates',
        IsoCode: "AE"
    },
    {
        countryCode: " " ,
        country: 'Uruguay (+598)',
        value:'Uruguay',
        IsoCode: "UY"
    },
    {
        countryCode: " " ,
        country: 'USA (+1)',
        value:'USA',
        IsoCode: "US"
    },
    {
        countryCode: " " ,
        country: 'Uzbekistan (+7)',
        value:'Uzbekistan',
        IsoCode: "UZ"
    },
    {
        countryCode: " " ,
        country: 'Vanuatu (+678)',
        value:'Vanuatu',
        IsoCode: "VU"
    },
    {
        countryCode: " " ,
        country: 'Vatican City (+379)',
        value:'Vatican City',
        IsoCode: "VA"
    },
    {
        countryCode: " " ,
        country: 'Venezuela (+58)',
        value:'Venezuela',
        IsoCode: "VE"
    },
    {
        countryCode: " " ,
        country: 'Vietnam (+84)',
        value:'Vietnam',
        IsoCode: "VN"
    },
    {
        countryCode: " " ,
        country: 'Virgin Islands - British (+1284)',
        value:'Virgin Islands - British',
        IsoCode: "VG"
    },
    {
        countryCode: " " ,
        country: 'Virgin Islands - US (+1340)',
        value:'Virgin Islands - US',
        IsoCode: "VI"
    },
    {
        countryCode: " " ,
        country: 'Wallis & Futuna (+681)',
        value:'Wallis & Futuna',
        IsoCode: "WF"
    },
    {
        countryCode: " " ,
        country: 'Yemen (North)(+969)',
        value:'Yemen',
        IsoCode: "YE"
    },
    {
        countryCode: " " ,
        country: 'Yemen (South)(+967)',
        value:'Yemen',
        IsoCode: "YE"
    },
    {
        countryCode: " " ,
        country: 'Zambia (+260)',
        value:'Zambia',
        IsoCode: "ZM"
    },
    {
        countryCode: " " ,
        country: 'Zimbabwe (+263)',
        value:'Zimbabwe',
        IsoCode: "ZW"
    },

]
export const days = [
    {
        title   : 'Last 7 Days',
        value   : 7,
    },
    {
        title   : 'Last 14 Days',
        value   : 14,
    },
    {
        title   : 'Last 30 Days',
        value   : 30,
    },
    {
        title   : 'Last 90 Days',
        value   :  90,
    },
    {
        title   : 'Last 180 Days',
        value   : 180,
    },
    {
        title   : 'Last 365 Days',
        value   : 365,
    },
]
export const in_days = [
    {
        title   : 'In 7 Days',
        value   : 7,
    },
    {
        title   : 'In 14 Days',
        value   : 14,
    },
    {
        title   : 'In 30 Days',
        value   : 30,
    },
    {
        title   : 'In 90 Days',
        value   :  90,
    },
    {
        title   : 'In 180 Days',
        value   : 180,
    },
    {
        title   : 'In 365 Days',
        value   : 365,
    },
]
export const ftype = [
    {
        title : 'Pending',
        value : 'pending'
    },
    {
        title : 'Completed',
        value : 'completed'
    },
  
]
export const genders = [
    {
        title : 'Male',
        value : '0'
    },
    {
        title : 'Female',
        value : '1'
    }
]
export const category = [
    {
        title : 'Education',
        value : 'education'
    },
    {
        title : 'Migration',
        value : 'migration'
    },
]
export const branches = [
    {
        title : 'Sydney',
        value : 'sydney'
    },
    {
        title : 'Lahore',
        value : 'lahore'
    },
    {
        title : 'Samundri',
        value : 'samundri'
    },
    {
        title : 'Sahiwal',
        value : 'sahiwal'
    },
    {
        title : 'Faisalabad',
        value : 'faisalabad'
    },
]
export const expo_branches = [
    {
        title : 'Sydney',
        value : 'sydney'
    },
    {
        title : 'Lahore',
        value : 'lahore'
    },
    {
        title : 'Samundri',
        value : 'samundri'
    },
    {
        title : 'Sahiwal',
        value : 'sahiwal'
    },
    {
        title : 'Islamabad',
        value : 'islamabad'
    },
    {
        title : 'Faisalabad',
        value : 'faisalabad'
    },
]
export const tution_fee = [
    {
        title : '0 to 1,000',
        id : '0 to 1000',
    },
    {
        title : '1,001 to 5,000',
        id : '1001 to 5000',
    },
    {
        title : '5,001 to 10,000',
        id : '5001 to 10000',
    },
    {
        title : '10,001 to 20,000',
        id : '10001 to 20000',
    },
    {
        title : '20,001 to 35,000',
        id : '20001 to 35000',
    },
    {
        title : '35,001+',
        id : '35,001+',
    },
]
export const genericDiscipline = [
    {
        title : 'Aeronautical Engineering',
        group : 'Engineering and related Technologies'
    },
    {
        title : 'Engineering',
        group : 'Engineering and related Technologies'
    },
    {
        title : 'Mathematics',
        group : 'Engineering and related Technologies'
    },
    {
        title : 'Mechanics',
        group : 'Engineering and related Technologies'
    },
    {
        title : 'Statistics',
        group : 'Engineering and related Technologies'
    },
    {
        title : 'Agriculture',
        group : 'Agriculture, Environmental and Related Studies'
    },
    {
        title : 'Environment Architecture and Building',
        group : 'Agriculture, Environmental and Related Studies'
    },
    {
        title : 'Architecture Creative Arts',
        group : 'Architecture and Buildings'
    },
    {
        title : 'Arts',
        group : 'Creative Arts'
    },
    {
        title : 'Designs',
        group : 'Creative Arts'
    },
    {
        title : 'Fashion',
        group : 'Creative Arts'
    },
    {
        title : 'Beauty Culture / Cosmetology',
        group: 'Mixed Field Programs',
    },
    {
        title : 'Sports',
        group: 'Mixed Field Programs',
    },
    {
        title : 'Business',
        group: 'Management and Commerce',
    },
    {
        title : 'Finance',
        group: 'Management and Commerce',
    },
    {
        title : 'Marketing',
        group: 'Management and Commerce',
    },
    {
        title : 'Computing',
        group: 'Information Technology',
    },
    {
        title : 'Management',
        group: 'Food, Hospitality and Personal Services',
    },
    {
        title : 'Culinary Arts',
        group: 'Food, Hospitality and Personal Services',
    },
    {
        title : 'Hospitality',
        group: 'Food, Hospitality and Personal Services',
    },
    {
        title : 'Retail Education',
        group: 'Food, Hospitality and Personal Services',
    },
    {
        title : 'Education (Early Childhood)',
        group: 'Education',
    },
    {
        title : 'Education (Primary)',
        group: 'Education',
    },
    {
        title : 'Education (Secondary)',
        group: 'Education',
    },
    {
        title : 'Education (Special)',
        group: 'Education',
    },
    {
        title : 'General Education Health',
        group: 'Education',
    },
    {
        title : 'English Program',
        group: 'Language Program',
    },
    {
        title : 'Languages other than English program',
        group: 'Language Program',
    },
    {
        title : 'Health',
        group: 'health',
    },
    {
        title : 'Medicine',
        group: 'health',
    },
    {
        title : 'Nursing',
        group: 'health',
    },
    {
        title : 'History',
        group: 'Natural and Physical Sciences',
    },
    {
        title : 'Science',
        group: 'Natural and Physical Sciences',
    },
    {
        title : 'Social Sciences Society and Culture',
        group: 'Natural and Physical Sciences',
    },
    {
        title : 'Journalism',
        group: 'Society and Culture',
    },
    {
        title : 'Languages and Literature',
        group: 'Society and Culture',
    },
    {
        title : 'Law',
        group: 'Society and Culture',
    },
]
export const duration = [
    {
        title : 'Less than 1 Year',
        id : -1,
    },
    {
        title : '1 Year',
        id: 1,
    },
    {
        title : '1.5 Year',
        id: 2,
    },
    {
        title : '2 Year',
        id : 3,
    },
    {
        title : '2.5 Year',
        id : 4,
    },
    {
        title : '3 Year',
        id : 5,
    },
    {
        title : '3.5 Year',
        id : 6,
    },
    {
        title : '4 Year',
        id: 7,
    },
    {
        title : '4.5 Year',
        id: 8,
    },
    {
        title : '5 Year',
        id: 9,
    },
    {
        title : '5.5 Year',
        id: 10,
    },
    {
        title : 'Greater than 5 Years',
        id: +11,
    },
]
export const prerequisites = [
    {
        title : 'Masters degree',
        group : 'Academic',
    },
    {
        title : 'Bachelors degree',
        group : 'Academic',
    },
    {
        title : '11 years of schooling',
        group : 'Academic',
    },
    {
        title : '12 years of schooling',
        group : 'Academic',
    },
    {
        title : '0 - 45 CAE',
        group : 'CAE',
    },
    {
        title : '45 - 75 CAE',
        group : 'CAE',
    },
    {
        title : '75 - 162 CAE',
        group : 'CAE',
    },
    {
        title : '162 - 176 CAE',
        group : 'CAE',
    },
    {
        title : '185 - 209 CAE',
        group : 'CAE',
    },
    {
        title : '10 - 55 DuoLingo',
        group : 'DuoLingo',
    },
    {
        title : ' 60 - 85 DuoLingo',
        group : 'DuoLingo',
    },
    {
        title : '90 - 115 DuoLingo',
        group : 'DuoLingo',
    },
    {
        title : '120 - 160 DuoLingo',
        group : 'DuoLingo',
    },
    {
        title : '3.0 IELTS',
        group : 'IELTS',
    },
    {
        title : '3.5 IELTS',
        group : 'IELTS',
    },
    {
        title : '4.0 IELTS',
        group : 'IELTS',
    },
    {
        title : '4.5 IELTS',
        group : 'IELTS',
    },
    {
        title : '5.0 IELTS',
        group : 'IELTS',
    },
    {
        title : '5.5 IELTS',
        group : 'IELTS',
    },
    {
        title : '6.0 IELTS',
        group : 'IELTS',
    },
    {
        title : '6.5 IELTS',
        group : 'IELTS',
    },
    {
        title : '7.0 IELTS',
        group : 'IELTS',
    },
    {
        title : '7.5 IELTS',
        group : 'IELTS',
    },
    {
        title : '8.0 IELTS',
        group : 'IELTS',
    },
    {
        title : '1.0 - 2.5 IELTS',
        group : 'IELTS',
    },
    {
        title : '20 - 30 PTE (A)',
        group : 'PTE(A)',
    },
    {
        title : '30 - 49 PTE (A)',
        group : 'PTE(A)',
    },
    {
        title : '49 - 61 PTE (A)',
        group : 'PTE(A)',
    },
    {
        title : '61 - 67 PTE (A)',
        group : 'PTE(A)',
    },
    {
        title : '68 - 88 PTE (A)',
        group : 'PTE(A)',
    },
    {
        title : '2 - 29 (TOEFL IBT)',
        group : 'TOEFL IBT',
    },
    {
        title : '30 - 40 (TOEFL IBT)',
        group : 'TOEFL IBT',
    },
    {
        title : '41 - 60 (TOEFL IBT)',
        group : 'TOEFL IBT',
    },
    {
        title : '61 - 73 (TOEFL IBT)',
        group : 'TOEFL IBT',
    },
    {
        title : '74 - 95 (TOEFL IBT)',
        group : 'TOEFL IBT',
    },
    {
        title : '96 - 120 (TOEFL IBT)',
        group : 'TOEFL IBT',
    },

]
export const education_sector = [
    {
        title: 'Foreign Affairs or Defence Sector',
    },
    {
        title: 'Higher Education Sector',
    },
    {
        title: 'Independent ELICOS Sector',
    },
    {
        title: 'Non-Award Sector',
    },
    {
        title: 'Postgraduate Research Sector',
    },
    {
        title: 'Schools Sector',
    },
    {
        title: 'Vocational Education and Training Sector',
    },
]
export const education_category = [
    {
        title: 'Foundation',
    },
    {
        title: 'Vocational',
    },
    {
        title: 'Undergraduate Diploma Pathways',
    },
    {
        title: 'Undergraduate Qualifications',
    },
    {
        title: 'Postgraduate Studies',
    },
    {
        title: 'Others',
    },
]
export const all_within = [
    {
        title: 'Due Date',
        value: 'duedate'
    },
    {
        title: 'Created Date',
        value: 'costdate'
    },
]
export const awaiting_within = [
    {
        title: 'Created Date',
        value: 'costdate'
    },
    {
        title: 'Due Date',
        value: 'duedate'
    },
    {
        title: 'OverDue By',
        value: 'overdue'
    },
]
export const paid_within = [
    {
        title: 'Created Date',
        value: 'costdate'
    },
    {
        title: 'Paid Date',
        value: 'lastpayment'
    },
]
export const destination_country = [
    {
        title: 'Australia',
    },
    {
        title: 'UK',
    },
    {
        title: 'USA',
    }
]
export const hear_about_us = [
    {
        title: 'Facebook',
    },
    {
        title: 'Instagram',
    },
    {
        title: 'Whatsapp',
    },
    {
        title: 'Phone Call',
    },
    {
        title: 'SMS',
    },
    {
        title: 'Email',
    },
    {
        title: 'Through a Friend',
    },
    {
        title: 'Through a Colleague',
    },
    {
        title: 'Billboard',
    },
    {
        title: 'Flex',
    },
]
export const installment_within = [
    {
        title: 'Last Paid',
        value: 'lastpayment'
    },
    {
        title: 'Next Due',
        value: 'duedate'
    },
]
export const marital_status = [
    {
        title: 'Married',
    },
    {
        title: 'Single',
    },
    {
        title: 'Divorced',
    },
    {
        title: 'Widowed',
    },
    {
        title: 'Separated',
    },
]
export const expo_status = [
    {
        title: 'Registered Attendees',
        value: 'attendee',
    },
    {
        title: 'Waiting',
        value: 'waiting',
    },
    {
        title: 'Allocated',
        value: 'consulting',
    },
    {
        title: 'Concluded',
        value: 'concluded',
    },
]
export const events = [
    {
        title: 'Education Abroad Expo-2022 (Aug/Sep)'
    },
    {
        title: 'Lahore Hi-Tea 2022'
    },

]
export const healthProviders = [
    {
        title: 'CBHS'
    },
    {
        title: 'AHM'
    },
    {
        title: 'NIB'
    },
    {
        title: 'Allianz'
    },
    {
        title: 'Medibank'
    },
    {
        title: 'Bupa'
    },
    {
        title: 'Orbit Protect'
    },
    {
        title: 'Uni-Care'
    },
]
export const franceCampus = [
    {
        title: 'Paris'
    },
    {
        title: 'Bordeaux'
    },
    {
        title: 'Marseille'
    },
    {
        title: 'Lyon'
    },
    {
        title: 'Toulouse'
    },
    {
        title: 'Nice'
    },
    {
        title: 'Nantes'
    },
    {
        title: 'Montpellier'
    },
    {
        title: 'Strasbourg'
    },
    {
        title: 'Lille'
    },
    {
        title: 'Rennes'
    },
    {
        title: 'Toulon'
    },
    {
        title: 'Reims'
    },
    {
        title: 'Saint-Étienne'
    },
    {
        title: 'Le Havre'
    },
    {
        title: 'Dijon'
    },
    {
        title: 'Grenoble'
    },
    {
        title: 'Angers'
    },
    {
        title: 'Villeurbanne'
    },
    {
        title: 'Nîmes'
    },
    {
        title: 'Aix-en-Provence'
    },
    {
        title: 'Clermont-Ferrand'
    },
    {
        title: 'Le Mans'
    },
    {
        title: 'Brest'
    },
    {
        title: 'Tours'
    },
    {
        title: 'Amiens'
    },
    {
        title: 'Annecy'
    },
    {
        title: 'Limoges'
    },
    {
        title: 'Metz'
    },
    {
        title: 'Boulogne-Billancourt'
    },
    {
        title: 'Perpignan'
    },
    {
        title: 'Besançon'
    },
    {
        title: 'Orléans'
    },
    {
        title: 'Rouen'
    },
    {
        title: 'Saint-Denis'
    },
    {
        title: 'Montreuil'
    },
    {
        title: 'Caen'
    },
    {
        title: 'Argenteuil'
    },
    {
        title: 'Mulhouse'
    },
    {
        title: 'Nancy'
    },
    {
        title: 'Tourcoing'
    },
    {
        title: 'Roubaix'
    },
    {
        title: 'Nanterre'
    },
    {
        title: 'Vitry-sur-Seine'
    },
    {
        title: 'Créteil'
    },
    {
        title: 'Poitiers'
    },
    {
        title: 'Aubervilliers'
    },
    {
        title: 'Colombes'
    },
    {
        title: 'Dunkerque'
    },
    {
        title: 'Aulnay-sous-Bois'
    },
    {
        title: 'Versailles'
    },
    {
        title: 'Courbevoie'
    },
    {
        title: 'La Rochelle'
    },
    {
        title: 'Rueil-Malmaison'
    },
    {
        title: 'Champigny-sur-Marne'
    },
    {
        title: 'Pau'
    },
    {
        title: 'Mérignac'
    },
    {
        title: 'Saint-Maur-des-Fossés'
    },
    {
        title: 'Antibes'
    },
    {
        title: 'Ajaccio'
    },
    {
        title: 'Cannes'
    },
    {
        title: 'Saint-Nazaire'
    },
    {
        title: 'Drancy'
    },
    {
        title: 'Noisy-le-Grand'
    },
    {
        title: 'Issy-les-Moulineaux'
    },
    {
        title: 'Cergy'
    },
    {
        title: 'Levallois-Perret'
    },
    {
        title: 'Calais'
    },
    {
        title: 'Pessac'
    },
    {
        title: 'Vénissieux'
    },
    {
        title: 'Clichy'
    },
    {
        title: 'Valence'
    },
    {
        title: 'Ivry-sur-Seine'
    },
    {
        title: 'Antony'
    },
    {
        title: 'Troyes'
    },
    {
        title: 'La Seyne-sur-Mer'
    },
    {
        title: 'Pantin'
    },
    {
        title: 'Chambéry'
    },
    {
        title: 'Neuilly-sur-Seine'
    },
    {
        title: 'Sarcelles'
    },
    {
        title: 'Le Blanc-Mesnil'
    },
    {
        title: 'Maisons-Alfort'
    },
    {
        title: 'Lorient'
    },
    {
        title: 'Villejuif'
    },
    {
        title: 'Bellevue'
    },
    {
        title: 'Beauvais'
    },
    {
        title: 'Meaux'
    },
    {
        title: 'Bobigny'
    },
    {
        title: 'Clamart'
    },
    {
        title: 'Vannes'
    },
    {
        title: 'Chelles'
    },
    {
        title: 'Évry'
    },
    {
        title: 'Épinay-sur-Seine'
    },
    {
        title: 'Saint-Ouen'
    },
    {
        title: 'Saint-Quentin'
    },
    {
        title: 'Bondy'
    },
    {
        title: 'Bayonne'
    },
    {
        title: 'Corbeil-Essonnes'
    },
    {
        title: 'Cagnes-sur-Mer'
    },
    {
        title: 'Vaulx-en-Velin'
    },
    {
        title: 'Sevran'
    },
    {
        title: 'Fontenay-sous-Bois'
    },
    {
        title: 'Sartrouville'
    },
    {
        title: 'Massy'
    },
    {
        title: 'Laval'
    },
    {
        title: 'Saint-Herblain'
    },
    {
        title: 'Gennevilliers'
    },
    {
        title: 'Suresnes'
    },
    {
        title: 'Saint-Priest'
    },
    {
        title: 'Vincennes'
    },
    {
        title: 'Bastia'
    },
    {
        title: 'Montrouge'
    },
    {
        title: 'Évreux'
    },
    {
        title: 'La Courneuve'
    },
    {
        title: 'Charleville-Mézières'
    },
    {
        title: 'Meudon'
    },
    {
        title: 'Choisy-le-Roi'
    },
    {
        title: 'Noisy-le-Sec'
    },
    {
        title: 'Livry-Gargan'
    },
    {
        title: 'Rosny-sous-Bois'
    },
    {
        title: 'Talence'
    },
    {
        title: 'Belfort'
    },
    {
        title: 'Alfortville'
    },
    {
        title: 'Chalon-sur-Saône'
    },
    {
        title: 'Sète'
    },
    {
        title: 'Mantes-la-Jolie'
    },
    {
        title: 'Saint-Brieuc'
    },
    {
        title: 'Tarbes'
    },
    {
        title: 'Alès'
    },
    {
        title: 'Châlons-en-Champagne'
    },
    {
        title: 'Bagneux'
    },
    {
        title: 'Puteaux'
    },
    {
        title: 'Caluire-et-Cuire'
    },
    {
        title: 'Bron'
    },
    {
        title: 'Rezé'
    },
    {
        title: 'Valenciennes'
    },
    {
        title: 'Châteauroux'
    },
    {
        title: 'Garges-lès-Gonesse'
    },
    {
        title: 'Arras'
    },
    {
        title: 'Melun'
    },
    {
        title: 'Le Cannet'
    },
    {
        title: 'Bourg-en-Bresse'
    },
    {
        title: 'Anglet'
    },
    {
        title: 'Angoulême'
    },
    {
        title: 'Boulogne-sur-Mer'
    },
    {
        title: 'Wattrelos'
    },
    {
        title: 'Villenave-d’Ornon'
    },
    {
        title: 'Stains'
    },
    {
        title: 'Gagny'
    },
    {
        title: 'Colomiers'
    },
    {
        title: 'Poissy'
    },
    {
        title: 'Douai'
    },
    {
        title: 'Bagnolet'
    },
    {
        title: 'Marcq-en-Baroeul'
    },
    {
        title: 'Villepinte'
    },
    {
        title: 'Saint-Martin-d’Hères'
    },
    {
        title: 'Chartres'
    },
    {
        title: 'Annemasse'
    },
    {
        title: 'Neuilly-sur-Marne'
    },
    {
        title: 'Franconville'
    },
    {
        title: 'Savigny-sur-Orge'
    },
    {
        title: 'Thonon-les-Bains'
    },
    {
        title: 'Échirolles'
    },
    {
        title: 'Châtillon'
    },
    {
        title: 'Athis-Mons'
    },
    {
        title: 'Creil'
    },
    {
        title: 'Conflans-Sainte-Honorine'
    },
    {
        title: 'Villefranche-sur-Saône'
    },
    {
        title: 'Meyzieu'
    },
    {
        title: 'Sainte-Geneviève-des-Bois'
    },
    {
        title: 'Villeneuve-Saint-Georges'
    },
    {
        title: 'Châtenay-Malabry'
    },
    {
        title: 'Palaiseau'
    },
    {
        title: 'Roanne'
    },
    {
        title: 'Le Perreux-Sur-Marne'
    },
    {
        title: 'Schiltigheim'
    },
    {
        title: 'Les Mureaux'
    },
    {
        title: 'Trappes'
    },
    {
        title: 'Nogent-sur-Marne'
    },
    {
        title: 'Houilles'
    },
    {
        title: 'Montluçon'
    },
    {
        title: 'Romainville'
    },
    {
        title: 'Nevers'
    },
    {
        title: 'Lens'
    },
    {
        title: 'Agen'
    },
    {
        title: 'Pierrefitte-sur-Seine'
    },
    {
        title: 'Bezons'
    },
    {
        title: 'Aix-les-Bains'
    },
    {
        title: 'Montigny-le-Bretonneux'
    },
    {
        title: 'Cambrai'
    },
    {
        title: 'L’Haÿ-les-Roses'
    },
    {
        title: 'Plaisir'
    },
    {
        title: 'Pontoise'
    },
    {
        title: 'Rillieux-la-Pape'
    },
    {
        title: 'Thiais'
    },
    {
        title: 'Vigneux-sur-Seine'
    },
    {
        title: 'Viry-Châtillon'
    },
    {
        title: 'Saint-Laurent-du-Var'
    },
    {
        title: 'Bègles'
    },
    {
        title: 'Goussainville'
    },
    {
        title: 'Villiers-sur-Marne'
    },
    {
        title: 'Cachan'
    },
    {
        title: 'Savigny-le-Temple'
    },
    {
        title: 'Menton'
    },
    {
        title: 'Villemomble'
    },
    {
        title: 'Malakoff'
    },
    {
        title: 'Liévin'
    },
    {
        title: 'La Garenne-Colombes'
    },
    {
        title: 'Ris-Orangis'
    },
    {
        title: 'Bois-Colombes'
    },
    {
        title: 'Clichy-sous-Bois'
    },
    {
        title: 'Décines-Charpieu'
    },
    {
        title: 'Saint-Cloud'
    },
    {
        title: 'Chatou'
    },
    {
        title: 'Vandœuvre-lès-Nancy'
    },
    {
        title: 'Périgueux'
    },
    {
        title: 'Charenton-le-Pont'
    },
    {
        title: 'Tournefeuille'
    },
    {
        title: 'Guyancourt'
    },
    {
        title: 'Le Plessis-Robinson'
    },
    {
        title: 'Draveil'
    },
    {
        title: 'Maubeuge'
    },
    {
        title: 'Ermont'
    },
    {
        title: 'Sotteville-lès-Rouen'
    },
    {
        title: 'Villiers-le-Bel'
    },
    {
        title: 'Fresnes'
    },
    {
        title: 'Soissons'
    },
    {
        title: 'Yerres'
    },
    {
        title: 'Saint-Étienne-du-Rouvray'
    },
    {
        title: 'Dieppe'
    },
    {
        title: 'Saint-Sébastien-sur-Loire'
    },
    {
        title: 'Vallauris'
    },
    {
        title: 'Vanves'
    },
    {
        title: 'Lomme'
    },
    {
        title: 'Limeil-Brévannes'
    },
    {
        title: 'Montfermeil'
    },
    {
        title: 'Le Chesnay'
    },
    {
        title: 'Sucy-en-Brie'
    },
    {
        title: 'Grigny'
    },
    {
        title: 'Lambersart'
    },
    {
        title: 'Brétigny-sur-Orge'
    },
    {
        title: 'Taverny'
    },
    {
        title: 'Oullins'
    },
    {
        title: 'Villeparisis'
    },
    {
        title: 'Cenon'
    },
    {
        title: 'Sannois'
    },
    {
        title: 'Cormeilles-en-Parisis'
    },
    {
        title: 'Bussy-Saint-Georges'
    },
    {
        title: 'Blagnac'
    },
    {
        title: 'Élancourt'
    },
    {
        title: 'Le Grand-Quevilly'
    },
    {
        title: 'La Garde'
    },
    {
        title: 'Gradignan'
    },
    {
        title: 'Vichy'
    },
    {
        title: 'Biarritz'
    },
    {
        title: 'Champs-Sur-Marne'
    },
    {
        title: 'Armentières'
    },
    {
        title: 'Montbéliard'
    },
    {
        title: 'Alençon'
    },
    {
        title: 'Brunoy'
    },
    {
        title: 'Eaubonne'
    },
    {
        title: 'Villeneuve-la-Garenne'
    },
    {
        title: 'Cherbourg'
    },
    {
        title: 'Saint-Ouen-l’Aumône'
    },
    {
        title: 'Béthune'
    },
    {
        title: 'Castelnau-le-Lez'
    },
    {
        title: 'Fontenay-aux-Roses'
    },
    {
        title: 'Orly'
    },
    {
        title: 'Le Kremlin-Bicêtre'
    },
    {
        title: 'Eysines'
    },
    {
        title: 'Le Bouscat'
    },
    {
        title: 'Rodez'
    },
    {
        title: 'Les Pavillons-sous-Bois'
    },
    {
        title: 'La Valette-du-Var'
    },
    {
        title: 'Montgeron'
    },
    {
        title: 'Les Lilas'
    },
    {
        title: 'Lormont'
    },
    {
        title: 'Maisons-Laffitte'
    },
    {
        title: 'Roissy-en-Brie'
    },
    {
        title: 'Loos'
    },
    {
        title: 'Fontaine'
    },
    {
        title: 'Dammarie-lè-Lys'
    },
    {
        title: 'Vélizy-Villacoublay'
    },
    {
        title: 'Tassin-la-Demi-Lune'
    },
    {
        title: 'Sèvres'
    },
    {
        title: 'Montigny-lès-Cormeilles'
    },
    {
        title: 'Deuil-la-Barre'
    },
    {
        title: 'La Madeleine'
    },
    {
        title: 'Torcy'
    },
    {
        title: 'Montereau-faut-Yonne'
    },
    {
        title: 'Combs-la-Ville'
    },
    {
        title: 'Hérouville-Saint-Clair'
    },
    {
        title: 'Montmorency'
    },
    {
        title: 'Sainte-Foy-lès-Lyon'
    },
    {
        title: 'Montigny-lès-Metz'
    },
    {
        title: 'Le Petit-Quevilly'
    },
    {
        title: 'Saint-Jean-de-Braye'
    },
    {
        title: 'Arcueil'
    },
    {
        title: 'Mons-en-Baroeul'
    },
    {
        title: 'Fleury-les-Aubrais'
    },
    {
        title: 'Saint-Michel-sur-Orge'
    },
    {
        title: 'Neuilly-Plaisance'
    },
    {
        title: 'Nogent-sur-Oise'
    },
    {
        title: 'Mantes-la-Ville'
    },
    {
        title: 'Achères'
    },
    {
        title: 'Saint-Mandé'
    },
    {
        title: 'Villeneuve-le-Roi'
    },
    {
        title: 'Saint-Cyr-l’École'
    },
    {
        title: 'Saint-Genis-Laval'
    },
    {
        title: 'Saint-Gratien'
    },
    {
        title: 'Wasquehal'
    },
    {
        title: 'Halluin'
    },
    {
        title: 'Bourg-la-Reine'
    },
    {
        title: 'Morsang-sur-Orge'
    },
    {
        title: 'Croix'
    },
    {
        title: 'Coudekerque-Branche'
    },
    {
        title: 'Fougères'
    },
    {
        title: 'Denain'
    },
    {
        title: 'Longjumeau'
    },
    {
        title: 'Sceaux'
    },
    {
        title: 'Saint-Pol-sur-Mer'
    },
    {
        title: 'La Celle-Saint-Cloud'
    },
    {
        title: 'Joinville-le-Pont'
    },
    {
        title: 'Cugnaux'
    },
    {
        title: 'Chevilly-Larue'
    },
    {
        title: 'Lingolsheim'
    },
    {
        title: 'Le Plessis-Trévise'
    },
    {
        title: 'Le Mée-sur-Seine'
    },
    {
        title: 'Mont-Saint-Aignan'
    },
    {
        title: 'Chaville'
    },
    {
        title: 'Chilly-Mazarin'
    },
    {
        title: 'Lisieux'
    },
    {
        title: 'Ronchin'
    },
    {
        title: 'Saint-Fons'
    },
    {
        title: 'Moulins'
    },
    {
        title: 'Villefontaine'
    },
    {
        title: 'Gentilly'
    },
    {
        title: 'Bonneuil-sur-Marne'
    },
    {
        title: 'Hem'
    },
    {
        title: 'Maurepas'
    },
    {
        title: 'Meylan'
    },
    {
        title: 'Éragny'
    },
    {
        title: 'Hellemmes-Lille'
    },
    {
        title: 'Juvisy-sur-Orge'
    },
    {
        title: 'Chennevières-sur-Marne'
    },
    {
        title: 'Écully'
    },
    {
        title: 'Cran-Gévrier'
    },
    {
        title: 'Carrières-sous-Poissy'
    },
    {
        title: 'Rosendaël'
    },
    {
        title: 'Fâches-Thumesnil'
    },
    {
        title: 'Soisy-sous-Montmorency'
    },
    {
        title: 'Bischheim'
    },
    {
        title: 'Garches'
    },
    {
        title: 'Hendaye'
    },
    {
        title: 'Floirac'
    },
    {
        title: 'Limay'
    },
    {
        title: 'Boissy-Saint-Léger'
    },
    {
        title: 'Le Pontet'
    },
    {
        title: 'Chamalières'
    },
    {
        title: 'Bry-sur-Marne'
    },
    {
        title: 'Les Clayes-sous-Bois'
    },
    {
        title: 'L’Isle-d’Abeau'
    },
    {
        title: 'Jouy-le-Moutier'
    },
    {
        title: 'Cluses'
    },
    {
        title: 'Firminy'
    },
    {
        title: 'Lons-le-Saunier'
    },
    {
        title: 'Saint-Égrève'
    },
    {
        title: 'Viroflay'
    },
    {
        title: 'Octeville'
    },
    {
        title: 'Le Pré-Saint-Gervais'
    },
    {
        title: 'Saint-Jean-de-la-Ruelle'
    },
    {
        title: 'Marly-le-Roi'
    },
    {
        title: 'Domont'
    },
    {
        title: 'Vauréal'
    },
    {
        title: 'Orsay'
    },
    {
        title: 'Saint-Leu-la-Forêt'
    },
    {
        title: 'Verneuil-sur-Seine'
    },
    {
        title: 'Petite-Synthe'
    },
    {
        title: 'Saint-Julien-en-Genevois'
    },
    {
        title: 'Le Pecq'
    },
    {
        title: 'Le Vésinet'
    },
    {
        title: 'Lucé'
    },
    {
        title: 'Wattignies'
    },
    {
        title: 'Noisiel'
    },
    {
        title: 'Bois-d’Arcy'
    },
    {
        title: 'Rive-de-Gier'
    },
    {
        title: 'Saint-Brice-sous-Forêt'
    },
    {
        title: 'Francheville'
    },
    {
        title: 'Longwy'
    },
    {
        title: 'Vesoul'
    },
    {
        title: 'Montargis'
    },
    {
        title: 'Carrières-sur-Seine'
    },
    {
        title: 'Ramonville-Saint-Agne'
    },
    {
        title: 'Castanet-Tolosan'
    },
    {
        title: 'Le Raincy'
    },
    {
        title: 'Le Bourget'
    },
    {
        title: 'Illzach'
    },
    {
        title: 'Montmagny'
    },
    {
        title: 'Villers-lès-Nancy'
    },
    {
        title: 'Haubourdin'
    },
    {
        title: 'Dinan'
    },
    {
        title: 'Lognes'
    },
    {
        title: 'Saint-Maurice'
    },
    {
        title: 'Verrières-le-Buisson'
    },
    {
        title: 'Arnouville-lès-Gonesse'
    },
    {
        title: 'Saint-Genis-Pouilly'
    },
    {
        title: 'Les Sables-d’Olonne'
    },
    {
        title: 'Montesson'
    },
    {
        title: 'Valenton'
    },
    {
        title: 'Chenôve'
    },
    {
        title: 'Bois-Guillaume'
    },
    {
        title: 'Auray'
    },
    {
        title: 'Persan'
    },
    {
        title: 'Fleury-Mérogis'
    },
    {
        title: 'Audincourt'
    },
    {
        title: 'Montévrain'
    },
    {
        title: 'Billère'
    },
    {
        title: 'Lys-lès-Lannoy'
    },
    {
        title: 'Avon'
    },
    {
        title: 'Vaires-sur-Marne'
    },
    {
        title: 'Courcouronnes'
    },
    {
        title: 'Fontenay-le-Fleury'
    },
    {
        title: 'Anzin'
    },
    {
        title: 'Ostwald'
    },
    {
        title: 'Outreau'
    },
    {
        title: 'Andrésy'
    },
    {
        title: 'Morangis'
    },
    {
        title: 'Kingersheim'
    },
    {
        title: 'Mouvaux'
    },
    {
        title: 'Saint-André'
    },
    {
        title: 'Saint-André-les-Vergers'
    },
    {
        title: 'Bayeux'
    },
    {
        title: 'Beausoleil'
    },
    {
        title: 'L’Union'
    },
    {
        title: 'Bully-les-Mines'
    },
    {
        title: 'Riedisheim'
    },
    {
        title: 'Marly'
    },
    {
        title: 'Craponne'
    },
    {
        title: 'Talant'
    },
    {
        title: 'Épinay-sous-Sénart'
    },
    {
        title: 'Le Relecq-Kerhuon'
    },
    {
        title: 'Villecresnes'
    },
    {
        title: 'Hœnheim'
    },
    {
        title: 'Vitry-le-François'
    },
    {
        title: 'Enghien-les-Bains'
    },
    {
        title: 'Méricourt'
    },
    {
        title: 'Yvetot'
    },
    {
        title: 'Dugny'
    },
    {
        title: 'Bruay-sur-l’Escaut'
    },
    {
        title: 'Saint-Germain-lès-Arpajon'
    },
    {
        title: 'Arpajon'
    },
    {
        title: 'Arcachon'
    },
    {
        title: 'Saint-Jean'
    },
    {
        title: 'Marquette-lès-Lille'
    },
    {
        title: 'Cesson'
    },
    {
        title: 'Stiring-Wendel'
    },
    {
        title: 'Maromme'
    },
    {
        title: 'Ferney-Voltaire'
    },
    {
        title: 'Bondoufle'
    },
    {
        title: 'Le Pont-de-Claix'
    },
    {
        title: 'Épinay-sur-Orge'
    },
    {
        title: 'Déville-lès-Rouen'
    },
    {
        title: 'Chanteloup-les-Vignes'
    },
    {
        title: 'Voisins-le-Bretonneux'
    },
    {
        title: 'Ville-d’Avray'
    },
    {
        title: 'Beaumont'
    },
    {
        title: 'Flers-lez-Lille'
    },
    {
        title: 'Tinqueux'
    },
    {
        title: 'Ormesson-sur-Marne'
    },
    {
        title: 'Pierre-Bénite'
    },
    {
        title: 'Clermont'
    },
    {
        title: 'Thorigny-sur-Marne'
    },
    {
        title: 'Croissy-sur-Seine'
    },
    {
        title: 'Villeneuve-Tolosane'
    },
    {
        title: 'Avranches'
    },
    {
        title: 'Pfastatt'
    },
    {
        title: 'Igny'
    },
    {
        title: 'Oignies'
    },
    {
        title: 'Gaillard'
    },
    {
        title: 'Neuville-en-Ferrain'
    },
    {
        title: 'Caudebec-lès-Elbeuf'
    },
    {
        title: 'Saint-Max'
    },
    {
        title: 'Villerupt'
    },
    {
        title: 'Auchel'
    },
    {
        title: 'Vernouillet'
    },
    {
        title: 'Aniche'
    },
    {
        title: 'Eybens'
    },
    {
        title: 'Fosses'
    },
    {
        title: 'Maxéville'
    },
    {
        title: 'Montigny-en-Gohelle'
    },
    {
        title: 'Grigny'
    },
    {
        title: 'Darnétal'
    },
    {
        title: 'Serris'
    },
    {
        title: 'Sallaumines'
    },
    {
        title: 'Neuville-lès-Dieppe'
    },
    {
        title: 'Beaumont-sur-Oise'
    },
    {
        title: 'Leers'
    },
    {
        title: 'Ézanville'
    },
    {
        title: 'Pérols'
    },
    {
        title: 'Crosne'
    },
    {
        title: 'Quincy-sous-Sénart'
    },
    {
        title: 'Jarville-la-Malgrange'
    },
    {
        title: 'Aucamville'
    },
    {
        title: 'Hagondange'
    },
    {
        title: 'Le Crès'
    },
    {
        title: 'Bures-sur-Yvette'
    },
    {
        title: 'Évian-les-Bains'
    },
    {
        title: 'Ville-la-Grand'
    },
    {
        title: 'Beauchamp'
    },
    {
        title: 'Magny-le-Hongre'
    },
    {
        title: 'Bougival'
    },
    {
        title: 'Tomblaine'
    },
    {
        title: 'Le Portel'
    },
    {
        title: 'Fontaine-lès-Dijon'
    },
    {
        title: 'Boucau'
    },
    {
        title: 'Essey-lès-Nancy'
    },
    {
        title: 'Wingles'
    },
    {
        title: 'Montlhéry'
    },
    {
        title: 'L’Île-Saint-Denis'
    },
    {
        title: 'Vaucresson'
    },
    {
        title: 'La Salvetat-Saint-Gilles'
    },
    {
        title: 'Pérenchies'
    },
    {
        title: 'Saint-Aubin-lès-Elbeuf'
    },
    {
        title: 'Groslay'
    },
    {
        title: 'Le Plessis-Bouchard'
    },
    {
        title: 'Meythet'
    },
    {
        title: 'Terrenoire'
    },
    {
        title: 'Harfleur'
    },
    {
        title: 'Carbon-Blanc'
    },
    {
        title: 'Courcelles-les-Lens'
    },
    {
        title: 'Bihorel'
    },
    {
        title: 'La Tour-du-Pin'
    },
    {
        title: 'Souffelweyersheim'
    },
    {
        title: 'Billy-Montigny'
    },
    {
        title: 'La Ville-du-Bois'
    },
    {
        title: 'Coulaines'
    },
    {
        title: 'Boussy-Saint-Antoine'
    },
    {
        title: 'Le Mesnil-Esnard'
    },
    {
        title: 'Talange'
    },
    {
        title: 'Cappelle-la-Grande'
    },
    {
        title: 'Beauzelle'
    },
    {
        title: 'Vaujours'
    },
    {
        title: 'Saint-Germain-lès-Corbeil'
    },
    {
        title: 'Terville'
    },
    {
        title: 'Waziers'
    },
    {
        title: 'Huningue'
    },
    {
        title: 'Villemoisson-sur-Orge'
    },
    {
        title: 'Sainte-Adresse'
    },
    {
        title: 'Guingamp'
    },
    {
        title: 'Fontaines-sur-Saône'
    },
    {
        title: 'Liancourt'
    },
    {
        title: 'Courtry'
    },
    {
        title: 'Gournay-sur-Marne'
    },
    {
        title: 'Sathonay-Camp'
    },
    {
        title: 'Jacou'
    },
    {
        title: 'Beuvrages'
    },
    {
        title: 'Égly'
    },
    {
        title: 'Carnoux-en-Provence'
    },
    {
        title: 'Grenay'
    },
    {
        title: 'Luisant'
    },
    {
        title: 'Noyelles-sous-Lens'
    },
    {
        title: 'La Bassée'
    },
    {
        title: 'Neuves-Maisons'
    },
    {
        title: 'La Mulatière'
    },
    {
        title: 'Bouffémont'
    },
    {
        title: 'La Penne-sur-Huveaune'
    },
    {
        title: 'Jœuf'
    },
    {
        title: 'Cognin'
    },
    {
        title: 'La Montagne'
    },
    {
        title: 'Bonsecours'
    },
    {
        title: 'Saint-Alban'
    },
    {
        title: 'L’Arbresle'
    },
    {
        title: 'Esbly'
    },
    {
        title: 'Le Mesnil-le-Roi'
    },
    {
        title: 'Fouquières-lès-Lens'
    },
    {
        title: 'La Verrière'
    },
    {
        title: 'Ambilly'
    },
    {
        title: 'Saint-Priest-en-Jarez'
    },
    {
        title: 'Magnanville'
    },
    {
        title: 'Pont-de-Chéruy'
    },
    {
        title: 'Menucourt'
    },
    {
        title: 'Ablon-sur-Seine'
    },
    {
        title: 'Champagne-au-Mont-d’Or'
    },
    {
        title: 'Palavas-les-Flots'
    },
    {
        title: 'Saintry-sur-Seine'
    },
    {
        title: 'Saint-André-de-la-Roche'
    },
    {
        title: 'Longueau'
    },
    {
        title: 'Le Port-Marly'
    },
    {
        title: 'Oberhausbergen'
    },
    {
        title: 'Heillecourt'
    },
    {
        title: 'Crégy-lès-Meaux'
    },
    {
        title: 'Loison-sous-Lens'
    },
    {
        title: 'Montaigu'
    },
    {
        title: 'Bassens'
    },
    {
        title: 'Grand-Fort-Philippe'
    },
    {
        title: 'Mandres-les-Roses'
    },
    {
        title: 'La Frette-sur-Seine'
    },
    {
        title: 'Le Ban-Saint-Martin'
    },
    {
        title: 'Saint-Nicolas'
    },
    {
        title: 'Villiers-sur-Orge'
    },
    {
        title: 'Cap-d’Ail'
    },
    {
        title: 'Nilvange'
    },
    {
        title: 'Leuville-sur-Orge'
    },
    {
        title: 'Jacob-Bellecombette'
    },
    {
        title: 'Valras-Plage'
    },
    {
        title: 'Avesnes-sur-Helpe'
    },
    {
        title: 'Ascq'
    },
    {
        title: 'Longeville-lès-Metz'
    },
    {
        title: 'Neuville-Saint-Rémy'
    },
    {
        title: 'Toufflers'
    },
    {
        title: 'Mareil-Marly'
    },
    {
        title: 'Lourches'
    },
    {
        title: 'Beaulieu-sur-Mer'
    },
    {
        title: 'Sochaux'
    },
    {
        title: 'Bergues'
    },
    {
        title: 'Barby'
    },
    {
        title: 'Fort-Mardyck'
    },
    {
        title: 'Neauphle-le-Château'
    },
    {
        title: 'Saint-Mammès'
    },
    {
        title: 'Les Noës-près-Troyes'
    },
    {
        title: 'Pont-à-Vendin'
    },
    {
        title: 'Rouelles'
    },
    {
        title: 'Paimbœuf'
    },
    {
        title: 'Courchelettes'
    },
    {
        title: 'Éleu-dit-Leauwette'
    },
    {
        title: 'Port-Louis'
    },
    {
        title: 'Sainte-Foy-la-Grande'
    }
]


export const uaeCampus = [
    {
        title: 'Dubai',
    },
    {
        title: 'Sharjah',
    },
    {
        title: 'Abu Dhabi',
    },
]
export const australiaCampus = [
    {
        title: 'Albert Park',
        group: 'Victoria'
    },
    {
        title: 'Altona North',
        group: 'Victoria'
    },
    {
        title: 'Balwyn',
        group: 'Victoria'
    },
    {
        title: 'Berwick',
        group: 'Victoria'
    },
    {
        title: 'Blackburn',
        group: 'Victoria'
    },
    {
        title: 'Brighton',
        group: 'Victoria'
    },
    {
        title: 'Carlton',
        group: 'Victoria'
    },
    {
        title: 'Caroline Springs',
        group: 'Victoria'
    },
    {
        title: 'Croydon',
        group: 'Victoria'
    },
    {
        title: 'Dandenong',
        group: 'Victoria'
    },
    {
        title: 'Caroline Springs',
        group: 'Victoria'
    },
    {
        title: 'Docklands',
        group: 'Victoria'
    },
    {
        title: 'Doncaster',
        group: 'Victoria'
    },
    {
        title: 'East Melbourne',
        group: 'Victoria'
    },
    {
        title: 'Elwood',
        group: 'Victoria'
    },
    {
        title: 'Fitzroy',
        group: 'Victoria'
    },
    {
        title: 'Footscray',
        group: 'Victoria'
    },
    {
        title: 'Frankston',
        group: 'Victoria'
    },
    {
        title: 'Glen Waverley',
        group: 'Victoria'
    },
    {
        title: 'Hawthorn East',
        group: 'Victoria'
    },
    {
        title: 'Hoppers Crossing',
        group: 'Victoria'
    },
    {
        title: 'Kensington',
        group: 'Victoria'
    },
    {
        title: 'Langwarrin',
        group: 'Victoria'
    },
    {
        title: 'Lynbrook',
        group: 'Victoria'
    },
    {
        title: 'Melbourne (CBD)',
        group: 'Victoria'
    },
    {
        title: 'Mill Park',
        group: 'Victoria'
    },
    {
        title: 'Mount Eliza',
        group: 'Victoria'
    },
    {
        title: 'Noble Park',
        group: 'Victoria'
    },
    {
        title: 'North Melbourne',
        group: 'Victoria'
    },
    {
        title: 'Patterson Lakes',
        group: 'Victoria'
    },
    {
        title: 'Point Cook',
        group: 'Victoria'
    },
    {
        title: 'Port Melbourne',
        group: 'Victoria'
    },
    {
        title: 'Reservoir',
        group: 'Victoria'
    },
    {
        title: 'Richmond',
        group: 'Victoria'
    },
    {
        title: 'Southbank',
        group: 'Victoria'
    },
    {
        title: 'St Kilda',
        group: 'Victoria'
    },
    {
        title: 'Sunbury',
        group: 'Victoria'
    },
    {
        title: 'Templestowe',
        group: 'Victoria'
    },
    {
        title: ' Armidale',
        group: 'New South Wales'
    },
    {
        title: 'Auburn',
        group: 'New South Wales'
    },
    {
        title: 'Bankstown',
        group: 'New South Wales'
    },
    {
        title: 'Blacktown',
        group: 'New South Wales'
    },
    {
        title: 'Bondi',
        group: 'New South Wales'
    },
    {
        title: 'Castle Hill',
        group: 'New South Wales'
    },
    {
        title: 'Coffs Harbour',
        group: 'New South Wales'
    },
    {
        title: 'Collaroy',
        group: 'New South Wales'
    },
    {
        title: 'Cowra',
        group: 'New South Wales'
    },
    {
        title: 'Dubbo',
        group: 'New South Wales'
    },
    {
        title: 'Eastwood',
        group: 'New South Wales'
    },
    {
        title: 'Homebush West',
        group: 'New South Wales'
    },
    {
        title: 'Hurstville',
        group: 'New South Wales'
    },
    {
        title: 'Ingleburn',
        group: 'New South Wales'
    },
    {
        title: 'Kellyville',
        group: 'New South Wales'
    },
    {
        title: 'Kincumber',
        group: 'New South Wales'
    },
    {
        title: 'Kings Langley',
        group: 'New South Wales'
    },
    {
        title: 'Liverpool',
        group: 'New South Wales'
    },
    {
        title: 'Macquarie Fields',
        group: 'New South Wales'
    },
    {
        title: 'Manly',
        group: 'New South Wales'
    },
    {
        title: 'Matraville',
        group: 'New South Wales'
    },
    {
        title: 'Meadowbank',
        group: 'New South Wales'
    },
    {
        title: 'Merrylands',
        group: 'New South Wales'
    },
    {
        title: 'Mount Druitt',
        group: 'New South Wales'
    },
    {
        title: 'Mudgee',
        group: 'New South Wales'
    },
    {
        title: 'Newtown',
        group: 'New South Wales'
    },
    {
        title: 'North Narrabeen',
        group: 'New South Wales'
    },
    {
        title: 'Paddington',
        group: 'New South Wales'
    },
    {
        title: 'Parramatta',
        group: 'New South Wales'
    },
    {
        title: 'Pemulwuy',
        group: 'New South Wales'
    },
    {
        title: 'Port Macquarie',
        group: 'New South Wales'
    },
    {
        title: 'Quakers Hill',
        group: 'New South Wales'
    },
    {
        title: 'Queanbeyan',
        group: 'New South Wales'
    },
    {
        title: 'Rouse Hill',
        group: 'New South Wales'
    },
    {
        title: 'Seven Hills',
        group: 'New South Wales'
    },
    {
        title: 'Surry Hills',
        group: 'New South Wales'
    },
    {
        title: 'Sydney (CBD)',
        group: 'New South Wales'
    },
    {
        title: 'Wentworthville',
        group: 'New South Wales'
    },
    {
        title: 'Wollongong',
        group: 'New South Wales'
    },
    {
        title: 'Arundel',
        group: 'Queensland'
    },
    {
        title: 'Gold Cost',
        group: 'Queensland'
    },
    {
        title: 'Ashgrove',
        group: 'Queensland'
    },
    {
        title: 'Queensland',
        group: 'Queensland'
    },
    {
        title: 'Bracken Ridge',
        group: 'Queensland'
    },
    {
        title: 'Brighton',
        group: 'Queensland'
    },
    {
        title: 'Brisbane (CBD)',
        group: 'Queensland'
    },
    {
        title: 'Currumbin Waters',
        group: 'Queensland'
    },
    {
        title: 'Ferny Grove',
        group: 'Queensland'
    },
    {
        title: 'Fortitude Valley',
        group: 'Queensland'
    },
    {
        title: 'Hope Island',
        group: 'Queensland'
    },
    {
        title: 'Ipswich',
        group: 'Queensland'
    },
    {
        title: 'Manly',
        group: 'Queensland'
    },
    {
        title: 'Milton',
        group: 'Queensland'
    },
    {
        title: 'Moorooka',
        group: 'Queensland'
    },
    {
        title: 'New Farm',
        group: 'Queensland'
    },
    {
        title: 'Nundah',
        group: 'Queensland'
    },
    {
        title: 'Oxley',
        group: 'Queensland'
    },
    {
        title: 'Paddington',
        group: 'Queensland'
    },
    {
        title: 'Paradise Point',
        group: 'Queensland'
    },
    {
        title: 'Petrie',
        group: 'Queensland'
    },
    {
        title: 'Pimpama',
        group: 'Queensland'
    },
    {
        title: 'Port Douglas',
        group: 'Queensland'
    },
    {
        title: 'Redcliffe',
        group: 'Queensland'
    },
    {
        title: 'Southport',
        group: 'Queensland'
    },
    {
        title: 'Springfield Lakes',
        group: 'Queensland'
    },
    {
        title: 'Stafford',
        group: 'Queensland'
    },
    {
        title: 'Surfers Paradise',
        group: 'Queensland'
    },
    {
        title: 'Toowong',
        group: 'Queensland'
    },
    {
        title: 'Toowoomba City',
        group: 'Queensland'
    },
    {
        title: 'West End',
        group: 'Queensland'
    },
    {
        title: 'Woodridge',
        group: 'Queensland'
    },
    {
        title: 'Adelaide',
        group: 'South Australia'
    },
    {
        title: 'Aldinga Beach',
        group: 'Queensland'
    },
    {
        title: 'Blakeview',
        group: 'South Australia'
    },
    {
        title: 'Davoren Park',
        group: 'Queensland'
    },
    {
        title: 'Flagstaff Hill',
        group: 'South Australia'
    },
    {
        title: 'Glenelg',
        group: 'Queensland'
    },
    {
        title: 'Mawson Lakes',
        group: 'South Australia'
    },
    {
        title: 'McLaren Flat',
        group: 'Queensland'
    },
    {
        title: 'Mount Gambier',
        group: 'South Australia'
    },
    {
        title: 'Myrtle Bank',
        group: 'Queensland'
    },
    {
        title: 'North Adelaide',
        group: 'South Australia'
    },
    {
        title: 'North Haven',
        group: 'Queensland'
    },
    {
        title: 'Plympton Park',
        group: 'South Australia'
    },
    {
        title: 'Prospect',
        group: 'Queensland'
    },
    {
        title: 'Ridgehaven',
        group: 'South Australia'
    },
    {
        title: 'Seaford Rise',
        group: 'Queensland'
    },
    {
        title: 'Royal Park',
        group: 'South Australia'
    },
    {
        title: 'Aubin Grove',
        group: 'Western Australia'
    },
    {
        title: 'Dianella',
        group: 'South Australia'
    },
    {
        title: 'Doubleview',
        group: 'Western Australia'
    },
    {
        title: 'Duncraig',
        group: 'South Australia'
    },
    {
        title: 'Langford',
        group: 'Western Australia'
    },
    {
        title: 'Mindarie',
        group: 'South Australia'
    },
    {
        title: 'Northam',
        group: 'Western Australia'
    },
    {
        title: 'Perth (CBD)',
        group: 'South Australia'
    },
    {
        title: 'Rivervale',
        group: 'Western Australia'
    },
    {
        title: 'Secret Harbour',
        group: 'South Australia'
    },
    {
        title: 'Tapping',
        group: 'Western Australia'
    },
    {
        title: 'Willetton',
        group: 'South Australia'
    },
    {
        title: 'Woodvale',
        group: 'Western Australia'
    },
    {
        title: 'Blackmans Bay',
        group: 'Tasmania'
    },
    {
        title: 'Bridgewater',
        group: 'Western Australia'
    },
    {
        title: 'George Town',
        group: 'Tasmania'
    },
    {
        title: 'Glenorchy',
        group: 'Western Australia'
    },
    {
        title: 'Hobart',
        group: 'Tasmania'
    },
    {
        title: 'Launceston',
        group: 'Western Australia'
    },
    {
        title: 'Longford',
        group: 'Tasmania'
    },
    {
        title: 'Norwood',
        group: 'Western Australia'
    },
    {
        title: 'Queenstown',
        group: 'Tasmania'
    },
    {
        title: 'South Hobart',
        group: 'Western Australia'
    }, 
]
export const pakistanCampus = [
    {
        title: 'Karachi'
    },
    {
        title: 'Lahore'
    },
    {
        title: 'Faisalabad'
    },
    {
        title: 'Islamabad'
    },
    {
        title: 'Gujranwala'
    },
    {
        title: 'Peshawar'
    },
    {
        title: 'Multan'
    },
    {
        title: 'Quetta'
    },
    {
        title: 'Bahawalpur'
    },
    {
        title: 'Hyderabad'
    },
    {
        title: 'Sargodha'
    },
    {
        title: 'Sialkot'
    },
]
export const germanyCampus = [
    { title: 'Berlin' },
    { title: 'Stuttgart' },
    { title: 'Munich' },
    { title: 'Hamburg' },
    { title: 'Cologne' },
    { title: 'Frankfurt' },
    { title: 'Düsseldorf' },
    { title: 'Leipzig' },
    { title: 'Dortmund' },
    { title: 'Essen' },
    { title: 'Bremen' },
    { title: 'Dresden' },
    { title: 'Hannover' },
    { title: 'Nuremberg' },
    { title: 'Duisburg' },
    { title: 'Bochum' },
    { title: 'Wuppertal' },
    { title: 'Bielefeld' },
    { title: 'Bonn' },
    { title: 'Münster' },
    { title: 'Mannheim' },
    { title: 'Karlsruhe' },
    { title: 'Augsburg' },
    { title: 'Wiesbaden' },
    { title: 'Mönchengladbach' },
    { title: 'Gelsenkirchen' },
    { title: 'Aachen' },
    { title: 'Braunschweig' },
    { title: 'Chemnitz' },
    { title: 'Kiel' },
    { title: 'Halle' },
    { title: 'Magdeburg' },
    { title: 'Freiburg im Breisgau' },
    { title: 'Krefeld' },
    { title: 'Mainz' },
    { title: 'Lübeck' },
    { title: 'Oberhausen' },
    { title: 'Rostock' },
    { title: 'Kassel' },
    { title: 'Hagen' },
    { title: 'Potsdam' },
    { title: 'Saarbrücken' },
    { title: 'Hamm' },
    { title: 'Ludwigshafen' },
    { title: 'Oldenburg' },
    { title: 'Mülheim' },
    { title: 'Osnabrück' },
    { title: 'Leverkusen' },
    { title: 'Heidelberg' },
    { title: 'Darmstadt' },
    { title: 'Solingen' },
    { title: 'Regensburg' },
    { title: 'Herne' },
    { title: 'Paderborn' },
    { title: 'Neuss' },
    { title: 'Ingolstadt' },
    { title: 'Fürth' },
    { title: 'Ulm' },
    { title: 'Heilbronn' },
    { title: 'Pforzheim' },
    { title: 'Würzburg' },
    { title: 'Wolfsburg' },
    { title: 'Göttingen' },
    { title: 'Bottrop' },
    { title: 'Reutlingen' },
    { title: 'Erlangen' },
    { title: 'Bremerhaven' },
    { title: 'Koblenz' },
    { title: 'Bergisch Gladbach' },
    { title: 'Remscheid' },
    { title: 'Trier' },
    { title: 'Recklinghausen' },
    { title: 'Jena' },
    { title: 'Moers' },
    { title: 'Salzgitter' },
    { title: 'Siegen' },
    { title: 'Gütersloh' },
    { title: 'Hildesheim' },
    { title: 'Hanau' },
    { title: 'Kaiserslautern' },
    { title: 'Schwerin' },
    { title: 'Esslingen' },
    { title: 'Ludwigsburg' },
    { title: 'Flensburg' },
    { title: 'Rosenheim' },
    { title: 'Herten' },
    { title: 'Gladbeck' },
    { title: 'Offenbach' },
    { title: 'Erfurt' },
];
export const newZealandCampus = [
    {
        title: 'Auckland'
    },
    {
        title: 'Devonport'
    },
    {
        title: 'Waiuku'
    },
    {
        title: 'Rotorua'
    },
    {
        title: 'Tauranga'
    },
    {
        title: 'Rotorua'
    },
    {
        title: 'Akaroa'
    },
    {
        title: 'Christchurch'
    },
    {
        title: 'Lyttelton'
    },
    {
        title: 'Timaru'
    },
    {
        title: 'Gisborne'
    },
    {
        title: 'Hastings'
    },
    {
        title: 'Napier'
    },
    {
        title: 'Pahiatua'
    },
    {
        title: 'Palmerston North'
    },
    {
        title: 'Taihape'
    },
    {
        title: 'Wanganui'
    },
    {
        title: 'Blenheim'
    },
    {
        title: 'Picton'
    },
    {
        title: 'Nelson'
    },
    {
        title: 'Whangārei'
    },
    {
        title: 'Kaitaia'
    },
    {
        title: 'Russell'
    },
    {
        title: 'Alexandra'
    },
    {
        title: 'Dunedin'
    },
    {
        title: 'Kaitangata'
    },
    {
        title: 'Oamaru'
    },
    {
        title: 'Invercargill'
    },
    {
        title: 'Hawera'
    },
    {
        title: 'Kapuni'
    },
    {
        title: 'New Plymouth'
    },
    {
        title: 'Stratford'
    },
    {
        title: 'Paeroa'
    },
    {
        title: 'Te Aroha'
    },
    {
        title: 'Tokoroa'
    },
    {
        title: 'Waihi'
    },
    {
        title: 'Lower Hutt'
    },
    {
        title: 'Masterton'
    },
    {
        title: 'Porirua'
    },
    {
        title: 'Upper Hutt'
    },
    {
        title: 'Wellington'
    },
    {
        title: 'Greymouth'
    },
    {
        title: 'Hokitika'
    },
    {
        title: 'Westport'
    },
    
]
export const usaCampus = [
    {
        title: 'Chambersburg'
    },
    {
        title: 'Washington'
    },
    {
        title: 'York'
    },
    {
        title: 'Moon Township'
    },
    {
        title: 'Bethlehem'
    },
    {
        title: 'Georgetown'
    },
    {
        title: 'Kerrville'
    },
    {
        title: 'Abilene'
    },
    {
        title: 'Sherman'
    },
    {
        title: 'Chicago'
    },
    {
        title: 'Springfield'
    },
    {
        title: 'Palos Heights'
    },
    {
        title: 'Romeoville'
    },
    {
        title: 'Eureka'
    },
    {
        title: 'Carlinville'
    },
    {
        title: 'Auburn'
    },
    {
        title: 'Montgomery'
    },
    {
        title: 'Montgomery'
    },
    {
        title: 'Franklin'
    },
    {
        title: 'Boston'
    },
    {
        title: 'Columbia'
    },
    {
        title: 'Bridgewater'
    },
    {
        title: 'Lynchburg'
    },
    {
        title: 'Winchester'
    },
    {
        title: 'Lynchburg'
    },
    {
        title: 'Virginia Beach'
    },
    {
        title: 'Charleston'
    },
    {
        title: 'Fayette'
    },
    {
        title: 'Rolla'
    },
    {
        title: 'Celeveland'
    },
    {
        title: 'Hiram'
    },
    {
        title: 'Delaware'
    },
    {
        title: 'Dayton'
    },
    {
        title: 'Mitchell'
    },
    {
        title: 'Monmouth'
    },
    {
        title: 'Garden city'
    },
    {
        title: 'Stony Brook'
    },
    {
        title: 'Sparkill'
    },
    {
        title: 'Buffalo'
    },
    {
        title: 'Los Angeles'
    },
    {
        title: 'Northern'
    },
    {
        title: 'Whittier'
    },
    {
        title: 'DC'
    },
    {
        title: 'Spokane'
    },
    {
        title: 'Seattle'
    },
    {
        title: 'Louisville'
    },
    {
        title: 'Nashville'
    },
    {
        title: 'Waukesha'
    },
    {
        title: 'Sheboygan'
    },
    {
        title: 'Madison'
    },
    {
        title: 'Fairfield'
    },
    {
        title: 'Boca Raton'
    },
    {
        title: 'West Palm Beach'
    },
    {
        title: 'Orlando'
    },
    {
        title: 'Miami'
    },
    {
        title: 'Hanover'
    },
    {
        title: 'Notre Dame'
    },
    {
        title: 'Baltimore'
    },
    {
        title: 'Emmitsburg'
    },
    {
        title: 'Baton Rouge'
    },
    {
        title: 'New Orleans'
    },
    {
        title: 'Williamsport'
    },
    {
        title: 'Oklahoma City'
    },
    {
        title: 'Camden'
    },
    {
        title: 'Newport'
    },
    {
        title: 'St. Paul'
    },
    {
        title: 'Dubuque'
    },
    {
        title: 'Lawrence'
    },
    {
        title: 'Leavenworth'
    },
    {
        title: 'Reno'
    },
    {
        title: 'Salt Lake City'
    },
    {
        title: 'Laramie'
    },
    
]
export const ukCampus = [
    { title: 'Aberystwyth' },
    { title: 'London' },
    { title: 'Birmingham' },
    { title: 'Portsmouth' },
    { title: 'Southampton' },
    { title: 'Nottingham' },
    { title: 'Bristol' },
    { title: 'Manchester' },
    { title: 'Liverpool' },
    { title: 'Leicester' },
    { title: 'Worthing' },
    { title: 'Coventry' },
    { title: 'Belfast' },
    { title: 'Bradford' },
    { title: 'Derby' },
    { title: 'Plymouth' },
    { title: 'Westminster' },
    { title: 'Wolverhampton' },
    { title: 'Northampton' },
    { title: 'Norwich' },
    { title: 'Luton' },
    { title: 'Solihull' },
    { title: 'Islington' },
    { title: 'Aberdeen' },
    { title: 'Croydon' },
    { title: 'Bournemouth' },
    { title: 'Basildon' },
    { title: 'Maidstone' },
    { title: 'Ilford' },
    { title: 'Warrington' },
    { title: 'Oxford' },
    { title: 'Harrow' },
    { title: 'West Bromwich' },
    { title: 'Gloucester' },
    { title: 'York' },
    { title: 'Blackpool' },
    { title: 'Stockport' },
    { title: 'Sale' },
    { title: 'Tottenham' },
    { title: 'Cambridge' },
    { title: 'Romford' },
    { title: 'Colchester' },
    { title: 'High Wycombe' },
    { title: 'Gateshead' },
    { title: 'Slough' },
    { title: 'Blackburn' },
    { title: 'Chelmsford' },
    { title: 'Rochdale' },
    { title: 'Rotherham' },
    { title: 'Walthamstow' },
    { title: 'Basingstoke' },
    { title: 'Salford' },
    { title: 'Hounslow' },
    { title: 'Wembley' },
    { title: 'Worcester' },
    { title: 'Hammersmith' },
    { title: 'Rayleigh' },
    { title: 'Hemel Hempstead' },
    { title: 'Bath' },
    { title: 'Hayes' },
    { title: 'Darlington' },
    { title: 'Hove' },
    { title: 'Hastings' },
    { title: 'Watford' },
    { title: 'Stevenage' },
    { title: 'Hartlepool' },
    { title: 'Chester' },
    { title: 'Fulham' },
    { title: 'Nuneaton' },
    { title: 'Ealing' },
    { title: 'Aylesbury' },
    { title: 'Edmonton' },
    { title: 'Saint Albans' },
    { title: 'Burnley' },
    { title: 'Batley' },
    { title: 'Scunthorpe' },
    { title: 'Dudley' },
    { title: 'Brixton' },
    { title: 'Southall' },
    { title: 'Paisley' },
    { title: 'Chatham' },
    { title: 'East Ham' },
    { title: 'Weston-super-Mare' },
    { title: 'Carlisle' },
    { title: 'South Shields' },
    { title: 'East Kilbride' },
    { title: 'Burton upon Trent' },
    { title: 'Harrogate' },
    { title: 'Crewe' },
    { title: 'Lowestoft' },
    { title: 'Rugby' },
    { title: 'Chingford' },
    { title: 'Uxbridge' },
    { title: 'Walsall' },
    { title: 'Grays' },
    { title: 'Walton upon Thames' },
    { title: 'Thornton Heath' },
    { title: 'Finchley' },
    { title: 'Kensington' },
    { title: 'Boston' },
    { title: 'Paignton' },
    { title: 'Waterlooville' },
    { title: 'Guiseley' },
    { title: 'Hornchurch' },
    { title: 'Mitcham' },
    { title: 'Feltham' },
    { title: 'Stourbridge' },
    { title: 'Rochester' },
    { title: 'Dewsbury' },
    { title: 'Acton' },
    { title: 'Twickenham' },
    { title: 'Wrecsam' },
    { title: 'Ellesmere Port' },
    { title: 'Bangor' },
    { title: 'Taunton' },
    { title: 'Loughborough' },
    { title: 'Barking' },
    { title: 'Edgware' },
    { title: 'Littlehampton' },
    { title: 'Ruislip' },
    { title: 'Streatham' },
    { title: 'Royal Tunbridge Wells' },
    { title: 'Bebington' },
    { title: 'Macclesfield' },
    { title: 'Wellingborough' },
    { title: 'Kettering' },
    { title: 'Braintree' },
    { title: 'Royal Leamington Spa' },
    { title: 'Barrow in Furness' },
    { title: 'Dunfermline' },
    { title: 'Altrincham' },
    { title: 'Lancaster' },
    { title: 'Crosby' },
    { title: 'Bootle' },
    { title: 'Stratford' },
    { title: 'Folkestone' },
    { title: 'Cumbernauld' },
    { title: 'Andover' },
    { title: 'Neath' },
    { title: 'Rowley Regis' },
    { title: 'Scarborough' },
    { title: 'Leith' },
    { title: 'Yeovil' },
    { title: 'Eltham' },
    { title: 'Hampstead' },
    { title: 'Sutton in Ashfield' },
    { title: 'Morden' },
    { title: 'Barnet' },
    { title: 'Stretford' },
    { title: 'Beckenham' },
    { title: 'Greenford' },
    { title: 'Cheshunt' },
    { title: 'Kirkby' },
    { title: 'Salisbury' },
    { title: 'Ashton' },
    { title: 'Surbiton' },
    { title: 'Castleford' },
    { title: 'Catford' },
    { title: 'Worksop' },
    { title: 'Morley' },
    { title: 'Merthyr Tudful' },
    { title: 'Middleton' },
    { title: 'Fleet' },
    { title: 'Fareham' },
    { title: 'Urmston' },
    { title: 'Sutton' },
    { title: 'Caerphilly' },
    { title: 'Bridgwater' },
    { title: 'Newbury' },
    { title: 'Welling' },
    { title: 'Kingswood' },
    { title: 'Dunstable' },
    { title: 'Ramsgate' },
    { title: 'Strood' },
    { title: 'Cleethorpes' },
    { title: 'Pinner' },
    { title: 'Great Yarmouth' },
    { title: 'Ilkeston' },
    { title: 'Chorley' },
    { title: 'Herne Bay' },
    { title: 'Bishops Stortford' },
    { title: 'Arnold' },
    { title: 'Coalville' },
    { title: 'Bletchley' },
    { title: 'Leighton Buzzard' },
    { title: 'Airdrie' },
    { title: 'Blyth' },
    { title: 'Laindon' },
    { title: 'Llanelli' },
    { title: 'Beeston' },
    { title: 'Small Heath' },
    { title: 'Whitley Bay' },
    { title: 'Denton' },
    { title: 'West Bridgford' },
    { title: 'Borehamwood' },
    { title: 'Falkirk' },
    { title: 'Walkden' },
    { title: 'Kenton' },
    { title: 'Bridlington' },
    { title: 'Billingham' },
    { title: 'Grantham' },
    { title: 'North Shields' },
    { title: 'Hitchin' },
    { title: 'Spalding' },
    { title: 'Rainham' },
    { title: 'Letchworth' },
    { title: 'Wickford' },
    { title: 'Huyton' },
    { title: 'Abingdon' },
    { title: 'Trowbridge' },
    { title: 'Wigston Magna' },
    { title: 'Didcot' },
    { title: 'Earley' },
    { title: 'Bexleyheath' },
    { title: 'Ecclesfield' },
    { title: 'Darwen' },
    { title: 'Prestwich' },
    { title: 'Pontypridd' },
    { title: 'Rutherglen' },
    { title: 'Dover' },
    { title: 'Chichester' },
    { title: 'Deal' },
    { title: 'Bicester' },
    { title: 'Northolt' },
    { title: 'Wishaw' },
    { title: 'Carshalton' },
    { title: 'Bulwell' },
    { title: 'Newtownards' },
    { title: 'Kendal' },
    { title: 'Cramlington' },
    { title: 'Bromsgrove' },
    { title: 'Pont-y-pŵl' },
    { title: 'Hanwell' },
    { title: 'Frome' },
    { title: 'Wood Green' },
    { title: 'Darlaston' },
    { title: 'Ashington' },
    { title: 'Longton' },
    { title: 'Melton Mowbray' },
    { title: 'Aldridge' },
    { title: 'Farnworth' },
    { title: 'Highbury' },
    { title: 'Cheadle Hulme' },
    { title: 'Newton Aycliffe' },
    { title: 'Bournville' },
    { title: 'Shenley Brook End' },
    { title: 'Consett' },
    { title: 'Coulsdon' },
    { title: 'Bilston' },
    { title: 'Wellington' },
    { title: 'Bishop Auckland' },
    { title: 'Longbridge' },
    { title: 'Bloxwich' },
    { title: 'Upminster' },
    { title: 'Rhyl' },
    { title: 'Droitwich' },
    { title: 'Hindley' },
    { title: 'Westhoughton' },
    { title: 'Broadstairs' },
];
export const canadaCampus = [
    {
        title: 'Boucherville, Quebec'
    },
    {
        title: 'Victoria-by-the-Sea, Prince Edward Island'
    },
    {
        title: 'St. Andrews by-the-Sea, New Brunswick'
    },
    {
        title: 'Dundas, Ontario'
    },
    {
        title: 'St. Albert, Alberta'
    },
    {
        title: 'North Vancouver, British Columbia'
    },
    {
        title: 'Wellington, Ontario'
    },
    {
        title: 'Portneuf, Quebec'
    },
    {
        title: 'Kansas, Missouri'
    },
    {
        title: 'Niagra Falls, Ontario'
    },
]
export const active_status = [
    {
        title: 'Active'
    },
    {
        title: 'Inactive'
    },
    {
        title: 'All'
    },
]
export const activate_status = [
    {
        title: 'Active'
    },
    {
        title: 'Inactive'
    },
]
export const eduFileType = [
    {
        title: 'Ceo file',
        value: 'ceo_file',
    },
    {
        title: 'Acceptance file',
        value: 'acceptance_file',
    },
    {
        title: 'Payment file',
        value: 'payment_file',
    }
];
export const migFileType = [
    {
        title: 'Withdraw File',
        value: 'withdraw_file',
    },
    {
        title: 'Approval File',
        value: 'approval_file',
    },
];
export const intakeQuarters = [
    {
        name: 'Q1',
    },
    {
        name: 'Q2',
    },
    {
        name: 'Q3',
    },
    {
        name: 'Q4',
    },
];
export const intakeYears = [
    {
        name: '2019',
    },
    {
        name: '2020',
    },
    {
        name: '2021',
    },
    {
        name: '2022',
    },
    {
        name: '2023',
    },
    {
        name: '2024',
    },
    {
        name: '2025',
    },
    {
        name: '2026',
    },
    {
        name: '2027',
    },
    {
        name: '2028',
    },
    {
        name: '2029',
    },
];
export const info_message = "Please clear below search result by clicking on (Clear All) button before filtering data from above filters, after filtering data from start/end/status filter you can search from below search fields..";

export default {intakeYears, intakeQuarters ,eduFileType, migFileType, info_message, activate_status, ukCampus,active_status,countries_code, expo_branches, hear_about_us, expo_status, days, in_days,marital_status, destination_country, ftype, genders, category, education_category, tution_fee, genericDiscipline, duration, prerequisites, education_sector, events, healthProviders, australiaCampus, pakistanCampus, canadaCampus,newZealandCampus, usaCampus, franceCampus, uaeCampus}