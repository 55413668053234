import React, { Component } from 'react';
import swal from 'sweetalert';
import Title from '../GenericComponents/Title';
import { get_ApiManager, post_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import moment from 'moment';
import TaskModal from './TaskModal';
import Loader from '../Loader/Loader';

class ProcessingTasksCards extends Component {
    constructor(props) {
        super(props);
        var user = JSON.parse(localStorage.getItem('user'));
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            user: user,
            isManager: Number(permissions?.['manage_tasks']) === 1,
            highPriorityTasks: [],
            standardTasks: [],
            loading: true,
            selectedTask: null,
            showTaskModal: false,
            allPermissions: permissions,
            usersList: [],
            processingActions: new Set(),
        };
    }

    componentDidMount() {
        this.fetchTasks();
        if (this.state.isManager) {
            this.fetchAllUsers();
        }
    }

    claimTask = async (taskId) => {
        if (this.state.processingActions.has(`claim-${taskId}`)) return;

        this.setState(prevState => ({
            processingActions: new Set(prevState.processingActions).add(`claim-${taskId}`)
        }));

        const data = { 
            claimed_by: this.state.user.id,
            assigned_to: this.state.user.id
        };
        let url = `claimTask/${taskId}`;

        try {
            post_ApiManager(data, url, (res) => {
                        if (res.data.status) {
                            this.fetchTasks();
                            swal("Success", "Task claimed successfully", "success");
                        } else {
                            swal("Error", "Failed to claim task", "error");
                        }
                    }, (err) => {
                        console.log(err);
                        swal("Error", "Failed to claim task", "error");
                    });
                } 
                finally {
                this.setState(prevState => {
                const newProcessingActions = new Set(prevState.processingActions);
                newProcessingActions.delete(`claim-${taskId}`);
                return { processingActions: newProcessingActions };
            });
        }
    }

    fetchTasks = () => {
        const { isManager, user } = this.state;
        // const url = isManager ? 'getAllTasks' : `getDailyTasks/${user.id}`;
        const url = isManager ? 'getAllTasks' : `getAllTasks`;

    
        // API call to fetch tasks
        get_ApiManager('', url, 
            (res) => {
                console.log('tasks-response', res);
    
                try {
                    // Check if res exists and has data property
                    const tasksData = res?.data?.data || res?.data || res;
                    
                    if (tasksData) {
                        let tasks = tasksData;
    
                        // Filter and process tasks based on user role
                        if (isManager) {
                            // Ensure tasks is an array before filtering
                            tasks = Array.isArray(tasks) ? tasks : [];
                            
                            const highPriorityTasks = tasks.filter(task => task.priority === 'high')
                                .map(task => ({
                                    ...task,
                                    isOverdue: this.checkIfOverdue(task.dueDate),
                                    created_by_name: task.created_by_name || 'N/A',
                                    claimed_by_name: task.claimed_by_name || 'Unclaimed'
                                }));
    
                            const standardTasks = tasks.filter(task => task.priority === 'standard')
                                .map(task => ({
                                    ...task,
                                    isOverdue: this.checkIfOverdue(task.dueDate),
                                    created_by_name: task.created_by_name || 'N/A',
                                    claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                }));
    
                            this.setState({
                                highPriorityTasks,
                                standardTasks,
                                loading: false,
                            });
                        } else {
                            // For regular users
                            let highPriorityTasks = [];
                            let standardTasks = [];
    
                            // Check if tasks has the expected structure or is a flat array
                            if (Array.isArray(tasks)) {
                                // If tasks is a flat array, filter it
                                const activeTasks = tasks.filter(task => task.status !== 'completed');
                                highPriorityTasks = activeTasks
                                    .filter(task => task.priority === 'high')
                                    .map(task => ({
                                        ...task,
                                        isOverdue: this.checkIfOverdue(task.dueDate),
                                        created_by_name: task.created_by_name || 'N/A',
                                        claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                    }));
                                standardTasks = activeTasks
                                    .filter(task => task.priority === 'standard')
                                    .map(task => ({
                                        ...task,
                                        isOverdue: this.checkIfOverdue(task.dueDate),
                                        created_by_name: task.created_by_name || 'N/A',
                                        claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                    }));
                            } else {
                                // If tasks has separate arrays for high priority and standard
                                const activeTasks = {
                                    highPriorityTasks: tasks.highPriorityTasks?.filter(task => task.status !== 'completed') || [],
                                    standardTasks: tasks.standardTasks?.filter(task => task.status !== 'completed') || []
                                };
    
                                highPriorityTasks = activeTasks.highPriorityTasks.map(task => ({
                                    ...task,
                                    isOverdue: this.checkIfOverdue(task.dueDate),
                                    created_by_name: task.created_by_name || 'N/A',
                                    claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                }));
                                standardTasks = activeTasks.standardTasks.map(task => ({
                                    ...task,
                                    isOverdue: this.checkIfOverdue(task.dueDate),
                                    created_by_name: task.created_by_name || 'N/A',
                                    claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                }));
                            }
    
                            this.setState({
                                highPriorityTasks,
                                standardTasks,
                                loading: false,
                            });
                        }
                    } else {
                        console.warn("No tasks found in response");
                        this.setState({
                            highPriorityTasks: [],
                            standardTasks: [],
                            loading: false,
                        });
                    }
                } catch (error) {
                    console.error("Error processing tasks:", error);
                    this.setState({
                        highPriorityTasks: [],
                        standardTasks: [],
                        loading: false,
                    });
                }
            },
            (err) => {
                console.error("API Error", err);
                swal("Error", "Failed to fetch tasks. Please try again.", "error");
                this.setState({ loading: false });
            }
        );
    };
    
    
    checkIfOverdue = (due_date) => {
        if (!due_date) return false;
        // Reset time to start of day for accurate comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const taskDueDate = new Date(due_date);
        taskDueDate.setHours(0, 0, 0, 0);
        
        return taskDueDate < today;
    };

    renderDueDate = (task) => {
        console.log("Task Due Date", task.due_date);
        if (!task.due_date) return '---';
        
        try {
            const formattedDate = new Date(task.due_date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });

            const isOverdue = this.checkIfOverdue(task.due_date);
            
            const style = {
                display: 'inline-block',
                padding: '6px 12px',
                borderRadius: '4px',
                fontWeight: isOverdue ? 'bold' : 'normal',
                color: isOverdue ? '#fff' : '#000',
                backgroundColor: isOverdue ? '#dc3545' : '#e2e3e5', // Red for overdue, light gray for normal
                border: isOverdue ? '1px solid #dc3545' : '1px solid #d6d8db'
            };

            return (
                <span style={style}>
                    {formattedDate}
                </span>
            );
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid date';
        }
    };

    handleModalOpen = (type, task = null) => {
        this.setState({
            showTaskModal: true,
            modalType: type,
            selectedTask: task
        });
    };

    handleModalClose = () => {
        this.setState({
            showTaskModal: false,
            modalType: null,
            selectedTask: null
        });
    };

    assignTask = (taskData) => {
        if (!this.state.isManager) return;
        const data = {
            ...taskData,
            created_by: this.state.user.id,
            claimed_by: null // Initialize as unclaimed
        };
        let url = 'createTask';
        post_ApiManager(data, url, (res) => {
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task assigned successfully", "success");
                this.handleModalClose();
            } else {
                swal("Error", "Failed to assign task", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to assign task", "error");
        });
    };

    reassignTask = (taskData) => {
        if (!this.state.isManager) return;

        const { taskId } = taskData;
        const data = {
            ...taskData,
            claimed_by: taskData.assigned_to // Update claimed_by when reassigning
        };
        let url = `reassignTask/${taskId}`;

        post_ApiManager(data, url, (res) => {
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task assigned successfully", "success");
                this.handleModalClose();
            } else {
                swal("Error", "Failed to assign task", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to assign task", "error");
        });
    };

    fetchAllUsers = async () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0, (res) => {
            console.log('Get all Users', res);
            this.setState({ usersList: res.data.data || [] });
        }, (err) => {
            console.log(err);
        });
    }

    handleStatusChange = async (taskId, status) => {
        if (this.state.processingActions.has(`status-${taskId}`)) return;

        this.setState(prevState => ({
            processingActions: new Set(prevState.processingActions).add(`status-${taskId}`)
        }));

        const data = { status: status };
        let url = `updateTaskStatus/${taskId}/${status}`;

        try {
            post_ApiManager(data, url, (res) => {
                        if (res.data.status) {
                            this.fetchTasks();
                            swal("Success", "Task status updated successfully", "success");
                        } else {
                            swal("Error", "Failed to update task status", "error");
                        }
                    }, (err) => {
                        console.log(err);
                        swal("Error", "Failed to update task status", "error");
                    });
                } 
                finally {
                this.setState(prevState => {
                const newProcessingActions = new Set(prevState.processingActions);
                newProcessingActions.delete(`status-${taskId}`);
                return { processingActions: newProcessingActions };
            });
        }
    }

    deleteTask =  (taskId) => {
        if (!this.state.isManager || this.state.processingActions.has(`delete-${taskId}`)) return;

        this.setState(prevState => ({
            processingActions: new Set(prevState.processingActions).add(`delete-${taskId}`)
        }));

        const data = { task_id: taskId };
        let url = `deleteTask/${taskId}`;

        try {
            post_ApiManager(data, url, (res) => {
                        if (res.data.status) {
                            this.fetchTasks();
                            swal("Success", "Task deleted successfully", "success");
                        } else {
                            swal("Error", "Failed to delete task", "error");
                        }
                    }, (err) => {
                        console.log(err);
                        swal("Error", "Failed to delete task", "error");
                    });
            } 
            finally {
            this.setState(prevState => {
                const newProcessingActions = new Set(prevState.processingActions);
                newProcessingActions.delete(`delete-${taskId}`);
                return { processingActions: newProcessingActions };
            });
        }
    }
    
    renderTaskCard = (task) => {
        const { isManager, user, processingActions } = this.state;
        const isTaskClaimed = task.claimed_by === user.id || task.assigned_to === user.id;
        const isClaimedByOther = task.assigned_to !== null && task.assigned_to !== user.id;
        
        const statusColors = {
            pending: 'bg-yellow-500',
            in_progress: 'bg-blue-500',
            completed: 'bg-green-500'
        };

        // Determine border color based on application type
        const getBorderColor = () => {
            if (task.application_type?.toLowerCase().includes('migration')) {
                return 'border-green-200';
            }
            if (task.application_type?.toLowerCase().includes('education')) {
                return 'border-blue-200';
            }
            return 'border-gray-200';
        };

        return (
            <div className="tailwind-container">
                <div className="w-full md:w-1/3 lg:w-1/4 p-3">
                <div
                            className={`bg-white rounded-lg shadow-md h-full flex flex-col border-2 ${
                                task.application_type?.toLowerCase() === 'migration'
                                    ? 'border-green-400'
                                    : task.application_type?.toLowerCase() === 'education'
                                    ? 'border-blue-400'
                                    : 'border-gray-400'
                            }`}
                        >
                        {/* Header */}
                        <div className="p-3 border-b flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <h3 className="text-base font-semibold text-gray-800 truncate">
                                    <a
                                        href={`/clients/view-client-detail/${task.client_id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:underline"
                                    >
                                        {task.client_name}
                                    </a>
                                </h3>
                            </div>

                            <span className={`px-2 py-1 rounded-full text-xs font-medium text-white ${statusColors[task.status] || 'bg-gray-500'}`}>
                                {task.status?.replace('_', ' ').toUpperCase()}
                            </span>
                        </div>

                        {/* Content */}
                        <div className="p-3 flex-grow">
                            <div className="grid grid-cols-2 gap-2 text-sm mb-2">
                                <div>
                                    <span className="text-gray-500 text-xs">Application Type:</span>
                                    <p className="text-gray-800"> {(task.application_type || "-").toUpperCase()}</p>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-500 text-xs"> </span>
                                    <p>
                                        {this.renderDueDate({
                                            due_date: task.due_date,
                                            isOverdue: task.isOverdue
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className="mb-2">
                                <span className="text-gray-500 text-xs">Application:</span>
                                <p className="text-gray-800 text-sm">
                                    {task.application_type === 'migration' ? (
                                        <a
                                            href={`/applications/view-migration-application-detail/${task.migration_app_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {task.migration_course_name || "-"}
                                        </a>
                                    ) : task.application_type === 'education' ? (
                                        <a
                                            href={`/applications/view-education-application-detail/${task.education_app_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {task.education_course_name || "-"}
                                        </a>
                                    ) : (
                                        <a
                                            href={`/applications/view-migration-application-detail/${task.application_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {task.course_name || "-"}
                                        </a>
                                    )}
                                </p>
                            </div>


                            <div className="mb-2">
                                <span className="text-gray-500 text-xs">Task:</span>
                                <p className="text-gray-800 text-sm line-clamp-2">{task.task}</p>
                            </div>

                            <div className="grid grid-cols-2 gap-2 text-sm mb-3">
                                <div>
                                    <span className="text-gray-500 text-xs">Created By:</span>
                                    <p className="text-gray-800 truncate">{task.created_by_name}</p>
                                </div>
                                <div className="flex justify-end">
                                    <div>
                                        <span className="text-gray-500 text-xs">Assigned To:</span>
                                        <p className="text-gray-800 truncate text-right">{task.assigned_to_name || '-'}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Actions */}
                            <div className="space-y-2">
                            {isManager ? (
                                <select 
                                    value={task.status} 
                                    onChange={(e) => this.handleStatusChange(task.id, e.target.value)}
                                    disabled={processingActions.has(`status-${task.id}`)}
                                    className={`w-full p-1.5 text-sm border rounded ${
                                        processingActions.has(`status-${task.id}`) 
                                            ? 'bg-gray-200 cursor-not-allowed' 
                                            : 'bg-gray-50 focus:ring-2 focus:ring-blue-500'
                                    }`}
                                >
                                    <option value="pending">Pending</option>
                                    <option value="in_progress">In Progress</option>
                                    <option value="completed">Completed</option>
                                </select>
                            )  : (
                                isTaskClaimed ? (
                                    <select 
                                        value={task.status} 
                                        onChange={(e) => this.handleStatusChange(task.id, e.target.value)}
                                        disabled={processingActions.has(`status-${task.id}`)}
                                        className={`w-full p-1.5 text-sm border rounded ${
                                            processingActions.has(`status-${task.id}`) 
                                                ? 'bg-gray-200 cursor-not-allowed' 
                                                : 'bg-gray-50 focus:ring-2 focus:ring-blue-500'
                                        }`}
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="in_progress">In Progress</option>
                                        <option value="completed">Completed</option>
                                    </select>
                                ) : (
                                    <button
                                        onClick={() => this.claimTask(task.id)}
                                        disabled={isClaimedByOther || processingActions.has(`claim-${task.id}`)}
                                        className={`w-full text-white text-sm py-1.5 px-3 rounded ${
                                            isClaimedByOther 
                                                ? 'bg-gray-400 cursor-not-allowed'
                                                : processingActions.has(`claim-${task.id}`)
                                                ? 'bg-green-300 cursor-not-allowed'
                                                : 'bg-green-500 hover:bg-green-600'
                                        }`}
                                    >
                                        {isClaimedByOther ? 'CLAIMED' : processingActions.has(`claim-${task.id}`) ? 'Processing...' : 'Claim Task'}
                                    </button>
                                )
                            )}

                            {isManager && (
                                <div className="flex gap-2">
                                    <button
                                        onClick={() => this.handleModalOpen('reassign', task)}
                                        disabled={processingActions.has(`reassign-${task.id}`)}
                                        className={`flex-1 text-white text-sm py-1.5 px-3 rounded ${
                                            processingActions.has(`reassign-${task.id}`)
                                                ? 'bg-yellow-300 cursor-not-allowed'
                                                : 'bg-yellow-500 hover:bg-yellow-600'
                                        }`}
                                    >
                                        Reassign
                                    </button>
                                    <button 
                                        onClick={() => this.deleteTask(task.id)}
                                        disabled={processingActions.has(`delete-${task.id}`)}
                                        className={`flex-1 text-white text-sm py-1.5 px-3 rounded ${
                                            processingActions.has(`delete-${task.id}`)
                                                ? 'bg-red-300 cursor-not-allowed'
                                                : 'bg-red-500 hover:bg-red-600'
                                        }`}
                                    >
                                        {processingActions.has(`delete-${task.id}`) ? 'Deleting...' : 'Delete'}
                                    </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderTaskSection = (tasks, title) => {
        return (
            <div className="tailwind-container">
                <div className="mb-6">
                    <h2 className="text-center py-2 mb-3 text-lg font-semibold bg-yellow-100 rounded">
                        {title}
                    </h2>
                    {/* <div className="flex flex-wrap -mx-3"> */}
                    <div className="flex flex-wrap -mx-3 justify-start">
                        {tasks.map(task => this.renderTaskCard(task))}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { highPriorityTasks, standardTasks, loading, isManager, showTaskModal, selectedTask, modalType } = this.state;
    
        if (loading) return <div><Loader /></div>;
    
        return (
            <div className="tailwind-container">
                <div className="min-h-screen bg-gray-50">
                    <Title 
                        title={isManager ? "All Tasks" : "My Tasks"}
                        btnCol="col-lg-8 text-center pt-lg-1" 
                        btn={true}
                        btnText="View History" 
                        btnClass="float-right btn btn-primary"
                        btnUrl={`/processing-reports/${this.state.user['id']}`}
                    />
                    
                    <div className="container mx-auto px-4 py-6">
                        <h2 className="text-center text-xl font-bold text-blue-600 mb-6">
                            {moment().format('MMMM Do YYYY')}
                        </h2>
        
                        {this.renderTaskSection(highPriorityTasks, 'High Priority Tasks')}
                        {this.renderTaskSection(standardTasks, 'Standard Priority Tasks')}
        
                        {isManager && (
                            <TaskModal
                                show={showTaskModal}
                                onClose={this.handleModalClose}
                                onSubmit={modalType === 'create' ? this.assignTask : this.reassignTask}
                                currentUserId={this.state.user.id}
                                modalType={modalType}
                                selectedTask={selectedTask}
                                usersList={this.state.usersList}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProcessingTasksCards;