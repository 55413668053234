
import React, { Suspense } from 'react';
import Title from '../GenericComponents/Title';
import "./dashboard.css";
import "../../styles/helper.css";
import { Link } from 'react-router-dom';
import { get_ApiManager} from '../ApiManager/ApiManager';
import { FaAngleDown, FaAngleUp, FaBookReader, FaExchangeAlt, FaJediOrder, FaModx, FaPeopleArrows,
   FaPhoenixFramework, FaPiedPiperHat, FaPlaneDeparture, FaSchlix, FaStar, FaVaadin, FaYelp } from 'react-icons/fa';
import { FaClipboardList } from 'react-icons/fa';
const AppStatusDashboard = React.lazy(() => import('./appStatusDashboard'));


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ausCount:'',
      pakCount:'',
      canadaCount:'',
      ukCount:'',
      userDetail: [],
      totalCount:[],
      userInfo: [],
      eduInfo: [],
      migInfo: [],
      getrfi:'',
      getnoicc:'',
      gettutionCompleted:'',
      getnj:'',
      geteoi:'',
      getroi:'',
      gethealth:'',
      gethealthCompleted:'',
      token:'',
      gettution:'',
      getmigpendinghealth:'',
      getmigcompletedhealth:'',
      getmigpendingbiometric:'',
      getmigcompletedbiometric:'',
      showMore:false,
      allPermissions: JSON.parse(localStorage.getItem('permissions')) || [],
      user: JSON.parse(localStorage.getItem('user_id')) || [],
      branchList:[],
      selectedBranches:[],
      selectedBranch:'null',
      isNew: false,
     }
     this.handleBranchCallback = this.handleBranchCallback.bind(this);
  }
  componentDidMount() {
    this.getTotalApp();
  }
  
    async getTotalApp() {
      const data = '';
      let url = `getTotal`
      console.log(url)
      get_ApiManager(data, url,(res) => {
        console.log('totalApp',res);
        this.setState({totalCount:res.data.data});
        this.getStatusTotal();
      },(err) => {
        throw err;
      }); 
    }   
    async getStatusTotal(){
        const data = '';
      let url = 'showAppTotal'
      get_ApiManager(data, url,(res) => {
        console.log('appStatus',res);
        this.setState({
          getrfi:res.data.data['rfi'],
          getnoicc:res.data.data['noicc'],
          getnj:res.data.data['nj'],
          geteoi:res.data.data['eoi-invite-recieve'],
          getroi:res.data.data['roi-invite-recieve'],
          gethealth:res.data.data['health-refund'],
          gettution:res.data.data['tution-fee'],
          gettutionCompleted:res.data.data['tution-completed'],
          gethealthCompleted:res.data.data['health-completed'],
          getmigpendinghealth:res.data.data['mighealthexaminationpending'],
          getmigcompletedhealth:res.data.data['mighealthexaminationcompleted'],
          getmigpendingbiometric:res.data.data['migbiometricspending'],
          getmigcompletedbiometric:res.data.data['migbiometricscompleted'],
        });
      },(err) => {
        throw err;
      }); 
    }

    handleBranchCallback(){
      this.getTotalApp();
    }

    toggleShowMore = () => {
      this.setState((prevState) => ({ showMore: !prevState.showMore }));
    };
     // Render Data
    renderData(text, pending, completed, path1, path2) {
      return(
        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch mb-2 mb-lg-0 ">
        <div className="card dashboard-card w-100 h-100">
          <div className='upper h-100'>
            <div className="card-block h-100">
            <Link className="no-underline" target="_blank" to={path1}>
              <div className="row text-center pb-lg-5 mb-lg-2">
                <div className="col-10">
                  <h4 className="text-c-green f-w-600">{pending}</h4>
                    <h6 className="text-muted">{text}</h6>
                    </div>
                    <div className="col-2 text-right pt-2">
                      <FaYelp size={26} className='dash-icon' />
                      <br/>
                      <>
                      <small className="size-11 bold no-show" style={{color:'white'}} >a</small><br/>
                      <div className="size-11 bold no-show" style={{color:'white'}} >s</div></>
                    </div>
                 </div>
               </Link>
             </div>
            </div>
            <div className="card-footer footer bg-green">
            <Link className="light-blue" target="_blank" to={path2}>
                <div className="d-flex flex-row j-content-center">
                    <div className="total">Completed ({completed})</div>
                </div>
                </Link> 
            </div>
        </div>
     </div>
      )
    }

    Data(text1, text1Data, path1, text2, text2Data, path2, permission1, permission2, rightTextAllowed, activeData, inactiveData, font, newPromo) {
      return(
        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch mb-2 mb-lg-0">
        <div className="card h-100 w-100 dashboard-card ">
        {(newPromo && this.state.isNew === true) &&
          <div class="card-new-badge">NEW</div>}
          <div className='upper h-100'>
            <div className="card-block h-100">
            {permission1 === 1 ?
            <Link className="no-underline" target="_blank" to={path1}>
              <div className="row text-center pb-lg-5 mb-lg-2">
                <div className="col-8">
                  <h4 className="text-c-green f-w-600">
                    {text1Data}</h4>
                    <h6 className="text-muted">{text1}</h6>
                    </div>
                    <div className="col-4 text-right pt-2">
                    {font}<br/>
                      {rightTextAllowed ?
                      <>
                      <small className="size-11 bold" style={{color:'cornflowerblue'}} >Active ({activeData})</small><br/>
                      <div className="size-11 bold" style={{color:'#e66d17'}} >Inactive ({inactiveData})</div>
                      </>: 
                      <>
                      <small className="size-11 bold no-show" style={{color:'white'}} >a</small><br/>
                      <div className="size-11 bold no-show" style={{color:'white'}} >s</div></>}
                    </div>
                 </div>
               </Link>:
               <div className='no-underline'>
                <div className="row text-center pb-5 mb-2">
                    <div className="col-9">
                        <h4 className="text-c-green f-w-600">{text1Data}</h4>
                        <h6 className="text-muted">{text1}</h6>
                    </div>
                    <div className="col-3 text-right pt-2">
                    {font}
                    <>
                    <br/>
                      <small className="size-11 bold no-show" style={{color:'white'}} >a</small><br/>
                      <div className="size-11 bold no-show" style={{color:'white'}} >s</div></>
                    </div>
                </div>
                </div>}
             </div>
            </div>
            <div className="card-footer footer bg-green">
            {permission2 === 1 ?
            <Link className="light-blue" target="_blank" to={path2}>
                <div className="d-flex flex-row j-content-center">
                    <div className="total">{text2}</div>
                    <b className="total-count white pl-2 pl-lg-0">
                    {text2Data}</b>
                </div>
                </Link> :
                <div className="d-flex flex-row j-content-center">
                    <div className="total">{text2}</div>
                    <b className="total-count white pl-2 pl-lg-0">
                    {text2Data}</b>
                </div> 
                }
            </div>
        </div>
    </div>
      )
    }

 render(){
   const { allPermissions, showMore } = this.state;
   return ( 
    <div className='pl-2'>
    <Title title="Dashboard" showBranches={true}  branchInnerClass={'col-11'} titleCol={'col-lg-5 pt-1'} 
    showTask={true} showMeeting={true} meetingClass={'col-lg-2 pt-1 ps-2'}  taskClass={'col-lg-2 pt-1'} branchClass={'col-lg-3 pt-1'} selectedValues={this.state.selectedBranches}
    parentCallback={this.handleBranchCallback} 
    />
    <div className="pt-1 pl-1 pr-lg-1 pr-2 mb-5">
    <div className='row justify-content-end px-2'>
    <Link className="no-underline col-2" target="_blank" to={`/employee-attendance/${this.state.user}`}>
    <button className='btn btn-primary mb-2'>Attendance Details</button></Link>
    </div>
    <div id='dashboard' className="row">
    {/* ProcessingTasks */}
    {
        this.Data(
          'Processing Tasks',
          allPermissions['manage_tasks'] ? this.state.totalCount['totalTasks'] : this.state.totalCount['myTasks'], 
          allPermissions['manage_tasks'] ? '/tasks/my-tasks' : '/tasks/my-tasks', 
          'Total Tasks',
          this.state.totalCount['totalTasks'],
          '/tasks/my-tasks',
          1,
          allPermissions['manage_tasks'],  // This controls the actions visibility
          true,
          this.state.totalCount['myPendingTasks'],
          this.state.totalCount['myCompletedTasks'],
          <FaClipboardList size={26} className='dash-icon' />
      )
      
    }
    {/* clients */}
    {this.Data('My Clients', this.state.totalCount['myAssignedClients'], '/clients/my-clients', 
      'Total Client', this.state.totalCount['totalClients'], '/clients/view-clients' ,
      1, allPermissions['clients'], true, this.state.totalCount['myActiveAssignedClients'],
      this.state.totalCount['myInactiveAssignedClients'], 
      <FaPeopleArrows size={26} className='dash-icon' />)}
    {/* Migration Application */}
    {this.Data('My Migration', this.state.totalCount['myMigrations'], `/view-all/${'mymig'}`, 
      'Total Migration', this.state.totalCount['totalMigrations'], `/view-all/${'mig'}` ,
      1, allPermissions['desk_migration'], true, this.state.totalCount['myActiveMigrations'], this.state.totalCount['myInactiveMigrations'],   <FaPlaneDeparture size={26} className='dash-icon' />)}
    {/* Education Application */}
    {this.Data('My Education', this.state.totalCount['myEducations'], `/view-all/${'myedu'}`, 
      'Total Education', this.state.totalCount['totalEducations'], `/view-all/${'edu'}` ,
      1, allPermissions['desk_education'], true, this.state.totalCount['myActiveEducations'], this.state.totalCount['myInactiveEducations'],   <FaBookReader size={26} className='dash-icon' />)}
    {/* Appointments  */}
    {this.Data('My Appointments', this.state.totalCount['myAppointments'], '/appointments/view-my-appointments', 
      'Total Appointments', this.state.totalCount['totalAppointments'], `/appointments/view-appointments` ,
      allPermissions['appointments'], allPermissions['viewappointments'], false, '', '', <FaPhoenixFramework className='dash-icon' size={26} />)}
    {/* External Clients  */}
    {this.Data('My External Clients', this.state.totalCount['myExternal'], `/external-clients/${'my'}`, 
      'Total External', this.state.totalCount['totalExternal'], `/external-clients/${'total'}` ,
      1, allPermissions['external_clients'], false, '', '', <FaExchangeAlt className='dash-icon' size={26} />, 'new')}
   
    {/* follow-ups */}
    {this.Data('My Follow-Ups', this.state.totalCount['myFollowUps'], `/view-all-followups/${'my'}`, 
       'Total Follow-Ups', this.state.totalCount['totalFollowUps'], `/view-all-followups/${'all'}` ,
       1, allPermissions['all_followups'], false, '', '', <FaModx className='dash-icon' size={26} />)}
    {/* Priority */}
      {this.Data('My Priority', this.state.totalCount['myPriority'], `/view-priority/${'my'}`, 
      'Total Priority', this.state.totalCount['totalPriority'], `/view-priority/${'all'}` ,
      1, allPermissions['all_followups'], false, '', '', <FaStar className='dash-icon' size={26} />)}
    {allPermissions['desk_rfi'] === 1 &&
      <>
    {this.Data('RFI', this.state.getrfi, `/view-status-detail/${'rfi'}`, 
       'View Details', '', `/view-status-detail/${'rfi'}` ,
       1, 1, false, '', '', <FaJediOrder className='dash-icon' size={26} />)}
    </>}
    {allPermissions['desk_noicc'] === 1 &&
    <>
    {this.Data('NOICC', this.state.getnoicc, `/view-status-detail/${'noicc'}`, 
       'View Details', '', `/view-status-detail/${'noicc'}` ,
       1, 1, false, '', '', <FaPiedPiperHat className='dash-icon' size={26} />)}
    </>}
    {(this.state.showMore === true || this.state.showMore === "true" ) &&
    <>
    {/* Natural Justice */}
    {allPermissions['desk_nj'] === 1 &&
    <>
      {this.Data('NATURAL JUSTICE', this.state.getnj, `/view-status-detail/${'nj'}`, 
       'View Details', '', `/view-status-detail/${'nj'}` ,
       1, 1, false, '', '', <FaSchlix className='dash-icon' size={26} />)}
    </>}
    {/* Health Examination */}
    {(allPermissions['health_examination'] === 1 || allPermissions['health_examination'] === "1" )&&
    <>{this.renderData('Health Examination', this.state.getmigpendinghealth,this.state.getmigcompletedhealth , `/status-followup/${'all'}/${'mighealthexamination'}/${'pending'}`, `/status-followup/${'all'}/${'mighealthexamination'}/${'completed'}`)}</>}
    {(allPermissions['biometrics'] === 1 || allPermissions['biometrics'] === "1") &&
    <>{this.renderData('Biometrics', this.state.getmigpendingbiometric,this.state.getmigcompletedbiometric , `/status-followup/${'all'}/${'migbiometrics'}/${'pending'}`, `/status-followup/${'all'}/${'migbiometrics'}/${'completed'}`)} </>}
    {(allPermissions['desk_eoi'] === 1 || allPermissions['desk_eoi'] === "1") &&
    <>
    {this.Data('EOI RECEIVED INVITATION', this.state.geteoi, `/view-status-detail/${'eoi'}`, 
       'View Details', '', `/view-status-detail/${'eoi'}` ,
       1, 1, false, '', '', <FaVaadin className='dash-icon' size={26} />)}
    </>}
    {(allPermissions['desk_roi'] === 1 || allPermissions['desk_roi'] === "1") &&
    <>
    {this.Data('ROI RECEIVED INVITATION', this.state.geteoi, `/view-status-detail/${'roi'}`, 
       'View Details', '', `/view-status-detail/${'roi'}` ,
       1, 1, false, '', '', <FaYelp className='dash-icon' size={26} />)}
    </>}
    <>
    <>{this.renderData('Health Refund', this.state.gethealth, this.state.gethealthCompleted , `/view-status-detail/${'healthrefund'}`, `/view-status-detail/${'healthrefundcompleted'}`)} </>
    <>{this.renderData('Tution Fee Refund', this.state.gettution, this.state.gettutionCompleted , `/view-status-detail/${'tutionrefund'}`, `/view-status-detail/${'tutionrefundcompleted'}`)} </>
    </>
    </>}
    </div>
  
    <div className="text-center">
      <button onClick={this.toggleShowMore} className="btn btn-info">
        {showMore ? <FaAngleUp className="white" size={25} /> : <FaAngleDown className="white" size={25} />}
      </button>
    </div>

      <Suspense fallback={<div>Loading...</div>}>
        <AppStatusDashboard />
      </Suspense>

    </div>
    </div>
    );  
  }
}
 
export default Dashboard;