// TaskModal.js
import React, { useState, useEffect, useRef } from 'react';

const Calendar = ({ selectedDate, onDateSelect }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);
    
    const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        return { daysInMonth, firstDayOfMonth };
    };

    const { daysInMonth, firstDayOfMonth } = getDaysInMonth(currentMonth);
    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
        days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }
    for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day,12);
        const isSelected = selectedDate && 
            date.getDate() === selectedDate.getDate() &&
            date.getMonth() === selectedDate.getMonth() &&
            date.getFullYear() === selectedDate.getFullYear();
        
        days.push(
            <div
                key={day}
                onClick={() => onDateSelect(date)}
                className={`calendar-day ${isSelected ? 'selected' : ''}`}
            >
                {day}
            </div>
        );
    }

    const handleMonthChange = (e) => {
        const newMonth = parseInt(e.target.value);
        setCurrentMonth(new Date(currentMonth.getFullYear(), newMonth, 1));
    };

    const handleYearChange = (e) => {
        const newYear = parseInt(e.target.value);
        setCurrentMonth(new Date(newYear, currentMonth.getMonth(), 1));
    };

    return (
        <div className="calendar">
            <div className="calendar-header">
                <div className="calendar-selectors">
                    <select 
                        value={currentMonth.getMonth()} 
                        onChange={handleMonthChange}
                        className="calendar-select"
                    >
                        {months.map((month, index) => (
                            <option key={month} value={index}>{month}</option>
                        ))}
                    </select>
                    <select 
                        value={currentMonth.getFullYear()} 
                        onChange={handleYearChange}
                        className="calendar-select"
                    >
                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="calendar-grid">
                <div className="calendar-day">Sun</div>
                <div className="calendar-day">Mon</div>
                <div className="calendar-day">Tue</div>
                <div className="calendar-day">Wed</div>
                <div className="calendar-day">Thu</div>
                <div className="calendar-day">Fri</div>
                <div className="calendar-day">Sat</div>
                {days}
            </div>
        </div>
    );
};

const TaskModal = ({ show, onClose, onSubmit, usersList, modalType, selectedTask, currentUserId }) => {
    const [formState, setFormState] = useState({
        task: '',
        priority: 'standard',
        due_date: null,
        assigned_to: '',
        taskId: '',
    });
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarPosition, setCalendarPosition] = useState('bottom');
    const dateInputRef = useRef(null);

    useEffect(() => {
        if (modalType === 'reassign' && selectedTask) {
            const { task, priority, due_date, assigned_to, id } = selectedTask;
            setFormState({
                task,
                priority: priority || 'standard',
                due_date: due_date ? new Date(due_date) : null,
                assigned_to: assigned_to || '',
                taskId: id,
            });
        } else if (modalType === 'create') {
            setFormState({
                task: '',
                priority: 'standard',
                due_date: null,
                assigned_to: '',
                taskId: '',
            });
        }
    }, [modalType, selectedTask]);

    const handleCalendarToggle = () => {
        if (!showCalendar) {
            const inputRect = dateInputRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const spaceBelow = windowHeight - inputRect.bottom;
            const spaceAbove = inputRect.top;
            const calendarHeight = 150; // Approximate height of calendar

            if (spaceBelow < calendarHeight && spaceAbove > spaceBelow) {
                setCalendarPosition('top');
            } else {
                setCalendarPosition('bottom');
            }
        }
        setShowCalendar(!showCalendar);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateSelect = (date) => {
        setFormState(prev => ({
            ...prev,
            due_date: date
        }));
        setShowCalendar(false);
    };

    const handleSubmit = () => {
        const taskData = {
            task: formState.task,
            priority: formState.priority,
            due_date: formState.due_date,
            assigned_to: formState.assigned_to,
            assigned_by: String(currentUserId)
        };

        if (modalType === 'reassign') {
            taskData.taskId = formState.taskId;
        }

        onSubmit(taskData);
        onClose();
    };

    if (!show) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                width: '90%',
                maxWidth: '500px',
                maxHeight: '90vh',
                overflowY: 'auto'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px'
                }}>
                    <h2 style={{ margin: 0 }}>
                        {modalType === 'create' ? 'Add New Task' : 'Reassign Task'}
                    </h2>
                    <button
                        onClick={onClose}
                        style={{
                            background: 'none',
                            border: 'none',
                            fontSize: '20px',
                            cursor: 'pointer'
                        }}
                    >
                        ×
                    </button>
                </div>

                <div style={{ marginBottom: '15px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>Task</label>
                    <textarea
                        name="task"
                        value={formState.task}
                        onChange={handleChange}
                        rows="4"
                        style={{
                            width: '100%',
                            padding: '8px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            resize: 'vertical',
                            minHeight: '100px',
                            fontFamily: 'inherit'
                        }}
                        placeholder="Please Enter"
                    />
                </div>

                <div style={{ marginBottom: '15px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>Priority</label>
                    <select
                        name="priority"
                        value={formState.priority}
                        onChange={handleChange}
                        style={{
                            width: '100%',
                            padding: '8px',
                            borderRadius: '4px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <option value="high">High</option>
                        <option value="standard">Standard</option>
                    </select>
                </div>

                <div style={{ marginBottom: '15px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>Due Date</label>
                    <div style={{ position: 'relative' }}>
                        <input
                            ref={dateInputRef}
                            type="text"
                            value={formState.due_date ? formState.due_date.toLocaleDateString() : ''}
                            onClick={handleCalendarToggle}
                            readOnly
                            placeholder="Select due date"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                cursor: 'pointer'
                            }}
                        />
                        {showCalendar && (
                            <div style={{
                                position: 'absolute',
                                [calendarPosition === 'top' ? 'bottom' : 'top']: '100%',
                                left: 0,
                                zIndex: 1000,
                                backgroundColor: 'white',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                            }}>
                                <Calendar
                                    selectedDate={formState.due_date}
                                    onDateSelect={handleDateSelect}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ marginBottom: '15px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>Assign To</label>
                    <select
                        name="assigned_to"
                        value={formState.assigned_to}
                        onChange={handleChange}
                        style={{
                            width: '100%',
                            padding: '8px',
                            borderRadius: '4px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <option value="">Select User</option>
                        {usersList?.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    marginTop: '20px'
                }}>
                    <button
                        onClick={onClose}
                        style={{
                            padding: '8px 16px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            backgroundColor: 'white',
                            cursor: 'pointer'
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        style={{
                            padding: '8px 16px',
                            borderRadius: '4px',
                            border: 'none',
                            backgroundColor: '#0066cc',
                            color: 'white',
                            cursor: 'pointer'
                        }}
                    >
                        {modalType === 'create' ? 'Create Task' : 'Reassign Task'}
                    </button>
                </div>
            </div>

            <style>{`
                .calendar {
                    padding: 10px;
                    background: white;
                    min-width: 280px;
                }
                
                .calendar-header {
                    margin-bottom: 10px;
                }
                
                .calendar-selectors {
                    display: flex;
                    gap: 8px;
                    justify-content: center;
                    margin-bottom: 10px;
                }
                
                .calendar-select {
                    padding: 4px 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-size: 14px;
                    cursor: pointer;
                }
                
                .calendar-select:focus {
                    outline: none;
                    border-color: #0066cc;
                }
                
                .calendar-grid {
                    display: grid;
                    grid-template-columns: repeat(7, 1fr);
                    gap: 2px;
                }
                
                .calendar-day {
                    padding: 8px;
                    text-align: center;
                    cursor: pointer;
                }
                
                .calendar-day:hover:not(.empty) {
                    background-color: #f0f0f0;
                }
                
                .calendar-day.selected {
                    background-color: #0066cc;
                    color: white;
                    border-radius: 4px;
                }
                
                .calendar-day.empty {
                    cursor: default;
                }
            `}</style>
        </div>
    );
};

export default TaskModal;