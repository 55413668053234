import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Title from '../GenericComponents/Title';
import { get_ApiManager } from "../ApiManager/ApiManager";
import moment from "moment";

const ViewProcessingReport = () => {
    const { id } = useParams();
    const [details, setDetails] = useState({});
    const [standardTasks, setStandardTasks] = useState([]);
    const [highPriorityTasks, setHighPriorityTasks] = useState([]);

    useEffect(() => {
        getReportDetail();
    }, []);

    const getReportDetail = () => {
        let url = `reportDetail/${id}`;
        console.log(url);
        get_ApiManager('', url, (res) => {
            console.log('detail', res);
            setDetails(res.data.data);
            setStandardTasks(res.data.data['standardTasks']);
            setHighPriorityTasks(res.data.data['highPriorityTasks']);
        }, (err) => {
            console.log(err);
        });
    };

    return (
        <>
            <Title title={`Report`} back={true} />
            <div className="mx-3 mt-2 mb-5">
                <h2 className='text-center pb-2 mb-0' style={{ color: 'blue' }}>
                    {moment(details['created_at']).format('MMMM Do YYYY')}
                </h2>

                <h2 className='row text-center j-content-center p-2 size-22 mb-0' style={{ background: 'burlywood' }}>
                    Standard Tasks
                </h2>
                <table className="table table-bordered bg-white">
                    <thead>
                        <tr>
                            <th className='text-center size-15 w-10'>Sr No.</th>
                            <th className='text-left size-15'>Task</th>
                            <th className='text-center size-15'>Created By</th>
                            <th className='text-center size-15'>Claimed Date</th>
                            <th className='text-center size-15'>Completion Date</th>
                            <th className='text-center size-15'>Due Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {standardTasks?.map((task, index) => (
                            <tr key={index}>
                                <td className='text-center size-16'>{index + 1}</td>
                                <td className='text-left size-16'>{task.task}</td>
                                <td className='text-center size-16'>{task.createdBy}</td>
                                <td className='text-center size-16'>{task.claimedDate}</td>
                                <td className='text-center size-16'>{task.completionDate}</td>
                                <td className='text-center size-16'>{task.dueDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2 className='row text-center j-content-center p-2 size-22 mb-0' style={{ background: 'burlywood' }}>
                    High Priority Tasks
                </h2>
                <table className="table table-bordered bg-white">
                    <thead>
                        <tr>
                            <th className='text-center size-15 w-10'>Sr No.</th>
                            <th className='text-left size-15'>Task</th>
                            <th className='text-center size-15'>Created By</th>
                            <th className='text-center size-15'>Claimed Date</th>
                            <th className='text-center size-15'>Completion Date</th>
                            <th className='text-center size-15'>Due Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {highPriorityTasks?.map((task, index) => (
                            <tr key={index}>
                                <td className='text-center size-16'>{index + 1}</td>
                                <td className='text-left size-16'>{task.task}</td>
                                <td className='text-center size-16'>{task.createdBy}</td>
                                <td className='text-center size-16'>{task.claimedDate}</td>
                                <td className='text-center size-16'>{task.completionDate}</td>
                                <td className='text-center size-16'>{task.dueDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ViewProcessingReport;
