
import React, { Component } from 'react';
import Title from '../GenericComponents/Title'
import Paginations from '../GenericComponents/Pagination'
import { Link } from 'react-router-dom'
import smallSpinner from '../../assets/small.gif';
import moment from 'moment'
import { get_ApiManager, post_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import swal from 'sweetalert';
import { main_url } from '../../utilis/Common';

class ApplyLeave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeError: null, toError: null, fromError: null, reasonError: null, durationError: null, inError: null, outError: null,
            loading: false,
            type:'',
            to:'',
            from:'',
            duration:'',
            reason:'',
            selectedFile:'',
            currentPage: 1,
            totalItem:'',
            lastPage:'',
            perPage: 10,
            perItem:'',
            order: 'ASC',
            in:'',
            out:'',
            allLeaves:[],
            headers: [
                {title: 'Applied On' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'Leave Type' , dataKey: "type",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Start Date', dataKey: "startdate", align: "text-center" ,key:3, checked: true, canChange: true},
                {title: 'End Date', dataKey: "enddate", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Reason' , dataKey: "reason",align: "text-center", key:5, checked: true, canChange: false},
                {title: 'Duration' , dataKey: "duration",align: "text-center", key:6, checked: true, canChange: false},
                {title: 'Status' , dataKey: "status",align: "text-center", key:7, checked: true, canChange: false},
                {title: 'Comments' , dataKey: "comments",align: "text-center", key:8, checked: true, canChange: false},
                {title: '' , dataKey: "",align: "text-center", key:9, checked: true, canChange: false},
            ],
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.getLeavesData();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    // CallBack function handle pagination
    handlePaginationCallback = (i, perPage) =>{
        if(perPage && perPage != ''){
            this.setState({ perPage : perPage, currentPage:1}, () => {  
             this.getLeavesData() }); 
        } else {this.setState({ currentPage : i}, () => { 
            this.getLeavesData()
         }); }
    }
     // Get Leaves Data
    async getLeavesData (){
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = 'leaves';
        view_ApiManager(url, currentPage, perPage,(res) => {
        console.log('leaves',res);
        this.setState({
          allLeaves: res.data.data['data'],
          totalItem: res.data.data['total'],
          perPage: res.data.data['per_page'],
          lastPage: res.data.data['last_page'],
          perItem: res.data.data['data'].length,
        });
    },(err) => {
        console.log(err);
    });

    }
     // Validation
    handleValidation = () => {
        this.setState({typeError: null, toError: null, fromError: null, durationError: null, reasonError: null, inError:null, outError:null });

        if(!this.state.type){
            this.setState({typeError : 'Please enter application type'})
            return false;
        } else if(!this.state.duration){
            this.setState({durationError : 'Please select duration'})
            return false;
        } else if(this.state.duration === 'half' && !this.state.in){
            this.setState({inError : 'Please select in time'})
            return false;
        } else if(this.state.duration === 'half' && !this.state.out){
            this.setState({outError : 'Please select out time'})
            return false;
        } else if(this.state.duration === 'half' && !this.state.to){
            this.setState({toError : 'Please select date'})
            return false;
        } else if(this.state.duration === 'full' && !this.state.to){
            this.setState({toError : 'Please select to date'})
            return false;
        }else if(this.state.duration === 'full' && !this.state.from){
            this.setState({fromError : 'Please select from date'})
            return false;
        } else if(!this.state.reason){
                this.setState({reasonError : 'Please select reason'})
                return false;
        } else{
            return true;
        }
    }
     // Apply Education
    async apply()  {
        if (this.handleValidation()) {
            this.setState({loading: true});
            const data = new FormData();
            data.append('document',  this.state.selectedFile);
            data.append('type',  this.state.type);
            data.append('to',  this.state.to);
            data.append('from',  this.state.from);
            data.append('in',  this.state.in);
            data.append('out',  this.state.out);
            data.append('duration',  this.state.duration);
            data.append('reason',  this.state.reason);
            let url = 'leaves';
            post_ApiManager(data, url,(res) => {
                console.log('leave-added',res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {  
                    swal("Success!", res.data.message, "success");
                    this.getLeavesData();
                    this.setState({loading: false});
                }
            },
            (err) => {
              console.error(err);
              swal("Error", err.response?.data?.message || err.message, "error");
            },
            { 'Content-Type': 'multipart/form-data' } // Custom header for multipart/form-data
          );
        }   
    }
     // Upload files
    fileSelectedHandler = event => {
        this.setState({selectedFile:event.target.files[0]});
    }
    async cancelRequest (id) {
        swal({
            title: "Are you sure?",
            text: "You want to cancel this leave application",icon: "warning", buttons: true, dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                let url = 'cancelRequest';
                get_ApiManager(id, url,(res) => {
                    console.log('request canceled',res);
                    swal("Application has been cancelled successfully", {icon: "success" });
                    this.getLeavesData();
                },(err) => {
                    console.log(err);
                }); 
            }   
       });
    }
  
    render(){
        return(
            <>
             <Title title="Leave-Application" />
             <div className="row w-100 pl-lg-3 pr-lg-3 mb-5">
             <div className="p-2">
             {/* <h5 className="bold size-18 pl-2 size-sm-14 sora-font">Leavle Aplication  <FontAwesomeIcon className="pl-1 pr-1" icon={faPlay} /><span className="underline">dd</span> </h5> */}
             <div className="mx-lg-5 mx-3">
             <div className="row edit-color mx-lg-5 py-lg-2 mb-lg-3 px-lg-4 border rounded">
                {/* Leave Type */}
             <div className="d-flex j-content-center mt-3">
             <label className="acc-label2 mb-1 size-17 mr-1 mt-lg-1">Leave Type </label>
             <select className="form-select app-form col-lg-4 col-7" name='type' onChange={this.handleChange}>
             <option value="">Please Select</option>
             <option value='casual'>Casual</option>
             <option value='sick'>Sick</option> 
             <option value='annual'>Annual</option>  
             <option value='unpaid'>Unpaid</option>  
             <option value='workfromhome'>Work from Home</option>        
             </select>
             {this.state.typeError && <><small className="error">{this.state.typeError}</small></>}
             </div>
                {/* Half/Full */}
             <div className="d-flex j-content-center mt-2">
             <div className='col-lg-2 col-5'>
             <input type="radio" className="form-group pl-0" id="radio1" name="duration" value="half" checked={this.state.duration === 'half' ? true : false}
             onChange={(e) => this.setState({duration: 'half'})} />
             <label className="acc-label2 size-15 size-sm-12 ml-1">Half Day</label>
             </div>
             <div className='col-lg-2 col-5'>
             <input type="radio" className="form-group pl-0" id="radio1" name="duration" value="full" checked={this.state.duration === 'full' ? true : false}
              onChange={() => this.setState({duration: 'full'})}  />
             <label className="acc-label2 size-15 size-sm-12 ml-1">Full Day</label>
             </div>
             {this.state.durationError && <><small className="error col-3">{this.state.durationError}</small></>}
             </div>
                {/* TO/From */}
            {this.state.duration === 'half' ? 
            <>
            <div className="d-flex j-content-center mt-3">
            <label className="acc-label2 mb-1 size-18 mr-1 mt-lg-1">From: </label>
            <input className="form-control acc-form col-3 pl-2" type="time" onChange={this.handleChange} name='in' min="09:00" max="18:00" required />
            <br/>
            {this.state.inError && <><small className="error">{this.state.inError}</small></>}
            <label className="acc-label2 mb-1 size-18 mr-1 mt-lg-1">To: </label>
            <input className="form-control acc-form col-3 pl-2" type="time" onChange={this.handleChange} name='out' min="09:00" max="18:00" required />
            {this.state.outError && <><small className="error">{this.state.outError}</small></>}
            <label className="acc-label2 mb-1 size-18 mx-3 mt-lg-1">On</label>
            <input className="form-control acc-form col-3 pl-2" type="date" name='to' onChange={this.handleChange} required />
            {this.state.toError && <><small className="error">{this.state.toError}</small></>}
            </div>
            </>
            :
             <div className="d-flex j-content-center mt-2">
             <label className="acc-label2 mb-1 size-18 size-sm-12 mr-1 mt-lg-1">From </label>
             <input className="form-control acc-form col-lg-3 col-4" type="date" onChange={this.handleChange} name="to" />
             {this.state.toError && <><small className="error">{this.state.toError}</small></>}
             <label className="acc-label2 mb-1 size-sm-12 size-18 ml-lg-3 mr-lg-1 mt-lg-1">To</label>
             <input className="form-control acc-form col-lg-3 col-4" type="date" min={this.state.to} onChange={this.handleChange} name="from" />
             {this.state.fromError && <><small className="error">{this.state.fromError}</small></>}
             </div>}
               {/* Notes */}
             <div className="d-flex j-content-center mt-3 mb-3">
             <label className="acc-label2 mb-1 size-18 size-sm-12 mr-1 mt-lg-1">Reason for Leave </label>
             <textarea className="textarea form-control acc-form col-lg-7" name="reason" placeholder="Please Enter" onChange={this.handleChange} rows="3" cols="50" required></textarea>
             {this.state.reasonError && <><small className="error">{this.state.reasonError}</small></>}
             </div>
             {/* Attachment */}
             <div className="d-flex j-content-center mb-4">
             <label for="formFile" className="acc-label2 mb-1 size-18 size-sm-12 mr-1 mt-lg-1" >Attachment</label>
             <input className="form-control col-lg-5 app-form" onChange={this.fileSelectedHandler} type="file" id="formFile" />
             </div>
             {this.state.loading ?
             <div className='col-12 scoll j-content-center text-center'>
             <img src={smallSpinner} width="15" className="pt-2" alt="loading" />
             </div>:
             <div className='row'>
             <button className='btn btn-success' onClick={() => this.apply()}>Apply Leave</button>
             </div>}

             </div>
             </div>
             </div>

             <div className="table-responsive mx-2 mx-lg-0 mb-2">
             <table className="table table-bordered bg-white mb-0">
             {/* Headers */}
             <thead className="bg-primary white">
             <tr>
             {this.state.headers.map(head => (
             head.checked &&
             <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th>  ))}
             </tr>
             </thead>
             {/* End Headers */}
             <tbody>
             {this.state.allLeaves.map(value => (
                <tr key={value.id}>
                <td className='text-center'>{moment(value.applied_on).format('D MMM YYYY')}</td>
                <td className='text-center'>{value.type}</td>
                <td className='text-center'>{value.startdate}</td>
                <td className='text-center'>{value.enddate}</td>
                <td className='text-center'>{value.reason}<br/>
                {value.document ?
                <a href={main_url()+`${value.document}`} target="_blank"> View File </a>:''}</td>
                <td className='text-center'>{value.duration} day  
                <br/>{value.duration === 'half' ? <>[{value.time_in} to {value.time_out}]</>: ''}</td>
                <td className={`text-center bold ${value.status === 'Pending' 
                ? 'text-yellow' : value.status === 'Cancelled' ||  value.status === 'Rejected' ? 'error' : 'text-c-green'}`}>{value.status}</td> 
                 <td className='text-center'>{value.remarks}</td>
                 <td className='text-center w-10'>
                 {value.status === 'Pending' ? 
                 <button className='btn btn-danger' onClick={() => this.cancelRequest(value.id)} >Cancel Request</button>:''}</td>
                </tr>
              )
             )}
             </tbody>
             </table>
             </div>
             <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              /> 
             </div>
            </>
        )
    }
}
export default ApplyLeave;