// === Google Apps Script (Code.gs) ===
// === React Component (TestForm.jsx) ===
import React, { useState } from 'react';

const TestForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        city: '',
        recent_qualification: '',
        eventsd: '',
    });
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    // Add handleChange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log('Field updated:', name, value); // Debug log
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setResponseMessage('');
        
        try {
            const scriptURL = 'https://script.google.com/macros/s/AKfycbwwGZeZFQGxvSo2xkniWfmzbj_n3h6lAJOzF_BaQ3D-zrB40aLEWZ3zkJ11LG26mzc/exec';
            
            // Debug log in console
            console.log('Submitting form data:', formData);
            
            // Fix: Use the correct formData variable here
            const params = new URLSearchParams();
            params.append('data', JSON.stringify(formData));
            
            console.log('Sending request to:', scriptURL);
            console.log('With body:', params.toString());
            
            const response = await fetch(scriptURL, {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params.toString()
            });
            
            console.log('Response received:', response);

            setResponseMessage('Form submitted successfully!');
            setFormData({
                name: '',
                phone: '',
                email: '',
                city: '',
                recent_qualification: '',
                eventsd: '',
            });
            
        } catch (error) {
            console.error('Detailed error:', error);
            setResponseMessage('An error occurred. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-6">Form Submission</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                {Object.entries(formData).map(([key, value]) => (
                    <div key={key} className="flex flex-col">
                        <label htmlFor={key} className="mb-1 font-medium">
                            {key.split('_').map(word => 
                                word.charAt(0).toUpperCase() + word.slice(1)
                            ).join(' ')}:
                        </label>
                        <input
                            type={key === 'email' ? 'email' : 'text'}
                            id={key}
                            name={key}
                            value={value}
                            onChange={handleChange}
                            className="px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                ))}

                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
                >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
            </form>

            {responseMessage && (
                <div className={`mt-4 p-3 rounded-md ${
                    responseMessage.includes('error') 
                        ? 'bg-red-100 text-red-700'
                        : 'bg-green-100 text-green-700'
                }`}>
                    {responseMessage}
                </div>
            )}
        </div>
    );
};

export default TestForm;