
import React, { Component,Suspense} from 'react';
import Title from '../GenericComponents/Title';
import swal from 'sweetalert';
import { post_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import { Link } from 'react-router-dom';
import './clients.css';
import { activate_status, active_status, genders } from '../../utilis/constants';
import { FaRegCheckSquare } from 'react-icons/fa';
import { format } from 'date-fns';
const MultipleSearchs = React.memo(React.lazy(() => import('../GenericComponents/MultipleSearchs')));
const Modals = React.memo(React.lazy(() => import('../GenericComponents/Modal')));
const Paginations = React.memo(React.lazy(() => import('../GenericComponents/Pagination')));
const ExportOptions = React.memo(React.lazy(() => import('../GenericComponents/ExportOptions')));
const Sorting = React.memo(React.lazy(() => import('../GenericComponents/SortTables')));


class MyClients extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.ref = React.createRef();
        this.userDetail = JSON.parse(localStorage.getItem('user'));
        this.userRole = JSON.parse(localStorage.getItem('user_role'));
        this.state = {
            name:'', email:'', phone:'', country:'', gender:'',
            totalItem:'',
            currentPage: 1,
            lastPage:'',
            perPage: 10,
            myClients:[],
            changedID:'',
            order:'ASC',
            perItem:'',
            search:'',
            selectedClients:[],
            activeStatus:1,
            bulk: false,
            bulkIds:[],
            headers: [
                {title: '#' , dataKey: "index", align: "text-center", key: 1, checked: true, canChange: true},
                {title: 'Name' , dataKey: "name", align: "text-center", key: 2, checked: true, canChange: false},
                {title: 'Email' , dataKey: "email", align: "text-center", key: 3, checked: true, canChange: false},
                {title: 'Country', dataKey: "country", align: "text-center", key: 4, checked: true, canChange: true},
                {title: 'Phone', dataKey: "mobile", align: "text-center", key: 5, checked: true, canChange: true},
                {title: 'Gender', dataKey: "gender", align:"text-center", key: 6, checked: false, canChange: true},
                {title: 'Event', dataKey: "event", align:"text-center", key: 7, checked: false, canChange: true},
                {title: 'Branch', dataKey: "branch", align:"text-center", key: 8, checked: false, canChange: true},
                {title: 'Actve Status', dataKey: "active_status", align:"text-center", key: 9, checked: false, canChange: true},
                {title: '', dataKey: '', align:"text-center", key: 10, checked: true, canChange: false},
                {title: 'CreatedAt', dataKey: 'created_at', align:"text-center", key: 11, checked: true, canChange: true},
            ],
        }
    }
    componentDidMount() {
        this.getMyClients();
    }

     async getMyClients() {
        const { currentPage, perPage } = this.state;
        let url = `getMyClients`;
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('All Clients',res);
            this.setState({
                myClients: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            let options = res.data.data['data'].map(function (item) {
                return { 
                  index: item.index,
                  name: item.name,
                  email: item.email,
                  phone: item.phone,
                  country: item.country,
                  gender: item.gender,
                  created_at: format(new Date(item.created_at), 'd MMM yyyy'),
                };
              })
              this.setState({selectedClients: options})
        },(err) => {
            console.log(err);
        });
    }
       //CallBack pagination function
     handlePaginationCallback = (i, perPage) => {
        window.scrollTo(0, 0);
        if(this.state.search == ''){
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.getMyClients();  }); 
            } else {this.setState({ currentPage : i}, () => { this.getMyClients(); }); }
        } else{
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.handleSearchCallback(this.state.search);  }); 
            } else {this.setState({ currentPage : i}, () => { this.handleSearchCallback(this.state.search); }); }
        }
    }
     async updateAssignedTask(note) {
        const data ={
            notes : note,
            id : this.state.changedID,
        }
        let url = 'updateAssignedTask';
        post_ApiManager(data, url, (res) => {
            console.log('client task updated' , res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                this.getMyClients();
                this.handleNotification();
                swal("Success!", "Completed Note added Successfully!", "success"); 
            };  
        },(err) => {
            console.log(err);
        });
    }
     //  Call Notification bell Counter
     handleNotification(){
        const socket = this.props.socket;
        const user = this.props.user;
        socket.emit("sendNotification", {
            senderName: user,
        });
    }
     // Get values of completed note from modal call back and call change status api function
     handleModelCallback = (check,type,note) => {
        if(type === 'edit'){
        this.setState({newNote: note, show:false},() => { this.updateAssignedTask(note) })
        } else this.setState({show:false})
    }
      // Table sorting
    sorting = (col) => {
        const sortResult = Sorting(this.state.myClients, col, this.state.order)
        this.setState({order: sortResult[0], myClients: sortResult[1]})
    }
     //Search Api
    handleSearchCallback = (input1, input2, input3, input4, input5, input6) => {
        let Name, Email, Phone, Country, Gender, ActiveStatus;
        if(input1 === 'clear'){ 
            this.setState({name: '', email: '', phone:'',  gender:'', activeStatus:1}) 
        } else if (input1 === 'yes') {
            Name = this.state.name;
            Email = this.state.email;
            Phone = this.state.phone;
            Country= this.state.country;
            Gender = this.state.gender;
            ActiveStatus = this.state.activeStatus;
          } else {
            Name = input1;
            Email = input2;
            Phone = input3;
            Country= input4;
            Gender = input5;
            ActiveStatus = input6 === 'Active' ? 1 : input6 === 'Inactive' ? 0 : !input6 ? this.state.activeStatus : 2;
            this.setState({search:'yes', name:Name, email: Email, phone: Phone, gender: Gender, activeStatus: ActiveStatus});
          }
        const data = {
            name:Name,
            email:Email,
            phone:Phone,
            country:Country,
            gender: Gender,
            activeStatus: ActiveStatus,
        }
        console.log(data);
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = `search/myclients`;
        search_ApiManager(url, data, currentPage, perPage,(res) => {
            console.log('search result',res);
            this.setState({
                myClients: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            let options = res.data.data['data'].map(function (item) {
                return { 
                index: item.index,
                name: item.name,
                email: item.email,
                phone: item.phone,
                country: item.country,
                gender: item.gender,
                created_at: format(new Date(item.created_at), 'd MMM yyyy'),
                };
            })
            this.setState({selectedClients: options})
        },(err) => {
            console.log(err);
        }); 
    }
    handleColumnsCallback = (selectedColumns) => {
        this.setState({headers: selectedColumns})
     }
      // All Bulk
    allBulk = () => {
        const newState = this.state.myClients.map(obj => {
              return {...obj, checked: true};
        });
        const array = [];
        this.state.myClients.map(x => {
            array.push({ id: x.id});
        })
        this.setState({myClients: newState, bulk: true, bulkIds: array}, () => {
            console.log(this.state.bulk)
        });
     }
     // Select Bulk
    selectBulk = () => {
        const newState = this.state.myClients.map(obj => {
            return {...obj, checked: false};
        });
        this.setState({bulk: !this.state.bulk, bulkIds: [],myClients: newState});
     }
     onBulkClick = (id, name, checked) => {
        console.log(id, checked)
        if(checked == true){
            const newState = this.state.myClients.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: true};
                }
                return obj;
            });
            this.setState({bulkIds: [...this.state.bulkIds, { id:id, name:name}], myClients: newState, bulk: true});
        } else{
            const newState = this.state.myClients.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: false};
                }
                return obj;
            });
            var array = this.state.bulkIds.filter(function(item) {return item.id !== id});
            this.setState({bulkIds: array, myClients: newState})
        }
     }
     onBulkActiveStatus = (status) => {
        let url = 'bulkClientStatus';
        const data = {
            bulkList: this.state.bulkIds,
            status: status,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('Clients Assigned',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
                this.getMyClients() 
                this.setState({bulk: false, bulkIds:[]});
                this.handleNotification();
            } 
        },(err) => {
            console.log(err);
        })
     }
     handleSort = (col) => () => {
        this.sorting(col);
      };

  render(){
    const role = JSON.parse(localStorage.getItem('user_role'));
    let count = this.state.currentPage * this.state.perPage - this.state.perPage + 1;
    const headers = this.state.headers;
    return(
        <>
         <Title title="View My Clients" />
         <div className="row w-100 pl-3 pr-3 mb-5">
             {/* search bar */}
        <Suspense fallback={<div>Loading...</div>}>
        <MultipleSearchs
         input1={headers[2]['checked'] && 'Search Client'}
         input1_type={"input"}
         input2={headers[3]['checked'] && 'Search Email'}
         input2_type={"input"}
         input3={headers[4]['checked'] && 'Search Phone'}
         input3_type={"input"}
         input4={headers[5]['checked'] && 'Search Country'}
         input4_type={"input"}
         input5={headers[6]['checked'] && 'Search Gender'}
         input5_type={"dropdown"}
         input5Data={genders}
         input5_multiple={"no"}
         input6={'Active Status'}
         input6_type={"dropdown"}
         input6_multiple={"no"}
         input6Data={active_status}
         input6_filter_by={"title"}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={headers}
         />
         </Suspense>
        <div className='w-100 mr-2 text-right'>
        <small className='underline pointer heading-blue' onClick={() => this.allBulk()}>Select All </small> 
        <FaRegCheckSquare className='underline' size={14} color='#4343ff' onClick={() => this.selectBulk()} /></div>
         <table id="myclients" className="table table-bordered bg-white mb-2">
         {/* Headers */}
         <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         head.checked &&
         <th id={head.dataKey} className={head.align} onClick={this.handleSort(head.dataKey)} key={head.key}>
         {head.title}</th>
         ))}
         </tr>
         </thead>
         {/* End Headers */}
         <tbody>
         {this.state.myClients.length > 0 ?
          this.state.myClients.map(value => (
            <tr key={value.id}>
            {headers[0]['checked'] && <td className='text-center'>{this.state.bulk === true ? 
            <input type='checkbox' checked={value.checked} onClick={(e) => this.onBulkClick(value.id, value.name, e.target.checked)} /> 
            : count++}</td>}
            {headers[1]['checked'] && <td className='text-center'>
            <Link to={`/clients/view-client-detail/${value.id}`}>{value.name}</Link></td>}
            {headers[2]['checked'] && <td className='text-center'>{value.email}</td>}
            {headers[3]['checked'] && <td className='text-center'>{value.country}</td>}
            {headers[4]['checked'] && <td className='text-center'>{value.mobile}</td>}
            {headers[5]['checked'] && <td className='text-center'>{value.gender}</td>}
            {headers[6]['checked'] && <td className='text-center'>{value.event}</td>}
            {headers[7]['checked'] && <td className='text-center'>{value.branch}</td>}
            {headers[8]['checked'] && <td className='text-center'> {value.active_status == 0 ? 'Deactivated' : 'Activated'}</td>}
            {headers[9]['checked'] && <td className='text-center'>
            <div onClick={() => this.setState({show:true,changedID: value.id})}>
                <FaRegCheckSquare size={20} color={'rgb(0 169 21)'} />
                </div>
            </td>}
            {headers[10]['checked'] && <td className='text-center'>{format(new Date(value.created_at), 'd MMM yyyy')}</td>}
         </tr>))
        :
        <tr>
            <td colSpan={headers.filter(head => head.checked).length} className='text-center'>
            No record found
            </td>
        </tr>}
        </tbody>
        </table>
        {this.state.bulk == true && this.state.bulkIds[0] && this.state.userRole['role'] === 2 &&
        <div className='d-flex mb-2 bg-white p-2 border'>
        <select className="form-select app-form col-2" name="assignedID" 
        onChange={(e) => this.onBulkActiveStatus(e.target.value)} >
        <option value='0' disabled selected>Select Status</option>
        {activate_status.map(value =>
        <option key={value.title} className="light" value={value.title}>{value.title}</option> )}
        </select> 
        </div>}
        <Paginations
            currentPage={this.state.currentPage}
            total={this.state.totalItem} 
            itemsPerPage={this.state.perPage}
            lastPage={this.state.lastPage}
            totalItems={this.state.totalItem}
            perItem={this.state.perItem}
            onPageChange={this.handlePaginationCallback} 
        />
        {/* Exports */}
        {role === 2 &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'MyClients.pdf'} 
         csvfilename={'MyClients.csv'} 
         xlsxfilename={'MyClients'} 
         header="My Clients List"
         columns={this.state.headers}
         data={this.state.selectedClients} />
         </div>}
        {/* End Exports */}
        </div>
       {/* open model for action completed note */}
       <Modals
        show={this.state.show}
        title={'Add Completed Note'}
        data={'Add Note'} 
        type={'category'}
        textarea="true"
        perItem={this.state.perItem}
        parentCallback={this.handleModelCallback}/>
        </>
    )
  }
}
export default MyClients;