import React, { Component } from 'react'
import swal from 'sweetalert';
import Title from '../GenericComponents/Title';
import { get_ApiManager, post_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import moment from 'moment';
import TaskModal from './TaskModal';
import { format, parseISO } from 'date-fns';
import Loader from '../Loader/Loader';


class ProcessingTasks extends Component {
    constructor(props) {
        super(props);
        var user = JSON.parse(localStorage.getItem('user'));
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        console.log("permissions ",permissions)
        this.state = {
            user: user,
            isManager: Number(permissions?.['manage_tasks']) === 1,
            highPriorityTasks: [],
            standardTasks: [],
            loading: true,
            selectedTask: null,
            showTaskModal: false,
            allPermissions: permissions,
            usersList: [],
        };
    }

    componentDidMount() {
        this.fetchTasks();
        if (this.state.isManager) {
            this.fetchAllUsers();
        }
    }

    claimTask = (taskId) => {
        const data = { 
            claimed_by: this.state.user.id,
            assigned_to: this.state.user.id  // Adding this to maintain consistency with existing structure
        };
        let url = `claimTask/${taskId}`;

        post_ApiManager(data, url, (res) => {
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task claimed successfully", "success");
            } else {
                swal("Error", "Failed to claim task", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to claim task", "error");
        });
    }

    fetchTasks = () => {
        const { isManager, user } = this.state;
        // const url = isManager ? 'getAllTasks' : `getDailyTasks/${user.id}`;
        const url = isManager ? 'getAllTasks' : `getAllTasks`;

    
        // API call to fetch tasks
        get_ApiManager('', url, 
            (res) => {
                console.log('tasks-response', res);
    
                try {
                    // Check if res exists and has data property
                    const tasksData = res?.data?.data || res?.data || res;
                    
                    if (tasksData) {
                        let tasks = tasksData;
    
                        // Filter and process tasks based on user role
                        if (isManager) {
                            // Ensure tasks is an array before filtering
                            tasks = Array.isArray(tasks) ? tasks : [];
                            
                            const highPriorityTasks = tasks.filter(task => task.priority === 'high')
                                .map(task => ({
                                    ...task,
                                    isOverdue: this.checkIfOverdue(task.dueDate),
                                    created_by_name: task.created_by_name || 'N/A',
                                    claimed_by_name: task.claimed_by_name || 'Unclaimed'
                                }));
    
                            const standardTasks = tasks.filter(task => task.priority === 'standard')
                                .map(task => ({
                                    ...task,
                                    isOverdue: this.checkIfOverdue(task.dueDate),
                                    created_by_name: task.created_by_name || 'N/A',
                                    claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                }));
    
                            this.setState({
                                highPriorityTasks,
                                standardTasks,
                                loading: false,
                            });
                        } else {
                            // For regular users
                            let highPriorityTasks = [];
                            let standardTasks = [];
    
                            // Check if tasks has the expected structure or is a flat array
                            if (Array.isArray(tasks)) {
                                // If tasks is a flat array, filter it
                                const activeTasks = tasks.filter(task => task.status !== 'completed');
                                highPriorityTasks = activeTasks
                                    .filter(task => task.priority === 'high')
                                    .map(task => ({
                                        ...task,
                                        isOverdue: this.checkIfOverdue(task.dueDate),
                                        created_by_name: task.created_by_name || 'N/A',
                                        claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                    }));
                                standardTasks = activeTasks
                                    .filter(task => task.priority === 'standard')
                                    .map(task => ({
                                        ...task,
                                        isOverdue: this.checkIfOverdue(task.dueDate),
                                        created_by_name: task.created_by_name || 'N/A',
                                        claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                    }));
                            } else {
                                // If tasks has separate arrays for high priority and standard
                                const activeTasks = {
                                    highPriorityTasks: tasks.highPriorityTasks?.filter(task => task.status !== 'completed') || [],
                                    standardTasks: tasks.standardTasks?.filter(task => task.status !== 'completed') || []
                                };
    
                                highPriorityTasks = activeTasks.highPriorityTasks.map(task => ({
                                    ...task,
                                    isOverdue: this.checkIfOverdue(task.dueDate),
                                    created_by_name: task.created_by_name || 'N/A',
                                    claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                }));
                                standardTasks = activeTasks.standardTasks.map(task => ({
                                    ...task,
                                    isOverdue: this.checkIfOverdue(task.dueDate),
                                    created_by_name: task.created_by_name || 'N/A',
                                    claimed_by_name: task.claimed_by_name || tasks.assigned_to_name || 'Unclaimed'
                                }));
                            }
    
                            this.setState({
                                highPriorityTasks,
                                standardTasks,
                                loading: false,
                            });
                        }
                    } else {
                        console.warn("No tasks found in response");
                        this.setState({
                            highPriorityTasks: [],
                            standardTasks: [],
                            loading: false,
                        });
                    }
                } catch (error) {
                    console.error("Error processing tasks:", error);
                    this.setState({
                        highPriorityTasks: [],
                        standardTasks: [],
                        loading: false,
                    });
                }
            },
            (err) => {
                console.error("API Error", err);
                swal("Error", "Failed to fetch tasks. Please try again.", "error");
                this.setState({ loading: false });
            }
        );
    };
    
    
    checkIfOverdue = (dueDate) => {
        if (!dueDate) return false;
        // Reset time to start of day for accurate comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const taskDueDate = new Date(dueDate);
        taskDueDate.setHours(0, 0, 0, 0);
        
        return taskDueDate < today;
    };

    renderDueDate = (task) => {
        if (!task.dueDate) return 'No due date';
        
        try {
            const formattedDate = new Date(task.dueDate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });

            const isOverdue = this.checkIfOverdue(task.dueDate);
            
            const style = {
                display: 'inline-block',
                padding: '6px 12px',
                borderRadius: '4px',
                fontWeight: isOverdue ? 'bold' : 'normal',
                color: isOverdue ? '#fff' : '#000',
                backgroundColor: isOverdue ? '#dc3545' : '#e2e3e5', // Red for overdue, light gray for normal
                border: isOverdue ? '1px solid #dc3545' : '1px solid #d6d8db'
            };

            return (
                <span style={style}>
                    {formattedDate}
                </span>
            );
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid date';
        }
    };

    handleModalOpen = (type, task = null) => {
        this.setState({
            showTaskModal: true,
            modalType: type,
            selectedTask: task
        });
    };

    handleModalClose = () => {
        this.setState({
            showTaskModal: false,
            modalType: null,
            selectedTask: null
        });
    };

    assignTask = (taskData) => {
        if (!this.state.isManager) return;
        const data = {
            ...taskData,
            created_by: this.state.user.id,
            claimed_by: null // Initialize as unclaimed
        };
        let url = 'createTask';
        post_ApiManager(data, url, (res) => {
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task assigned successfully", "success");
                this.handleModalClose();
            } else {
                swal("Error", "Failed to assign task", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to assign task", "error");
        });
    };

    reassignTask = (taskData) => {
        if (!this.state.isManager) return;

        const { taskId } = taskData;
        const data = {
            ...taskData,
            claimed_by: taskData.assigned_to // Update claimed_by when reassigning
        };
        let url = `reassignTask/${taskId}`;

        post_ApiManager(data, url, (res) => {
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task assigned successfully", "success");
                this.handleModalClose();
            } else {
                swal("Error", "Failed to assign task", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to assign task", "error");
        });
    };

    fetchAllUsers = async () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0, (res) => {
            console.log('Get all Users', res);
            this.setState({ usersList: res.data.data || [] });
        }, (err) => {
            console.log(err);
        });
    }

    handleStatusChange = (taskId, status) => {
        const data = { status: status };
        let url = `updateTaskStatus/${taskId}/${status}`;
        post_ApiManager(data, url, (res) => {
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task status updated successfully", "success");
            } else {
                swal("Error", "Failed to update task status", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to update task status", "error");
        });
    }

    deleteTask = (taskId) => {
        if (!this.state.isManager) return;

        const data = { task_id: taskId };
        let url = `deleteTask/${taskId}`;
        post_ApiManager(data, url, (res) => {
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task deleted successfully", "success");
            } else {
                swal("Error", "Failed to delete task", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to delete task", "error");
        });
    }



    renderTasksTable = (tasks, title) => {
        const { isManager, user } = this.state;
        console.log("isManager",isManager)
        
        return (
            <div className={title.includes('Standard') ? 'mt-4' : ''}>
                <h2 className='row text-center j-content-center p-2 size-22 mb-0' 
                    style={{background:'burlywood'}}>{title}</h2>
                <table className="table table-bordered bg-white">
                    <thead>
                        <tr>
                            <th className='text-center size-15' style={{width: '5%'}}>Sr No.</th>
                            <th className='text-center size-15' style={{width: '5%'}}>Client</th>
                            <th className='text-center size-15' style={{width: '5%'}}>Category</th>
                            <th className='text-center size-15' style={{width: '5%'}}>Application</th>
                            <th className='size-15' style={{width: '40%'}}>Task</th>
                            <th className='text-center size-15' style={{width: '10%'}}>Due Date</th>
                            <th className='text-center size-15' style={{width: '10%'}}>Created By</th>
                            <th className='text-center size-15' style={{width: '10%'}}>Claimed By</th>
                            <th className='text-center size-15' style={{width: '15%'}}>Status</th>
                            {isManager && (<th className='text-center size-15' style={{width: '13%'}}>Actions</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map((task, index) => {
                            const isTaskClaimed = task.claimed_by === user.id || task.assigned_to === user.id;
                            
                            return (
                                <tr key={task.id}>
                                    <td className='text-center size-16'>{index + 1}</td>
                                    {/* <td className='size-16'>{task.client_name}</td> */}
                                    <td className="text-center">
                                        <a
                                            href={`/clients/view-client-detail/${task.client_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {task.client_name}
                                        </a>
                                    </td>
                                    <td className='size-16 text-center'>{task.application_type || "-"}</td>
                                    <td className="text-center">
                                        {task.application_type === 'migration' ? (
                                            <a
                                                href={`/applications/view-migration-application-detail/${task.migration_app_id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {task.migration_course_name || "-"}
                                            </a>
                                        ) : task.application_type === 'education' ? (
                                            <a
                                                href={`/applications/view-education-application-detail/${task.education_app_id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {task.education_course_name || "-"}
                                            </a>
                                        ) : (
                                            <a
                                                href={`/applications/view-migration-application-detail/${task.application_id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {task.course_name || "-"}
                                            </a>
                                        )}
                                    </td>
                                    {/* <td className='size-16'>{task.provider_name}</td> */}
                                    <td className='size-16'>{task.task}</td>
                                    <td className='text-center size-16'>
                                        {this.renderDueDate({
                                            dueDate: task.due_date,
                                            isOverdue: task.isOverdue
                                        })}
                                    </td>
                                    <td className='text-center size-16'>{task.created_by_name}</td>
                                    <td className='text-center size-16'>{task.assigned_to_name || '-'}</td>
                                    {isManager ? (
                                        <td className='text-center size-16'>
                                            <select 
                                                value={task.status} 
                                                onChange={(e) => this.handleStatusChange(task.id, e.target.value)}
                                                className="form-select acc-form w-100 mb-1"
                                            >
                                                <option value="pending">Pending</option>
                                                <option value="in_progress">In Progress</option>
                                                <option value="completed">Completed</option>
                                            </select>
                                        </td>
                                    ) : (
                                        <td className='text-center size-16'>
                                            {isTaskClaimed ? (
                                                <select 
                                                    value={task.status} 
                                                    onChange={(e) => this.handleStatusChange(task.id, e.target.value)}
                                                    className="form-select acc-form w-100 mb-1"
                                                >
                                                    <option value="pending">Pending</option>
                                                    <option value="in_progress">In Progress</option>
                                                    <option value="completed">Completed</option>
                                                </select>
                                            ) : task.assigned_to ? (
                                                <span className="text-muted">Claimed</span>
                                            ) : (
                                                <button
                                                    onClick={() => this.claimTask(task.id)}
                                                    className="btn btn-success btn-sm"
                                                >
                                                    Claim
                                                </button>
                                            )}
                                        </td>
                                    )}
                                    {isManager && (
                                        <td className='text-center size-16'>
                                            <div className="btn-group">
                                                <button
                                                    onClick={() => this.handleModalOpen('reassign', task)}
                                                    className="btn btn-warning btn-sm me-2"
                                                >
                                                    Reassign
                                                </button>
                                                <button 
                                                    onClick={() => this.deleteTask(task.id)}
                                                    className="btn btn-danger btn-sm"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        const { highPriorityTasks, standardTasks, loading, isManager, showTaskModal, selectedTask, modalType } = this.state;
    
        if (loading) return <div><Loader /></div>;
    
        return (
            <>
                <Title 
                    title={isManager ? "All Tasks" : "My Tasks"}
                    btnCol="col-lg-8 text-center pt-lg-1" 
                    btn={true}
                    btnText="View History" 
                    btnClass="float-right btn btn-primary"
                    // btnUrl={`/user-reports/${this.state.user['id']}`}
                    btnUrl={`/processing-reports/${this.state.user['id']}`}
                />
                
                <div className="mx-3 mt-2 mb-5">
                    <h2 className='text-center pb-2 mb-0' style={{color: 'blue'}}>
                        {moment().format('MMMM Do YYYY')}
                    </h2>
    
                    {/* cannot useable now because adding a task required noteid now (linked with addNote functionality) */}
                    {/* {isManager && (
                        <div className="text-end mb-3">
                            <button
                                onClick={() => this.handleModalOpen('create')}
                                className="btn btn-primary"
                            >
                                Add Task
                            </button>
                        </div>
                    )} */}
    
                    {this.renderTasksTable(highPriorityTasks, 'High Priority Tasks')}
                    {this.renderTasksTable(standardTasks, 'Standard Priority Tasks')}
    
                    {isManager && (
                        <TaskModal
                            show={showTaskModal}
                            onClose={this.handleModalClose}
                            onSubmit={modalType === 'create' ? this.assignTask : this.reassignTask}
                            currentUserId={this.state.user.id}
                            modalType={modalType}
                            selectedTask={selectedTask}
                            usersList={this.state.usersList}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default ProcessingTasks;