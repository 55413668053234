
import React ,{useState}from "react"
import { tution_fee, genericDiscipline, prerequisites, duration, countries_code, in_days, category, within, all_within, awaiting_within, paid_within, installment_within, australiaCampus, pakistanCampus, canadaCampus, uaeCampus, germanyCampus } 
from "../../utilis/constants"
import {days} from '../../utilis/constants'
import { Multiselect } from "multiselect-react-dropdown";
import {reportType, allSortBy, sortBy, oshcSortBy, courseSortBy,feeSortby, commissionSortBy } from '../../utilis/appStatus';

const SelectionBar = (props) => {
    const [sDateType, setsDateType] = useState('text');
    const [eDateType, seteDateType] = useState('text');
    const [sDate, setsDate] = useState('text');
    const [within , setWithin] = useState('');
    const [showDates, setShowDates] = useState(false);
    const [campus , setCampus] = useState([]);
    const [providerValue , setProviderValue] = useState([]);

    function sort(){
        const sort_by = props.selectedType == 'fee' ? feeSortby : props.selectedType == 'health' ? oshcSortBy :props.selectedType == 'course' ? courseSortBy : (props.selectedType == 'null' || props.selectedType == null) || props.selectedType == 'license' ? sortBy :  props.selectedType == 'commissions' ? commissionSortBy : allSortBy;
        // Report sort by 
        return(
         <div className='col-lg-2 ml-lg-3 mb-2 pt-lg-4'>
         <select name="sortby" value={props.selectedID} onChange={handleChange} data-live-search="true" 
         className="days form-select mt-2 w-100">
         <option value={'null'}>Select Sort By</option>
         {sort_by.map(value => 
         <option key={value.value} className="bg-grey light" value={value.value}>
         {value.title}</option>
         )}
         </select>
         </div>
        )
    };

    function Days(){
        let Days, Label = '';
        if(props.stypeLabel === 'enddate' || props.stypeLabel === 'expiry'){
            Days = in_days;
            Label = 'In Days';
        } else{
            Days = days;
            Label = 'From Days';
        }
        return(
            <>
            {props.selectedType == 'health'  || props.selectedType == 'fee' || props.selectedType == 'commissions' ? '' :
            <div className="col-lg-2 mb-2 ml-lg-3 pt-lg-4">
            <select value={props.selectedDays} onChange={handleChange} name="days" 
            className="days form-select mt-2 w-100" data-live-search="true">
            <option value="null" selected>{Label}</option>
            {Days.map(value => 
            <option key={value.value} className="bg-grey light" value={value.value}>
            {value.title}</option>
            )}
            </select>
            </div>}
            </>
        )
    }

     // Handle change applications statuses according to application types(eoi,roi,mig,visa)
     const handleChange = (event) => {
         if(event.target.name == 'sDate'){
             setsDate(event.target.value); 
         } 
         if(event.target.name == 'within'){
             setWithin(event.target.value);
             
             if(event.target.value != '') setShowDates(true)
             else setShowDates(false)
         } 
         if(event.target.name == 'countries'){
            if(event.target.value == 'Australia'){
                setCampus(australiaCampus);
            } else if(event.target.value == 'Pakistan'){
                setCampus(pakistanCampus);
            } else if(event.target.value == 'Canada'){
                setCampus(canadaCampus);
            } else if(event.target.value == 'United Arab Emirates'){
                setCampus(uaeCampus);
            } else if(event.target.value == 'Germany'){
                setCampus(germanyCampus);
            }
         }
            props.parentCallback(event.target.value, event.target.name); 
     }

     const changeType = (type, from) => {
         if(from === 'sdate'){
            setsDateType(type);
            if(type == 'text'){
                document.getElementById('s_date').classList.remove('date-input');
                document.getElementById('s_date').classList.add('form-control');
                document.getElementById('s_date').classList.add('app-form');
            } else{
                document.getElementById('s_date').classList.remove('form-control');
                document.getElementById('s_date').classList.remove('app-form');
                document.getElementById('s_date').classList.add('date-input');
            }
         } else{
            seteDateType(type);
            if(type == 'text'){
                document.getElementById('e_date').classList.remove('date-input');
                document.getElementById('e_date').classList.add('form-control');
                document.getElementById('e_date').classList.add('app-form');
            } else{
                document.getElementById('e_date').classList.remove('form-control');
                document.getElementById('e_date').classList.remove('app-form');
                document.getElementById('e_date').classList.add('date-input');
            }
         }
     }

     const handleOptionChange = (event) => {
        props.parentCallback(event.target.value, event.target.name, event.target.options[event.target.selectedIndex].text); 
    }
    const onSelect = (selectedList, selectedItem) => {
       props.parentCallback(selectedItem.title, 'levels'); 
    }

  return(
        <>
        {props.type && props.type === 'report' ?
        <>
          {/* Report Type */}
         <div className="col-lg-2 mb-2 pt-lg-4">
         <select name="type" defaultValue={props.selectedType} onChange={handleChange} data-live-search="true" 
         className="days form-select mt-2 w-100">
         <option value='' >Select Report Type</option>
         {reportType.map(value => 
         <option key={value.value} className="bg-grey light" value={value.value}>
         {value.title}</option>
         )}
         </select>
         </div>

         {sort()}

            {/* Days */}
         {Days()}

         {/* Start Date */}
         {props.selectedType == 'fee' || props.selectedType == 'commissions'  ? '' :
         <div className="col-lg-2 ml-lg-3 mb-2">
         <label className="fira-font bold pr-1" htmlFor="sDate">{props.selectedType == 'health' ? 'Issue Date' : 'Start Date'}</label>
         <input className="date-input pl-2 pr-2 bold w-100" type="date" name="sDate" onChange={handleChange}
         defaultValue={props.selectedsDate}  />
         </div> }

         {/* End Date */}
         {props.selectedType == 'fee' || props.selectedType == 'commissions'  ? '' :
         <div className="col-lg-2 ml-lg-3 mb-2">
         <label className="fira-font bold pr-1" htmlFor="sDate">{props.selectedType == 'health' ? 'Expiry Date' : 'End Date'}</label>
         <input className="date-input pl-2 pr-2 bold w-100" type="date" name="eDate" onChange={handleChange}
         defaultValue={props.selectedeDate}  />
         </div> }
        
         </>
          //also work as search in finance module
        : props.type && props.type === 'finance' ?
        <>
            {/* App Type */}
         <div className="col-lg-2 mb-2">
         <select name="type" defaultValue={props.selectedType} onChange={handleChange} data-live-search="true" 
         className="days form-select mt-2 w-lg-90">
         <option value='' disabled>Application Type</option>
         {category.map(value => 
         <option key={value.value} className="bg-grey light" defaultValue={props.selectedType}>
         {value.title}</option>)}
         </select>
         </div>

         {props.from != 'overpaid' &&
         <>
            {/* Within */}
         <div className="col-lg-2 mb-2">
         <select name="within" defaultValue={props.selectedType} onChange={handleChange} data-live-search="true" 
         className="days form-select mt-2 w-lg-90">
         <option value='' >Search Within</option>
         {props.from == 'all' ? <>
         {all_within.map(value => 
         <option key={value.value} className="bg-grey light" value={value.value}>
         {value.title}</option>)}</> 
         :  props.from == 'awaiting' ?
         <>
         {awaiting_within.map(value => 
         <option key={value.value} className="bg-grey light" value={value.value}>
         {value.title}</option>)}</> 
         :  props.from == 'paid' ?
         <>
         {paid_within.map(value => 
         <option key={value.value} className="bg-grey light" value={value.value}>
         {value.title}</option>)}</> 
         :  props.from == 'installments' ?
         <>
         {installment_within.map(value => 
         <option key={value.value} className="bg-grey light" value={value.value}>
         {value.title}</option>)}</> 
         :''}
         </select>
         </div>

         {showDates &&
         <>
            {/* Start Date */}
        {within != 'overdue' &&
         <div className="col-lg-2 mb-2">
         <input className="app-form form-control pl-2 pr-2 mt-2 w-lg-90" 
         type={sDateType} 
         onFocus={() => changeType('date', 'sdate')}  
         onBlur={() => changeType('text', 'sdate')} 
         name="sDate" onChange={handleChange} 
         placeholder={within == 'duedate' ? 'Start DueDate' : 
         within == 'costdate' ? 'Start Invoice Date':
         within == 'paid' ? 'Start Paid Date' :
         within == 'lastpayment' ? 'Start LastPaid Date' : 'Start Date'}
         id='s_date'
         value={props.selectedsDate}  /> 
         </div>}

            {/* End Date */}
        {within != 'overdue' &&
         <div className="col-lg-2 mb-2">
         <input className="app-form form-control pl-2 mt-2 pr-2 w-lg-90" type={eDateType} 
          onFocus={() => changeType('date', 'edate')}  
         onBlur={() => changeType('text', 'edate')} 
         name="eDate" onChange={handleChange} min={sDate}
         placeholder={within == 'duedate' ? 'End DueDate' : 
         within == 'costdate' ? 'End Invoice Date':
         within == 'paid' ? 'End Paid Date' : 
         within == 'lastpayment' ? 'End LastPaid Date' :'End Date'}
         id='e_date'
         value={props.selectedeDate}  />
         </div>}
         </>}
         </>}

         <div className="col-lg-2 mb-2">
         <select onChange={(e) => props.parentCallback(e.target.value, 'currency')}
         defaultValue={props.currency}
         className="days form-select mt-2 w-lg-90">
         <option className='' value=''>Select Currency</option>
         <option className='aud acc-label2 size-14'>AUD</option>
         <option className='cad size-14'>CAD</option>
         <option className='gbp size-14'>GBP</option>
         <option className='pak size-14'>PKR</option>
         <option className='myr size-14'>MYR</option>
         <option className='sgd size-14'>SGD</option>
         <option className='usd size-14'>USD</option>
         </select>
         </div>

         <div className="col-lg-2 mb-2">
         <input className="app-form form-control mt-2 w-lg-90" type='text'  
          onChange={(e) => props.parentCallback(e.target.value, 'invoiceno')}
         placeholder='Search InvoiceNo' defaultValue={props.invoiceno}  />
         </div>

         <div className="col-lg-2 mb-2">
         <input className="app-form form-control mt-2 w-lg-90" type='text'  
          onChange={(e) => props.parentCallback(e.target.value, 'client')}
         placeholder='Search Client'
         defaultValue={props.client}  />
         </div>

         <div className="col-lg-2 mb-2">
         <input className="app-form form-control mt-2 w-lg-90" type='text'  
         onChange={(e) => props.parentCallback(e.target.value, 'application')}
         placeholder='Search Application'
         defaultValue={props.application}  />
         </div>
         </>
        :
        <>
            {/* Countries */}
        <div className="col-lg-2 mb-2">
        <select onChange={handleChange} name="countries" value={props.defaultCountry} className="form-select acc-form w-100">
        <option value=''>Destination Country</option>
        {countries_code.map((value, index) => 
        <option key={index} value={value.value}>{value.value}</option>
        )}
        </select>
        </div>
            {/* Course */}
        <div className="col-lg-2 ml-lg-2 mb-2">
        <input className="app-form form-control acc-form w-100" type='text'  
         onChange={(e) => props.parentCallback(e.target.value, 'course')}
         placeholder='Search Course'
         defaultValue={props.selectedCourse}  />
        </div>
           {/* Providers */}
           <div className="col-lg-2 mb-2 ml-lg-2">
        <Multiselect
        options={props.providerList.slice().sort((a, b) => a.name.localeCompare(b.name))}
        className="form-select acc-form py-1 chip-hide w-lg-90"
        placeholder={'Select Provider'}
        displayValue={"name"}
        onSelect={selected => props.parentCallback(selected, 'provider', '') }
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={props.defaultProvider}
        />
        </div>
            {/* Discipline */}
        <div className="col-lg-2 ml-lg-2 mb-2">
        <select onChange={handleChange} name="discipline" value={props.defaultDiscipline} className="form-select acc-form">
        <option value=''>Discipline</option>
        {[].concat(genericDiscipline).sort((a, b) => a.title > b.title ? 1 : -1).map((value, index) => 
        <option key={index} value={value.title}>{value.title}</option>
        )}
        </select>
        </div>
            {/* Tution Fee */}
        <div className="col-lg-2 ml-lg-2 mb-2">
        <select name="tutionfee" onChange={handleOptionChange} value={props.defaultFee} className="form-select acc-form">
        <option selected value=''>Tution Fee</option>
        {tution_fee.map((value, index) => 
        <option key={index} value={value.id}>{value.title}</option>
        )}
        </select>
        </div>
            {/* Levels */}
        <div className="col-lg-2 mr-2 mb-2">
        <Multiselect
        options={[].concat(props.levelList).sort((a, b) => a.title > b.title ? 1 : -1)}
        className="form-select acc-form multi"
        groupBy="country"
        placeholder="Levels"
        showArrow={false}
        displayValue="title"
        showCheckbox={false}
        singleSelect
        onSelect={onSelect}
        hidePlaceholder={true}
        />

        </div>
            {/* Intakes */}
        <div className="col-lg-2 mb-2">
        <select onChange={handleOptionChange} name="intake" value={props.defaultIntake} className="form-select acc-form">
        <option selected value=''>Intakes</option>
        {props.intakesList.map((value, index) => 
        <option key={index} className="light" value={value.value}>
        {value.label}</option>
        )}
        </select>
        </div>
            {/* Duration */}
        <div className="col-lg-2 ml-lg-2 mb-2 ">
        <select onChange={handleChange} name="duration" value={props.defaultDuration} className="form-select acc-form">
        <option selected value=''>Duration</option>
        {duration.map((value, index) => 
        <option key={index} value={value.title}>{value.title}</option>
        )}
        </select>
        </div>
            {/* Prerequisites */}
        <div className="col-lg-2 mb-2 ml-lg-2">
        <select onChange={handleChange} name="prerequisit" value={props.defaultPrerequisit} className="form-select acc-form">
        <option selected value=''>Prerequsites</option>
        {prerequisites.map((value, index) => 
        <option key={index} value={value.title}>{value.title}</option>
        )}
        </select>
        </div>
        {/* Campus */}
        <div className="col-lg-2 mb-2 ml-lg-2">
        <select onChange={handleChange} name="campus" value={props.defaultCampus} className="form-select acc-form">
        <option selected value=''>Campus</option>
        {[].concat(campus).sort((a, b) => a.title > b.title ? 1 : -1).map((value, index) =>  
        <option key={index} className="light" value={value.title}>
        {value.title}</option>
        )}
        </select>
        </div>

        </>}
        </>
    )
}
export default SelectionBar;