
import React, {useState} from "react"
import './search.css'
import * as FcIcons from 'react-icons/fc';
import CustomColumnsModel from "./CustomColumnsModel";
import Multiselect from "multiselect-react-dropdown";
import Select from 'react-select';

const MultipleSearchs = ({external_agent, externalAgentData, onSearchChange, allColumns,
     onColumnsChange, showDuplicateEntry, duplicate, input1_type, input1, input1_multiple, input1Data, input1_suggestion, input1_filter_by,
     input2_type, input2, input2_multiple, input2Data, input2_suggestion, input2_filter_by,
     input3_type, input3, input4_type, input3_multiple, input3Data, input3_filter_by,
     input4, input4_multiple, input4_filter_by, input4Data,
     input5_type, input5,input5_multiple, input5Data, input5_filter_by,
     input6_type, input6, input6Data, input6_multiple, input6_filter_by,
     input7_type, input7, input7Data, input7_multiple, input7_filter_by, input7_suggestion,
     input8_type, input8, input8Data, input8_multiple, input8_filter_by, input8_suggestion,
     input9_type, input9,input9_filter_by, input9Data, input9_multiple, input9_suggestion,
     input10_type, input10, input10Data, input10_multiple, input10_filter_by,
     input11_type, input11, input11Data, input11_multiple,input11_filter_by,
     input12_type, input12, input12Data, input12_multiple, input12_filter_by,
     input13_type, input13, input13Data, input13_multiple,
     input14_type, input14, input14Data, input14_multiple,
     input6CallBack},) => {
  const [openCustomColumnsModel , setOpenCustomColumnsModel] = useState(false);
  const [input2Type, setInput2Type] = useState('text');
  const [input3Type, setInput3Type] = useState('text');
  const [input4Type, setInput4Type] = useState('text');
  const [input5Type, setInput5Type] = useState('text');
  const [input1Value , setInput1Value] = useState('');
  const [input2Value , setInput2Value] = useState('');
  const [input3Value , setInput3Value] = useState('');
  const [input4Value , setInput4Value] = useState('');
  const [input5Value , setInput5Value] = useState('');
  const [input6Value , setInput6Value] = useState('');
  const [input7Value , setInput7Value] = useState('');
  const [input8Value , setInput8Value] = useState('');
  const [input9Value , setInput9Value] = useState('');
  const [input10Value , setInput10Value] = useState('');
  const [input11Value , setInput11Value] = useState('');
  const [input12Value , setInput12Value] = useState('');
  const [input13Value , setInput13Value] = useState('');
  const [input14Value , setInput14Value] = useState('');
  const [input1ArrayValue , setInput1ArrayValue] = useState([]);
  const [input2ArrayValue , setInput2ArrayValue] = useState([]);
  const [input4ArrayValue , setInput4ArrayValue] = useState([]);
  const [input6ArrayValue , setInput6ArrayValue] = useState([]);
  const [input7ArrayValue , setInput7ArrayValue] = useState([]);
  const [input8ArrayValue , setInput8ArrayValue] = useState([]);
  const [input9ArrayValue , setInput9ArrayValue] = useState([]);
  const [input10ArrayValue , setInput10ArrayValue] = useState([]);
  const [input11ArrayValue , setInput11ArrayValue] = useState([]);
  const [input12ArrayValue , setInput12ArrayValue] = useState([]);
  const [input13ArrayValue , setInput13ArrayValue] = useState([]);

  // Get values of completed note from modal call back and call change status api function
    const handleModelCallback = (edit, selectedColumns, duplicate) => {
        if(edit === 'add'){
            onColumnsChange(selectedColumns, duplicate);
            setOpenCustomColumnsModel(false);
        } else{
            setOpenCustomColumnsModel(false);
        }
     }

    const clearAll = (e) => {
        e.preventDefault();
        setInput1Value('');
        setInput2Value('');
        setInput3Value('');
        setInput4Value('');
        setInput5Value('');
        setInput6Value('');
        setInput7Value('');
        setInput8Value('');
        setInput1ArrayValue('');
        setInput4ArrayValue('');
        setInput6ArrayValue('');
        setInput7ArrayValue('');
        setInput8ArrayValue('');
        setInput9ArrayValue('');
        setInput10ArrayValue('');
        setInput11ArrayValue('');
        setInput12ArrayValue('');
        setInput13ArrayValue('');
        onSearchChange('clear')
    }
    const searchBtn = (e) => {
        e.preventDefault();
        onSearchChange(
            input1_type === 'input' || input1_multiple === "no" ? input1Value : input1_type === 'dropdown' && input1_multiple === "yes" ? input1ArrayValue :'',
            input2_type === 'input' || input2_type === 'date' || input2_multiple === "no" ? input2Value : input2_type === 'dropdown' && input2_multiple === "yes" ? input2ArrayValue:'',
            input3_type === 'input' || input3_type === 'date' || input3_multiple === "no" ? input3Value : input3_type === 'dropdown' && input3_multiple === "yes" ? '' :'',
            input4_type === 'input' || input4_type === 'date' || input4_multiple === "no" ? input4Value : input4_type === 'dropdown' && input4_multiple === "yes" ? input4ArrayValue :'',
            input5Value,
            input6_type === 'input' || input6_multiple === "no" ? input6Value : input6_type === 'dropdown' && input6_multiple === "yes" ? input6ArrayValue :'',
            input7_type === 'input' || input7_multiple === "no" ? input7Value : input7_type === 'dropdown' && input7_multiple === "yes" ? input7ArrayValue :'',
            input8_type === 'input' || input8_multiple === "no" ? input8Value : input8_type === 'dropdown' && input8_multiple === "yes" ? input8ArrayValue :'',
            input9_type === 'input' || input9_multiple === "no" ? input9Value : input9_type === 'dropdown' && input9_multiple === "yes" ? input9ArrayValue :'',
            input10_type === 'input'|| input10_multiple === "no" ? input10Value : input10_type === 'dropdown' && input10_multiple === "yes" ? input10ArrayValue :'',
            input11_type === 'input'|| input11_multiple === "no" ? input11Value : input10_type === 'dropdown' && input11_multiple === "yes" ? input11ArrayValue :'',
            input12_type === 'input'|| input12_multiple === "no" ? input12Value : input12_type === 'dropdown' && input12_multiple === "yes" ? input12ArrayValue :'',
            input13_type === 'input'|| input13_multiple === "no" ? input13Value : input13_type === 'dropdown' && input13_multiple === "yes" ? input13ArrayValue :'',
            input14Value,
            )
    }
    const handle1InputChanges = (index) => {
        // Create a copy of the array to avoid mutating the original array directly
        const newArray = [...input1ArrayValue];
        // Remove the item at the specified index
        newArray.splice(index, 1);
        // Update the state or perform any desired operations with the modified array
        setInput1ArrayValue(newArray);
    };
    const handle2InputChanges = (index) => {
        // Create a copy of the array to avoid mutating the original array directly
        const newArray = [...input2ArrayValue];
        // Remove the item at the specified index
        newArray.splice(index, 1);
        // Update the state or perform any desired operations with the modified array
        setInput2ArrayValue(newArray);
    };
    const handle4InputChanges = (index) => {
        // Create a copy of the array to avoid mutating the original array directly
        const newArray = [...input4ArrayValue];
        // Remove the item at the specified index
        newArray.splice(index, 1);
        // Update the state or perform any desired operations with the modified array
        setInput4ArrayValue(newArray);
    };
    const handle6InputChanges = (index) => {
        // Create a copy of the array to avoid mutating the original array directly
        const newArray = [...input6ArrayValue];
        // Remove the item at the specified index
        newArray.splice(index, 1);
        // Update the state or perform any desired operations with the modified array
        setInput6ArrayValue(newArray);
    };
    const handle7InputChanges = (index) => {
        const newArray = [...input7ArrayValue];
        newArray.splice(index, 1);
        setInput7ArrayValue(newArray);
    };
    const handle8InputChanges = (index) => {
        const newArray = [...input8ArrayValue];
        newArray.splice(index, 1);
        setInput8ArrayValue(newArray);
    };
    const handle9InputChanges = (index) => {
        const newArray = [...input9ArrayValue];
        newArray.splice(index, 1);
        setInput9ArrayValue(newArray);
    };
    const handle10InputChanges = (index) => {
        const newArray = [...input10ArrayValue];
        newArray.splice(index, 1);
        setInput10ArrayValue(newArray);
    };
    const handle11InputChanges = (index) => {
        const newArray = [...input11ArrayValue];
        newArray.splice(index, 1);
        setInput11ArrayValue(newArray);
    };
    const handle12InputChanges = (index) => {
        const newArray = [...input12ArrayValue];
        newArray.splice(index, 1);
        setInput12ArrayValue(newArray);
    };
    const handle13InputChanges = (index) => {
        const newArray = [...input13ArrayValue];
        newArray.splice(index, 1);
        setInput13ArrayValue(newArray);
    };
    const handle2Focus = () => {
        setInput2Type('date');
    };
    const handle2Blur = () => {
        setInput2Type('text');
    };
    const handle3Focus = () => {
        setInput3Type('date');
    };
    const handle3Blur = () => {
          setInput3Type('text');
    };
    const handle4Focus = () => {
      setInput4Type('date');
    };
    const handle4Blur = () => {
        setInput4Type('text');
    };
    const handle5Focus = () => {
        setInput5Type('date');
    };
    const handle5Blur = () => {
        setInput5Type('text');
    };
    const sendValueDirectly = (value) => {
        setInput6Value(value)
        setInput1Value('')
        setInput2Value('')
        setInput3Value('')
        setInput4Value('')
        setInput5Value('')
        onSearchChange('', '', '','','',value)
        setInput7Value('')
        setInput7ArrayValue([])
    }
    const handleSelectChange = (selectedOptions) => {
        setInput1ArrayValue(selectedOptions || [])
      };
    const handleSelectChange2 = (selectedOptions) => {
      setInput2ArrayValue(selectedOptions || [])
    };
    const handleSelectChange7 = (selectedOptions) => {
        setInput7ArrayValue(selectedOptions || [])
    };
    const handleSelectChange8 = (selectedOptions) => {
        setInput8ArrayValue(selectedOptions || [])
    };
    const handleSelectChange9 = (selectedOptions) => {
        setInput9ArrayValue(selectedOptions || [])
    };
      const customStyles = {
        multiValue: (provided) => ({
          ...provided,
          display: 'none', // Hide the selected values
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          display: 'none', // Hide the selected values' labels
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          display: 'none', // Hide the remove button
        }),  
        placeholder: (provided) => ({
            ...provided,
            display: 'block', 
        }),
        input: (provided) => ({
          ...provided,
          margin: 0, // Adjust margin if needed
        }),
      };

    return(
        <>
        <div className="row mb-3">
        <form >
        <div className="row inner-form py-2 px-3">
        <div className="d-flex j-content-right">
        {allColumns &&
        <div className="pt-0 pointer pr-1">
        <FcIcons.FcAutomatic className="mb-1" onClick={() => setOpenCustomColumnsModel(true)} size={20}  />
         </div>}
        {allColumns &&
        <div className="pt-0 pointer pr-1">
        <div className="size-13 pt-1 font-sans" style={{color:'#4a4aff'}} onClick={(e) => clearAll(e)}> Clear All </div>
        </div>}
         </div>
         {/* seacrh input section */}

         {/* Input 1 fields */}
        {input1_type === 'input' &&
        input1 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" value={input1Value}
        placeholder={input1} onChange={(e) => setInput1Value(e.target.value)} />
        </div>}
         {/* Input 1 fields */}
        {input1_type === 'dropdown' && input1_multiple === 'no' &&
        input1 && 
        <div className='col-lg-2 col-6'>
        <select value={input1} onChange={(e) => setInput1Value(e.target.value)}
        className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled>{input1}</option>
        {[].concat(input1Data).sort((a, b) => a.name > b.name ? 1 : -1).map((value) => (
            <option key={value.id} className={input1 === value.name ? "selected" : ""}>
            {value.name}
            </option>
        ))}
        </select>
        </div>}
        {input1_type === 'dropdown' && input1_multiple === 'yes' &&
        input1 &&
        <div className='col-lg-2 col-6'>
        {input1_suggestion ?
         <Select
            isMulti
            name="participants"
            options={input1Data.filter(option => !input1ArrayValue.includes(option))} // Filter out selected values
            className="s-dropdown search py-0 shadow-sm mt-1 chip-hide w-lg-90"
            classNamePrefix="select"
            onChange={handleSelectChange}
            placeholder={input1} // Use dynamic placeholder
            noOptionsMessage={() => 'No options available'}
            value={input1ArrayValue} // Manage selected values
            styles={customStyles}
            />
        :
        <Multiselect
        options={input1Data.slice().sort((a, b) => a.name.localeCompare(b.name))}
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input1}
        displayValue={'name'}
        onSelect={selected => setInput1ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input1ArrayValue}
        />}
        </div>}

        {/* Input 2 fields */}
        {input2_type === 'input' &&
        input2 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" value={input2Value}
        placeholder={input2} onChange={(e) => setInput2Value(e.target.value)} />
        </div>}
        {input2_type === 'date' &&
        input2 &&
        <div className='col-lg-2 col-6'>
        <input type={input2Type} className="form-control search bg-white w-lg-90 m-1 shadow-sm" onChange={(e) => setInput2Value(e.target.value)} 
        placeholder={input2} onFocus={handle2Focus} onBlur={handle2Blur} value={input2Value} />
        </div>}
         {/* Input 2 fields */}
        {input2_type === 'dropdown' && input2_multiple === 'no' &&
        input2 && input2Data &&
        <div className='col-lg-2 col-6'>
        <select value={input2} onChange={(e) => setInput2Value(e.target.value)}
        className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled>{input2}</option>
        {input2Data.map((value) => (
            <option key={value.id} className={input2 === value.name ? "selected" : ""}>
            {value.name}
            </option>
        ))}
        </select>
        </div>}

        {input2_type === 'dropdown' && input2_multiple === 'yes' &&
        input2Data &&
        <div className='col-lg-2 col-6'>
        {input2_suggestion ?
         <Select
            isMulti
            name="participants"
            options={input2Data.filter(option => !input2ArrayValue.includes(option))} // Filter out selected values
            className="s-dropdown search py-0 shadow-sm mt-1 chip-hide w-lg-90"
            classNamePrefix="select"
            onChange={handleSelectChange2}
            placeholder={input2} // Use dynamic placeholder
            noOptionsMessage={() => 'No options available'}
            value={input2ArrayValue} // Manage selected values
            styles={customStyles}
            />
        :
        <Multiselect
        options={input2Data.slice().sort((a, b) => a.name.localeCompare(b.name))}
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input2}
        displayValue={'name'}
        onSelect={selected => setInput2ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input2ArrayValue}
        />}
        </div>}

        {/* Input 3 fields */}
        {input3_type === 'input' &&
        input3 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" value={input3Value}
        placeholder={input3} onChange={(e) => setInput3Value(e.target.value)} />
        </div>}
        {input3_type === "date" &&
        input3 &&
        <div className='col-lg-2 col-6'>
        <input type={input3Type} className="form-control search bg-white w-lg-90 m-1 shadow-sm" onChange={(e) => setInput3Value(e.target.value)} 
        placeholder={input3} onFocus={handle3Focus} onBlur={handle3Blur} value={input3Value} />
        </div>}
          {/* Input 3 fields */}
        {input3_type === 'dropdown' && input3_multiple === 'no' &&
        input3 && input3Data &&
        <div className='col-lg-2 col-6'>
        <select value={input1} onChange={(e) => setInput3Value(e.target.value)}
        className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option>{input3}</option>
        {input3Data.map((value) => (
            <option key={input3_filter_by && input3_filter_by === 'title' ? value.value : value.value} 
            className={input3 === value.label ? "selected" : ""}>
            {input3_filter_by && input3_filter_by === 'title' ? value.title : value.label}
            </option>
        ))}
        </select>
        </div>}
         {/* Input 4 fields */}
        {input4_type === 'input' &&
        input4 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" value={input4Value}
        placeholder={input4} onChange={(e) => setInput4Value(e.target.value)} />
        </div>}
        {input4_type === 'date' &&
        input4 &&
        <div className='col-lg-2 col-6'>
        <input type={input4Type} className="form-control search bg-white w-lg-90 m-1 shadow-sm" onChange={(e) => setInput4Value(e.target.value)} 
        placeholder={input4} onFocus={handle4Focus} onBlur={handle4Blur} value={input4Value} />
        </div>}

        {input4_type === 'dropdown' && input4_multiple === 'yes' &&
        input4 &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={input4Data.slice().sort((a, b) => a.name.localeCompare(b.name))}
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input4}
        displayValue={'name'}
        onSelect={selected => setInput4ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input4ArrayValue}
        />
        </div>}
        {input4_type === 'dropdown' && input4_multiple === 'no' &&
        input4 &&
        <div className='col-lg-2 col-6'>
        <select value={input4} onChange={(e) => setInput4Value(e.target.value)}
        className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option selected>{input4}</option>
        {input4Data.map((value) => (
            <option key={input4_filter_by && input4_filter_by === 'label' ? value.value: input4_filter_by === 'agents' ? value.agencyname: value.title} 
            value={input4_filter_by && input4_filter_by === 'label'? value.value : input4_filter_by === 'agents' ? value.agencyname : value.id}
            className={input4 === value.title ? "selected" : ""}>
            {input4_filter_by && input4_filter_by === 'label' ? value.label : input4_filter_by === 'agents' ? value.agencyname: value.title}
            </option>
        ))}
        </select>
        </div>}
        {/* Input 5 fields */}
        {input5_type === 'input' &&
        input5 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" value={input5Value}
        placeholder={input5} onChange={(e) => setInput5Value(e.target.value)} />
        </div>}
        {input5_type === 'date' &&
        input5 &&
        <div className='col-lg-2 col-6'>
        <input type={input5Type} className="form-control search bg-white w-lg-90 m-1 shadow-sm" onChange={(e) => setInput5Value(e.target.value)} 
        placeholder={input5} onFocus={handle5Focus} onBlur={handle5Blur} value={input5Value} />
        </div>}
        {input5_type === 'dropdown' && input5_multiple === 'no' &&
        input5 &&
        <div className='col-lg-2 col-6'>
        <select value={input5} onChange={(e) => setInput5Value(e.target.value)}
        className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled >{input5}</option>
        {input5Data.map((value) => (
            <option key={value.title} className={input5 === value.title ? "selected" : ""}>
            {value.title}
            </option>
        ))}
        </select>
        </div>}
        {/* Input 6 fields */}
        {input6_type === 'input' &&
        input6 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" value={input6Value}
        placeholder={input6} onChange={(e) => setInput6Value(e.target.value)} />
        </div>}
         {/* Input 6 fields dropdown */}
        {input6_type === 'dropdown' && input6_multiple === 'yes' &&
        input6 &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={input6_filter_by && input6_filter_by === 'agents' ? input6Data.slice().sort((a, b) => a.agencyname.localeCompare(b.agencyname))
        : input6Data.slice().sort((a, b) => a.title.localeCompare(b.title))}
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input6}
        displayValue={input6_filter_by && input6_filter_by === 'agents' ? 'agencyname' : 'title'}
        onSelect={selected => setInput6ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input6ArrayValue}
        />
        </div>}
        {input6_type === 'dropdown' && input6_multiple === 'no' &&
        input6 &&
        <div className='col-lg-2 col-6'>
        <select value={input6} onChange={(e) => input6CallBack ? sendValueDirectly(e.target.value) : setInput6Value(e.target.value)}
        className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled >{input6}</option>
        {input6Data.map((value) => (
            <option key={value.title} className={input6 === value.title ? "selected" : ""}>
            {value.title}
            </option>
        ))}
        </select>
        </div>}
        
        {/* Input 7 fields */}
        {input7_type === 'dropdown' && input7_multiple === 'no' &&
        input7 &&
        <div className='col-lg-2 col-6'>
        <select value={input7} onChange={(e) => setInput7Value(e.target.value)}
        className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled >{input7}</option>
        {input7Data.map((value) => (
            <option key={value.title} className={input7 === value.title ? "selected" : ""}>
            {value.title}
            </option>
        ))}
        </select>
        </div>}
        {/* Input 7 fields */}
        {input7_type === 'dropdown' && input7_multiple === 'yes' &&
        input7 &&
        <div className='col-lg-2 col-6'>
        {input7_suggestion ?
         <Select
            isMulti
            name="participants"
            options={input7Data.filter(option => !input7ArrayValue.includes(option))} // Filter out selected values
            className="s-dropdown search py-0 shadow-sm mt-1 chip-hide w-lg-90"
            classNamePrefix="select"
            onChange={handleSelectChange7}
            placeholder={input7} // Use dynamic placeholder
            noOptionsMessage={() => 'No options available'}
            value={input7ArrayValue} // Manage selected values
            styles={customStyles}
            />
        :
        <Multiselect
        options={input7_filter_by && input7_filter_by === 'agents' ? input7Data.slice().sort((a, b) => a.agencyname.localeCompare(b.agencyname)) 
         : input7_filter_by === 'title' ? input7Data.slice().sort((a, b) => a.title.localeCompare(b.title))
         : input7Data.slice().sort((a, b) => a.name.localeCompare(b.name))}
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input7}
        displayValue={input6_filter_by && input6_filter_by === 'agents' ? 'agencyname' : input6_filter_by === 'title' ? "title" : "name"}
        onSelect={selected => setInput7ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input7ArrayValue}
        />}
        </div>}

        {/* Input 8 fields */}
        {input8_type === 'dropdown' && input8_multiple === 'yes' &&
        input8 &&
        <div className='col-lg-2 col-6'>
        {input8_suggestion ?
         <Select
            isMulti
            name="participants"
            options={input8Data.filter(option => !input8ArrayValue.includes(option))} // Filter out selected values
            className="s-dropdown search py-0 shadow-sm mt-1 chip-hide w-lg-90"
            classNamePrefix="select"
            onChange={handleSelectChange8}
            placeholder={input8} // Use dynamic placeholder
            noOptionsMessage={() => 'No options available'}
            value={input8ArrayValue} // Manage selected values
            styles={customStyles}
            />
        :
        <Multiselect
        options={input8_filter_by && input8_filter_by === "name" ?
         input8Data.slice().sort((a, b) => a.name.localeCompare(b.name)) :
        input8Data.slice().sort((a, b) => a.title.localeCompare(b.title))}
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input8}
        displayValue={input8_filter_by && input8_filter_by === 'name' ? 'name' : "title"}
        onSelect={selected => setInput8ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input8ArrayValue}
        />}
        </div>}
        {input8_type === 'dropdown' && input8_multiple === 'no' &&
        input8 &&
        <div className='col-lg-2 col-6'>
        <select value={input8} onChange={(e) => setInput8Value(e.target.value)} className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled>{input8}</option>
        {input8Data.map((value) => (
            <option key={value.title} className={input8 === value.title ? "selected" : ""}
            value={value.title}>{value.title}</option>
        ))}
        </select>
        </div>}

        {/* Input 9 fields */}
        {input9_type === 'dropdown' && input9_multiple === 'yes' &&
        input9 &&
        <div className='col-lg-2 col-6'>
        {input9_suggestion ?
         <Select
            isMulti
            name="participants"
            options={input9Data.filter(option => !input9ArrayValue.includes(option))} // Filter out selected values
            className="s-dropdown search py-0 shadow-sm mt-1 chip-hide w-lg-90"
            classNamePrefix="select"
            onChange={handleSelectChange9}
            placeholder={input9} // Use dynamic placeholder
            noOptionsMessage={() => 'No options available'}
            value={input9ArrayValue} // Manage selected values
            styles={customStyles}
            />
        :
        <Multiselect
        options={input9_filter_by && input9_filter_by === "name" ?
        input9Data.slice().sort((a, b) => a.name.localeCompare(b.name)):
        input9Data.slice().sort((a, b) => a.title.localeCompare(b.title)) }
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input9}
        displayValue={input9_filter_by && input9_filter_by === 'name' ? 'name' : "title"}
        onSelect={selected => setInput9ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input9ArrayValue}
        />}
        </div>}

          {/* Input 10 fields */}
        {input10_type === 'dropdown' && input10_multiple === 'yes' &&
        input10 &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={input10_filter_by && input10_filter_by === "name" ?
         input10Data.slice().sort((a, b) => a.name.localeCompare(b.name)) :
         input10Data.slice().sort((a, b) => a.title.localeCompare(b.title)) }
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input10}
        displayValue={input10_filter_by && input10_filter_by === 'name' ? 'name' : "title"}
        onSelect={selected => setInput10ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input10ArrayValue}
        />
        </div>}

        {input10_type === 'dropdown' && input10_multiple === 'no' &&
        input10 &&
        <div className='col-lg-2 col-6'>
        <select value={input10} onChange={(e) => setInput10Value(e.target.value)} className="form-select s-dropdown pb-2 shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled>{input10}</option>
        {input10Data.map((value) => (
            <option key={value.name} className={input8 === value.name ? "selected" : ""}
            value={value.name}>{value.name}</option>
        ))}
        </select>
        </div>}

        {input11_type === 'dropdown' && input11_multiple === 'no' &&
        input11 &&
        <div className='col-lg-2 col-6'>
        <select value={input11} onChange={(e) => setInput11Value(e.target.value)} className="form-select s-dropdown shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled>{input11}</option>
        {input11Data.map((value) => (
            <option key={value.title} className={input11 === value.title ? "selected" : ""}
            value={value.value}>{value.title}</option>
        ))}
        </select>
        </div>}

        {input11_type === 'dropdown' && input11_multiple === 'yes' &&
        input11 &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={input11_filter_by && input11_filter_by === "agencyname" ?
        input11Data.slice().sort((a, b) => a.agencyname.localeCompare(b.agencyname)) :
        input11Data.slice().sort((a, b) => a.title.localeCompare(b.title)) }
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input11}
        displayValue={input11_filter_by && input11_filter_by === 'agencyname' ? 'agencyname' : "title"}
        onSelect={selected => setInput11ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input11ArrayValue}
        />
        </div>}

        {/* Input 12 fields */}
        {input12_type === 'dropdown' && input12_multiple === 'yes' &&
        input12 &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={input12_filter_by && input12_filter_by === "agencyname" ?
        input12Data.slice().sort((a, b) => a.agencyname.localeCompare(b.agencyname)) :
        input12_filter_by === "name" ? 
        input12Data.slice().sort((a, b) => a.name.localeCompare(b.name))
        :
        input12Data.slice().sort((a, b) => a.title.localeCompare(b.title)) }
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input12}
        displayValue={input12_filter_by && input12_filter_by === 'agencyname' ? 'agencyname' :
        input12_filter_by === 'name' ? 'name': "title"}
        onSelect={selected => setInput12ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input12ArrayValue}
        />
        </div>}

        {input13_type === 'dropdown' && input13_multiple === 'no' &&
        input13 &&
        <div className='col-lg-2 col-6'>
        <select value={input13} onChange={(e) => setInput13Value(e.target.value)} className="form-select s-dropdown shadow-sm pt-1 w-lg-90 mt-1 form-control">
        <option disabled>{input13}</option>
        {input13Data.map((value) => (
            <option key={value.title} className={input13 === value.title ? "selected" : ""}
            value={value.value ? value.value : value.title}>{value.title}</option>
        ))}
        </select>
        </div>}

          {/* Input 13 fields */}
        {input13_type === 'dropdown' && input13_multiple === 'yes' &&
        input13 &&
        <div className='col-lg-2 col-6'>
        <Multiselect
         options={input13Data.slice().map(item => ({
        ...item,
        combinedText: `${item.title} - ${item.country}`
    })).sort((a, b) => a.combinedText.localeCompare(b.combinedText))}
        className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
        placeholder={input13}
        displayValue={"combinedText"}
        onSelect={selected => setInput13ArrayValue(selected)}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        selectedValues={input13ArrayValue}
        />
        </div>}

        {input14_type === 'input' &&
        input14 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" value={input14Value}
        placeholder={input14} onChange={(e) => setInput14Value(e.target.value)} />
        </div>}

        {/* Search Button */}
        <div className="row mt-2">
        <div className="col-lg-10 col-12">
        <div className="row selectedValues">
        {/* Input1 searched value */}
        {input1Value &&
            <div className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput1Value("")}>
                </button><div className="list-tag">{input1Value}</div>
            </div>}
        {/* Input1 searched value */}
        {input1ArrayValue &&
        input1ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                    <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle1InputChanges(index)}>
                    </button><div className="list-tag">{input1_filter_by && input1_filter_by === 'name' ? item.name : item.label}</div>
                </div>
        ))}
        {input2ArrayValue &&
        input2ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                    <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle2InputChanges(index)}>
                    </button><div className="list-tag">{input2_filter_by && input2_filter_by === 'name' ? item.name : item.label}</div>
                </div>
        ))}
        {/* Input2 searched value */}
        {input2Value &&
            <div className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput2Value("")}>
                </button><div className="list-tag">{input2Value}</div>
            </div>}
        {/* Input3 searched value */}
        {input3Value &&
            <div className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput3Value("")}>
                </button><div className="list-tag">{input3Value}</div>
            </div>}
        {/* Input4 searched value */}
        {input4Value &&
            <div className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput4Value("")}>
                </button><div className="list-tag">{input4Value}</div>
            </div>}
        {input4ArrayValue &&
        input4ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                    <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle4InputChanges(index)}>
                    </button><div className="list-tag">{item.name}</div>
                </div>
        ))}
        {/* Input5 searched value */}
        {input5Value &&
            <div className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput5Value("")}>
                </button><div className="list-tag">{input5Value}</div>
            </div>}
        {/* Input6 searched value */}
        {input6Value &&
        <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => input6CallBack ? sendValueDirectly() :setInput6Value("")}>
            </button><div className="list-tag">{input6Value}</div>
        </div>}
        {input6ArrayValue &&
        input6ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                    <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle6InputChanges(index)}>
                    </button><div className="list-tag">{input6_filter_by && input6_filter_by === 'agents' ? item.agencyname : item.title}</div>
                </div>
        ))}
          {/* Input7 searched value */}
        {input7ArrayValue &&
        input7ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                    <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle7InputChanges(index)}>
                    </button><div className="list-tag">{input7_filter_by && input7_filter_by === 'agents' ?  item.agencyname 
                    : input7_suggestion ? item.label :
                     item.name}</div>
                </div>
        ))}
        {input7Value &&
        <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput7Value("")}>
            </button><div className="list-tag">{input7Value}</div>
        </div>}
        {/* Input8 searched value */}
        {input8Value &&
        <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput8Value("")}>
            </button><div className="list-tag">{input8Value}</div>
        </div>}
        {/* Input8 searched value */}
        {input8ArrayValue &&
        input8ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                    <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle8InputChanges(index)}>
                    </button><div className="list-tag">{input8_filter_by && input8_filter_by === 'name' ? item.label ? item.label : item.name : item.title}</div>
                </div>
        ))}
          {/* Input9 searched value */}
        {input9ArrayValue &&
        input9ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle9InputChanges(index)}>
                </button><div className="list-tag">{item.name? item.name : item.label ? item.label : item.title}</div>
            </div>
        ))}
           {/* Input10 searched value */}
        {input10ArrayValue &&
        input10ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle10InputChanges(index)}>
                </button><div className="list-tag">{input8_filter_by && input8_filter_by === 'name' ? item.name : item.title}</div>
            </div>
        ))}
        {input10Value &&
            <div className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput10Value("")}>
                </button><div className="list-tag">{input10Value}</div>
            </div>}
            {/* Input11 searched value */}
        {input11Value &&
        <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput11Value("")}>
            </button><div className="list-tag">{input11Value}</div>
        </div>}
        {input11ArrayValue &&
        input11ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle11InputChanges(index)}>
                </button><div className="list-tag">{input11_filter_by && input11_filter_by === 'agencyname' ? item.agencyname : item.title}</div>
            </div>
        ))}
            {/* Input12 searched value */}
            {input12ArrayValue &&
        input12ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle12InputChanges(index)}>
                </button><div className="list-tag">{input12_filter_by && input12_filter_by === 'agencyname' ? item.agencyname :
                    input12_filter_by === 'name' ? item.name: item.title}</div>
            </div>
        ))}
            {/* Input13 searched value */}
        {input13Value &&
        <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput13Value("")}>
            </button><div className="list-tag">{input13Value}</div>
        </div>}

          {/* Input13 searched value */}
          {input13ArrayValue &&
        input13ArrayValue.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => handle13InputChanges(index)}>
                </button><div className="list-tag">{item.title}</div>
            </div>
        ))}

            {/* Input14 searched value */}
        {input14Value &&
        <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => setInput14Value("")}>
            </button><div className="list-tag">{input14Value}</div>
        </div>}
        
        </div>
        </div>
        {/* Search Button */}
        <div className="col-lg-2 col-6">
        <button className="btn btn-search float-right w-100 text-left"
        onClick={(e) => searchBtn(e)}>
        <i className="fa fa-search pr-2" aria-hidden="true"></i>Search</button>
        </div>
        </div>
      
        </div>
        </form>
        </div>
        {allColumns &&
        <CustomColumnsModel
        show={openCustomColumnsModel}
        parentCallback={handleModelCallback}
        allcolumns={allColumns}
        defaultDuplicate={duplicate}
        showDuplicateEntry={showDuplicateEntry}
        />
        }
        </>
    )
}
export default MultipleSearchs;