import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Title from '../GenericComponents/Title';
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { Sorting } from "../GenericComponents/SortTables";
import moment from "moment";
import { search_ApiManager, view_ApiManager } from "../ApiManager/ApiManager";
import Paginations from "../GenericComponents/Pagination";
import MultipleSearchs from "../GenericComponents/MultipleSearchs";

const ProcessingReports = () => {
    const { id } = useParams();
    const history = useNavigate();
    const [allHistory, setAllHistory] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [perItem, setPerItem] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [lastPage, setLastPage] = useState('');
    const [headers] = useState([
        { title: "Sr No", dataKey: "index", align: "text-center", key: 1, checked: true, canChange: true },
        { title: "Date", dataKey: "created_at", align: "text-center", key: 2, checked: true, canChange: true },
        { title: "View Report", dataKey: "view_report", align: "text-center", key: 3, checked: true, canChange: true },
    ]);

    useEffect(() => {
        getHistory();
    }, [currentPage, perPage]);

    useEffect(() => {
        if (search) {
            handleSearchCallback(search);
        }
    }, [currentPage, perPage]);

    const getHistory = () => {
        let url = `getUserDailyRecord`;
        console.log(url);
        view_ApiManager(url, currentPage, perPage, (res) => {
            console.log('history', res);
            setAllHistory(res.data.data['data']);
            setTotalItem(res.data.data['total']);
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setPerItem(res.data.data['data'].length);
        }, (err) => {
            console.log(err);
        });
    }

    const sorting = (col) => {
        const sortResult = Sorting(allHistory, col, order);
        setOrder(sortResult[0]);
    }

    const handlePaginationCallback = (i, perPage) => {
        window.scrollTo(0, 0);
        if (perPage && perPage !== '') {
            setCurrentPage(1);
            setPerPage(perPage);
        } else { setCurrentPage(i); }
    }

    const handleSearchCallback = (input1, input2, input3) => {
        let StartDate, EndDate;
        if (input1 === 'clear') {
            setSearch('');
        } else {
            if (input1 === 'yes') {
                StartDate = startDate;
                EndDate = endDate;
            } else {
                setSearch('yes');
                StartDate = input2;
                EndDate = input3;
                setStartDate(StartDate);
                setEndDate(EndDate);
            }
            const data = {
                startdate: StartDate,
                enddate: EndDate,
            }
            console.log(data);
            let url = `search/userDailyRecord`;
            search_ApiManager(url, data, currentPage, perPage, (res) => {
                console.log('search result', res);
                setAllHistory(res.data.data['data']);
                setTotalItem(res.data.data['total']);
                setPerPage(res.data.data['per_page']);
                setLastPage(res.data.data['last_page']);
                setPerItem(res.data.data['data'].length);

            }, (err) => {
                console.log(err);
            });
        }
    }

    return (
        <>
            <Title title={`User Processing Tasks Report`} back={true} />
            <div className="row w-100 pl-3 pr-3 mb-5 mt-3">
                <MultipleSearchs
                    input2={'Start Date'}
                    input2_type={"date"}
                    input3={'End Date'}
                    input3_type={"date"}
                    onSearchChange={handleSearchCallback}
                />
                <table className="table table-bordered bg-white mb-1">
                    {/* Headers */}
                    <thead className="thead-dark">
                        <tr>
                            {headers.map(head => (
                                <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)}
                                    key={head.key}>{head.title}</th>))}
                        </tr>
                    </thead>
                    {/* End Headers */}
                    <tbody>
                        {allHistory.map((value, index) => (
                            <tr key={index}>
                                <td className="text-center w-3">{++index}</td>
                                <td className='text-center'>{moment(value.created_at).format('D MMM YYYY')}</td>
                                {/* <td className="text-center"><Link to={`/view-report/${value.id}`} className="btn btn-primary">View Report</Link></td> */}
                                <td className="text-center"><Link to={`/view-processing-report/${value.id}`} className="btn btn-primary">View Report</Link></td>
                            </tr>))}
                    </tbody>
                </table>
                <Paginations
                    currentPage={currentPage}
                    total={totalItem}
                    itemsPerPage={perPage}
                    lastPage={lastPage}
                    onPageChange={handlePaginationCallback}
                    totalItems={totalItem}
                    perItem={perItem}
                />
            </div>
        </>
    )
}
export default ProcessingReports;
