
import { apiUrl } from './AxiosHeaders';

export function get_ApiManager(data, url, callback, errorcallback){

  try {
    if(!data){
      apiUrl.get(`${url}`)
      .then(res => {
            if (res.data.status === true && callback != null) {
              callback(res);
            }
          })
          .catch(err => {
            if(errorcallback != null){
              errorcallback(err);
            }
      })
      } else{
      apiUrl.get(`${url}/${data}`)
      .then(res => {
            if (res.data.status === true && callback != null) {
              callback(res);
            }
          })
          .catch(err => {
            if(errorcallback != null){
              errorcallback(err);
            }
      })
    }
    } catch (err) {
      console.log(err);
    }
}

export function put_ApiManager(data, edit_id, url, callback, errorcallback){
    apiUrl.put(`${url}/${edit_id}`, data)
    .then(res => {
      if (res.data.status === true && callback != null) {
        callback(res);
      }
    })
    .catch(err => {
      if(errorcallback != null){
          errorcallback(err);
      }
  })
}

export function delete_ApiManager(id, url, callback, errorcallback){
    apiUrl.delete(`/${url}/${id}`)
      .then(res => {
        if (res.data.status === true && callback != null) {
          callback(res);
        }
      })
      .catch(err => {
        if(errorcallback != null){
           errorcallback(err);
        }
  })
}

export function post_ApiManager(data, url, callback, errorcallback,customHeaders = {}){
    apiUrl.post(`/${url}`, data,{ headers: { ...apiUrl.defaults.headers, ...customHeaders }})
      .then(res => {
          if (res.data.status === true && callback != null) {
            callback(res);
          }
        })
        .catch(err => {
          if(errorcallback != null){
             errorcallback(err);
          }
    })
}

export function view_ApiManager(url, currentPage, perPage, callback, errorcallback){
    apiUrl.get(`${url}?page=${currentPage}&perPage=${perPage}`)
      .then(res => {
          if (res.data.status === true && callback != null) {
            callback(res);
          }
        })
        .catch(err => {
          if(errorcallback != null){
             errorcallback(err);
          }
    })
}

export function search_ApiManager(url, data, currentPage, perPage, callback, errorcallback){
  apiUrl.post(`${url}?page=${currentPage}&perPage=${perPage}`, data)
    .then(res => {
        if (res.data.status === true && callback != null) {
          callback(res);
        }
      })
      .catch(err => {
        if(errorcallback != null){
           errorcallback(err);
        }
  })
}

export function logout_ApiManager(callback, errorcallback){
    apiUrl.get(`/logout`)
      .then(res => {
            if (res.data.status === true && callback != null) {
              callback(res);
            }
          })
          .catch(err => {
            if(errorcallback != null){
              errorcallback(err);
            }
      })
}

